.review-input {
  .ant-input-textarea-show-count .ant-input-data-count {
    @apply -bottom-8;
  }
  .ant-upload-list {
    @apply flex overflow-auto;
  }
  textarea.ant-input {
    font-family: "Inter";
    @apply mb-4;
    &:focus {
      @apply focus:border-0 shadow-none;
    }
  }
  .ant-upload-wrapper {
    .ant-upload-drag {
      @apply bg-[rgba(235,243,252,0.5)] h-[144px]  border-transparent hover:border-blue-500;
    }
    .ant-upload-list {
      @apply flex gap-4;
      .ant-upload-list-item-container {
        width: fit-content !important;
        height: fit-content !important;
        @apply mb-0 flex-[0_0_auto];
        .can-preview-upload {
          .ant-upload-list-item {
            @apply p-0 border-0;
            @apply flex flex-col mt-0 w-20 h-20 items-center justify-center;
            @apply overflow-hidden;

            .ant-upload-list-item-thumbnail {
              .anticon {
                @apply text-fs-12;
              }
              .ant-upload-list-item-name {
                @apply line-clamp-1 overflow-hidden;
              }
            }
            &::before {
              @apply w-full h-full rounded-lg;
            }
          }
        }
        .default-file {
          .ant-upload-list-item {
            @apply flex flex-col mt-0 w-[7.5rem] h-20 items-center justify-center p-0;
            // .ant-upload-list-item-thumbnail{

            // }
            // .ant-upload-list-item-name {
            //   @apply flex w-full h-full;
            // }
          }
        }
        .ant-upload-list-item-actions {
          @apply absolute w-full h-full hover:bg-[rgba(0,0,0,0.2)] duration-300;
          &:hover {
            a {
              @apply opacity-80;
            }
          }
          a {
            @apply opacity-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-fs-20 flex items-center duration-300;
          }

          .ant-upload-list-item-action {
            @apply opacity-100 #{!important};
            @apply absolute z-40 top-1 right-1 bg-[rgba(0,0,0,0.8)] text-white rounded-full w-6 h-6 border-2 border-white;
          }
        }
      }
      .ant-upload.ant-upload-select {
        @apply absolute right-0 z-30 bg-gray-200;
      }
    }
    .ant-upload-list.ant-upload-list-picture-card {
      .ant-upload-list-item-thumbnail {
        @apply w-full h-full;
        // .anticon {
        //   @apply text-fs-12;
        // }
        // .ant-upload-list-item-name {
        //   @apply line-clamp-1 overflow-hidden;
        // }
      }
    }
  }
  .ant-upload-wrapper.ant-upload-wrapper.dragger-hase-file {
    @apply flex;
    .ant-upload-drag {
      @apply w-20 h-20 border-grey-300 bg-transparent flex-[0_0_80px] mr-0.5;
    }
  }
}
