$icomoon-font-family: "icomoon" !default;
$font-path: '/fonts/#{$icomoon-font-family}.ttf';
$icon-font-path: '../../public/#{$font-path}';

$mrv-building: "\f18f";
$mrv-review: "\f191";
$mrv-crown1: "\f190";
$mrv-crown: "\f18e";
$mrv-k: "\e900";
$mrv-k_plus: "\e901";
$mrv-x_mobiledata: "\e902";
$mrv-k1: "\e903";
$mrv-k_plus1: "\e904";
$mrv-mp: "\e905";
$mrv-d_rotation: "\e906";
$mrv-g_mobiledata: "\e907";
$mrv-k2: "\e908";
$mrv-k_plus2: "\e909";
$mrv-mp1: "\e90a";
$mrv-p: "\e90b";
$mrv-g_mobiledata1: "\e90c";
$mrv-g_plus_mobiledata: "\e90d";
$mrv-k3: "\e90e";
$mrv-k_plus3: "\e90f";
$mrv-mp2: "\e910";
$mrv-g: "\e911";
$mrv-k4: "\e912";
$mrv-k_plus4: "\e913";
$mrv-mp3: "\e914";
$mrv-ft_apart: "\e915";
$mrv-k5: "\e916";
$mrv-k_plus5: "\e917";
$mrv-mp4: "\e918";
$mrv-k6: "\e919";
$mrv-k_plus6: "\e91a";
$mrv-mp5: "\e91b";
$mrv-k7: "\e91c";
$mrv-k_plus7: "\e91d";
$mrv-mp6: "\e91e";
$mrv-k8: "\e91f";
$mrv-k_plus8: "\e920";
$mrv-mp7: "\e921";
$mrv-k9: "\e922";
$mrv-mp8: "\e923";
$mrv-mp9: "\e924";
$mrv-mp10: "\e925";
$mrv-mp11: "\e926";
$mrv-mp12: "\e927";
$mrv-mp13: "\e928";
$mrv-mp14: "\e929";
$mrv-mp15: "\e92a";
$mrv-up_rating: "\e92b";
$mrv-mp16: "\e92c";
$mrv-mp17: "\e92d";
$mrv-mp18: "\e92e";
$mrv-mp19: "\e92f";
$mrv-mp20: "\e930";
$mrv-mp21: "\e931";
$mrv-mp22: "\e932";
$mrv-fps: "\e933";
$mrv-fps_select: "\e934";
$mrv-fps1: "\e935";
$mrv-fps_select1: "\e936";
$mrv-123: "\e937";
$mrv-360: "\e938";
$mrv-abc: "\e939";
$mrv-ac_unit: "\e93a";
$mrv-accessibility: "\e93b";
$mrv-accessibility_new: "\e93c";
$mrv-accessible: "\e93d";
$mrv-accessible_forward: "\e93e";
$mrv-account_balance: "\e93f";
$mrv-account_balance_wallet: "\e940";
$mrv-account_box: "\e941";
$mrv-account_circle: "\e942";
$mrv-account_circle_full: "\e943";
$mrv-account_tree: "\e944";
$mrv-ad_units: "\e945";
$mrv-adb: "\e946";
$mrv-add: "\f182";
$mrv-add_a_photo: "\e947";
$mrv-add_alert: "\e948";
$mrv-add_box: "\e949";
$mrv-add_business: "\e94a";
$mrv-add_call: "\e94b";
$mrv-add_card: "\e94c";
$mrv-add_chart: "\e94d";
$mrv-add_circle: "\e94e";
$mrv-add_comment: "\e94f";
$mrv-add_link: "\e950";
$mrv-add_location: "\e951";
$mrv-add_location_alt: "\e952";
$mrv-add_moderator: "\e953";
$mrv-add_photo_alternate: "\f183";
$mrv-add_reaction: "\e954";
$mrv-add_road: "\e955";
$mrv-add_shopping_cart: "\e956";
$mrv-add_shortcut_to_drive: "\e957";
$mrv-add_task: "\e958";
$mrv-add_to_drive: "\e959";
$mrv-add_to_home_screen: "\e95a";
$mrv-add_to_photos: "\e95b";
$mrv-add_to_queue: "\e95c";
$mrv-adf_scanner: "\e95d";
$mrv-adjust: "\e95e";
$mrv-admin_panel_settings: "\e95f";
$mrv-ads_click: "\e960";
$mrv-agriculture: "\e961";
$mrv-air: "\e962";
$mrv-air_freshener: "\e963";
$mrv-air_purifier_gen: "\e964";
$mrv-airline_seat_flat: "\e965";
$mrv-airline_seat_flat_angled: "\e966";
$mrv-airline_seat_individual_suite: "\e967";
$mrv-airline_seat_legroom_extra: "\e968";
$mrv-airline_seat_legroom_normal: "\e969";
$mrv-airline_seat_legroom_reduced: "\e96a";
$mrv-airline_seat_recline_extra: "\e96b";
$mrv-airline_seat_recline_normal: "\e96c";
$mrv-airline_stops: "\e96d";
$mrv-airlines: "\e96e";
$mrv-airplane_ticket: "\e96f";
$mrv-airplanemode_active: "\e970";
$mrv-airplanemode_inactive: "\e971";
$mrv-airplay: "\e972";
$mrv-airport_shuttle: "\e973";
$mrv-airware: "\e974";
$mrv-alarm: "\e975";
$mrv-alarm_add: "\e976";
$mrv-alarm_off: "\e977";
$mrv-alarm_on: "\e978";
$mrv-album: "\e979";
$mrv-align_horizontal_center: "\e97a";
$mrv-align_horizontal_left: "\e97b";
$mrv-align_horizontal_right: "\e97c";
$mrv-align_vertical_bottom: "\e97d";
$mrv-align_vertical_center: "\e97e";
$mrv-align_vertical_top: "\e97f";
$mrv-all_inbox: "\e980";
$mrv-all_inclusive: "\e981";
$mrv-all_out: "\e982";
$mrv-alt_route: "\e983";
$mrv-alternate_email: "\e984";
$mrv-amp_error: "\e985";
$mrv-amp_stories: "\e986";
$mrv-analytics: "\e987";
$mrv-anchor: "\e988";
$mrv-android: "\e989";
$mrv-android_calculator: "\e98a";
$mrv-android_camera: "\e98b";
$mrv-android_chat: "\e98c";
$mrv-android_contacts: "\e98d";
$mrv-android_dialer: "\e98e";
$mrv-android_emergency_location_service: "\e98f";
$mrv-android_family_link: "\e990";
$mrv-android_find_my_device: "\e991";
$mrv-android_google_home: "\e992";
$mrv-android_messages: "\e993";
$mrv-android_now_playing: "\e994";
$mrv-android_recorder: "\e995";
$mrv-android_safety: "\e996";
$mrv-animation: "\e997";
$mrv-aod: "\e998";
$mrv-apartment: "\e999";
$mrv-api: "\e99a";
$mrv-app_blocking: "\e99b";
$mrv-app_registration: "\e99c";
$mrv-app_shortcut: "\e99d";
$mrv-approval: "\e99e";
$mrv-apps: "\e99f";
$mrv-apps_outage: "\e9a0";
$mrv-architecture: "\e9a1";
$mrv-archive: "\e9a2";
$mrv-area_chart: "\e9a3";
$mrv-arrow_back: "\e9a4";
$mrv-arrow_back_ios: "\e9a5";
$mrv-arrow_back_ios_new: "\e9a6";
$mrv-arrow_circle_down: "\e9a7";
$mrv-arrow_circle_left: "\e9a8";
$mrv-arrow_circle_right: "\e9a9";
$mrv-arrow_circle_up: "\e9aa";
$mrv-arrow_downward: "\e9ab";
$mrv-arrow_drop_down: "\e9ac";
$mrv-arrow_drop_down_circle: "\e9ad";
$mrv-arrow_drop_up: "\e9ae";
$mrv-arrow_forward: "\e9af";
$mrv-arrow_forward_ios: "\e9b0";
$mrv-arrow_left: "\e9b1";
$mrv-arrow_right: "\e9b2";
$mrv-arrow_right_alt: "\e9b3";
$mrv-arrow_upward: "\e9b4";
$mrv-art_track: "\e9b5";
$mrv-article: "\e9b6";
$mrv-aspect_ratio: "\e9b7";
$mrv-assignment: "\e9b8";
$mrv-assignment_ind: "\e9b9";
$mrv-assignment_late: "\e9ba";
$mrv-assignment_return: "\e9bb";
$mrv-assignment_returned: "\e9bc";
$mrv-assignment_turned_in: "\e9bd";
$mrv-assistant: "\e9be";
$mrv-assistant_direction: "\e9bf";
$mrv-assistant_navigation: "\e9c0";
$mrv-assured_workload: "\e9c1";
$mrv-astrophotography_auto: "\e9c2";
$mrv-astrophotography_off: "\e9c3";
$mrv-atm: "\e9c4";
$mrv-atr: "\e9c5";
$mrv-attach_email: "\e9c6";
$mrv-attach_file: "\f184";
$mrv-attach_money: "\e9c7";
$mrv-attachment: "\e9c8";
$mrv-attractions: "\e9c9";
$mrv-attribution: "\e9ca";
$mrv-audio_file: "\e9cb";
$mrv-authenticator: "\e9cc";
$mrv-auto_awesome: "\e9cd";
$mrv-auto_awesome_mosaic: "\e9ce";
$mrv-auto_awesome_motion: "\e9cf";
$mrv-auto_delete: "\e9d0";
$mrv-auto_graph: "\e9d1";
$mrv-auto_mode: "\e9d2";
$mrv-auto_read_pause: "\e9d3";
$mrv-auto_read_play: "\e9d4";
$mrv-auto_stories: "\e9d5";
$mrv-autofps_select: "\e9d6";
$mrv-autorenew: "\e9d7";
$mrv-av_timer-path1: "\e9d8";
$mrv-av_timer-path2: "\e9d9";
$mrv-baby_changing_station: "\e9da";
$mrv-back_hand: "\e9db";
$mrv-background_replace: "\e9dc";
$mrv-backpack: "\e9dd";
$mrv-backspace: "\e9de";
$mrv-backup: "\e9df";
$mrv-backup_table: "\e9e0";
$mrv-badge: "\e9e1";
$mrv-badge_critical_battery: "\e9e2";
$mrv-bakery_dining: "\e9e3";
$mrv-balance: "\e9e4";
$mrv-balcony: "\e9e5";
$mrv-ballot: "\e9e6";
$mrv-bar_chart: "\e9e7";
$mrv-batch_prediction: "\e9e8";
$mrv-bathroom: "\e9e9";
$mrv-bathtub: "\e9ea";
$mrv-battery_0_bar: "\e9eb";
$mrv-battery_1_bar: "\e9ec";
$mrv-battery_2_bar: "\e9ed";
$mrv-battery_3_bar: "\e9ee";
$mrv-battery_4_bar: "\e9ef";
$mrv-battery_5_bar: "\e9f0";
$mrv-battery_6_bar: "\e9f1";
$mrv-battery_20: "\e9f2";
$mrv-battery_30: "\e9f3";
$mrv-battery_50: "\e9f4";
$mrv-battery_60: "\e9f5";
$mrv-battery_80: "\e9f6";
$mrv-battery_90: "\e9f7";
$mrv-battery_alert: "\e9f8";
$mrv-battery_charging_20: "\e9f9";
$mrv-battery_charging_30: "\e9fa";
$mrv-battery_charging_50: "\e9fb";
$mrv-battery_charging_60: "\e9fc";
$mrv-battery_charging_80: "\e9fd";
$mrv-battery_charging_90: "\e9fe";
$mrv-battery_charging_full: "\e9ff";
$mrv-battery_full: "\ea00";
$mrv-battery_full_alt: "\ea01";
$mrv-battery_low: "\ea02";
$mrv-battery_saver: "\ea03";
$mrv-battery_unknown: "\ea04";
$mrv-battery_very_low: "\ea05";
$mrv-beach_access: "\ea06";
$mrv-bed: "\ea07";
$mrv-bedroom_baby: "\ea08";
$mrv-bedroom_child: "\ea09";
$mrv-bedroom_parent: "\ea0a";
$mrv-bedtime: "\ea0b";
$mrv-bedtime_off: "\ea0c";
$mrv-beenhere: "\ea0d";
$mrv-bento: "\ea0e";
$mrv-bike_scooter: "\ea0f";
$mrv-biotech: "\ea10";
$mrv-blanket: "\ea11";
$mrv-blender: "\ea12";
$mrv-blinds: "\ea13";
$mrv-blinds_closed: "\ea14";
$mrv-block: "\ea15";
$mrv-bloodtype: "\ea16";
$mrv-bluenote: "\ea17";
$mrv-bluetooth: "\ea18";
$mrv-bluetooth_connected: "\ea19";
$mrv-bluetooth_disabled: "\ea1a";
$mrv-bluetooth_drive: "\ea1b";
$mrv-bluetooth_searching: "\ea1c";
$mrv-blur_circular: "\ea1d";
$mrv-blur_linear: "\ea1e";
$mrv-blur_medium: "\ea1f";
$mrv-blur_off: "\ea20";
$mrv-blur_on: "\ea21";
$mrv-blur_short: "\ea22";
$mrv-bnagent: "\ea23";
$mrv-bolt: "\ea24";
$mrv-book: "\ea25";
$mrv-book_online: "\ea26";
$mrv-bookmark: "\ea27";
$mrv-bookmark_add: "\ea28";
$mrv-bookmark_added: "\ea29";
$mrv-bookmark_remove: "\ea2a";
$mrv-bookmarks: "\ea2b";
$mrv-border_all: "\ea2c";
$mrv-border_bottom: "\ea2d";
$mrv-border_clear: "\ea2e";
$mrv-border_color: "\ea2f";
$mrv-border_horizontal: "\ea30";
$mrv-border_inner: "\ea31";
$mrv-border_left: "\ea32";
$mrv-border_outer: "\ea33";
$mrv-border_right: "\ea34";
$mrv-border_style: "\ea35";
$mrv-border_top: "\ea36";
$mrv-border_vertical: "\ea37";
$mrv-boy: "\ea38";
$mrv-branding_watermark: "\ea39";
$mrv-breakfast_dining: "\ea3a";
$mrv-breaking_news_alt_1: "\ea3b";
$mrv-brightness_1: "\ea3c";
$mrv-brightness_2: "\ea3d";
$mrv-brightness_3: "\ea3e";
$mrv-brightness_4: "\ea3f";
$mrv-brightness_5: "\ea40";
$mrv-brightness_6: "\ea41";
$mrv-brightness_7: "\ea42";
$mrv-brightness_auto: "\ea43";
$mrv-brightness_high: "\ea44";
$mrv-brightness_low: "\ea45";
$mrv-brightness_medium: "\ea46";
$mrv-bring_your_own_ip: "\ea47";
$mrv-broadcast_on_home: "\ea48";
$mrv-broadcast_on_personal: "\ea49";
$mrv-broken_image: "\ea4a";
$mrv-browse_gallery: "\ea4b";
$mrv-browser_updated: "\ea4c";
$mrv-brunch_dining: "\ea4d";
$mrv-brush: "\ea4e";
$mrv-bubble_chart: "\ea4f";
$mrv-bug_report: "\ea50";
$mrv-build: "\ea51";
$mrv-build_circle: "\ea52";
$mrv-bungalow: "\ea53";
$mrv-burst_mode: "\ea54";
$mrv-bus_alert: "\ea55";
$mrv-business_center: "\ea56";
$mrv-cabin: "\ea57";
$mrv-cable: "\ea58";
$mrv-cached: "\ea59";
$mrv-cake: "\ea5a";
$mrv-calculate: "\ea5b";
$mrv-calendar_add_on: "\ea5c";
$mrv-calendar_apps_script: "\ea5d";
$mrv-calendar_month: "\ea5e";
$mrv-calendar_today: "\ea5f";
$mrv-calendar_view_day: "\ea60";
$mrv-calendar_view_month: "\ea61";
$mrv-calendar_view_week: "\ea62";
$mrv-call: "\ea63";
$mrv-call_end: "\ea64";
$mrv-call_made: "\ea65";
$mrv-call_merge: "\ea66";
$mrv-call_missed: "\ea67";
$mrv-call_missed_outgoing: "\ea68";
$mrv-call_received: "\ea69";
$mrv-call_split: "\ea6a";
$mrv-call_to_action: "\ea6b";
$mrv-camera: "\ea6c";
$mrv-camera_enhance: "\ea6d";
$mrv-camera_front: "\ea6e";
$mrv-camera_indoor: "\ea6f";
$mrv-camera_outdoor: "\ea70";
$mrv-camera_rear: "\ea71";
$mrv-camera_roll: "\ea72";
$mrv-cameraswitch: "\ea73";
$mrv-campaign: "\ea74";
$mrv-cancel: "\f185";
$mrv-cancel_presentation: "\ea75";
$mrv-cancel_schedule_send: "\ea76";
$mrv-candlestick_chart: "\ea77";
$mrv-car_crash: "\ea78";
$mrv-car_rental: "\ea79";
$mrv-car_repair: "\ea7a";
$mrv-card_membership: "\ea7b";
$mrv-card_travel: "\ea7c";
$mrv-carpenter: "\ea7d";
$mrv-cases: "\ea7e";
$mrv-casino: "\ea7f";
$mrv-cast: "\ea80";
$mrv-cast_connected: "\ea81";
$mrv-cast_for_education: "\ea82";
$mrv-castle: "\ea83";
$mrv-category: "\ea84";
$mrv-celebration: "\ea85";
$mrv-cell_tower: "\ea86";
$mrv-cell_wifi: "\ea87";
$mrv-center_focus_strong: "\ea88";
$mrv-center_focus_weak: "\ea89";
$mrv-chair: "\ea8a";
$mrv-chair_alt: "\ea8b";
$mrv-chalet: "\ea8c";
$mrv-change_circle: "\ea8d";
$mrv-change_history: "\ea8e";
$mrv-charger: "\ea8f";
$mrv-charging_station: "\ea90";
$mrv-chat: "\ea91";
$mrv-chat_add_on: "\ea92";
$mrv-chat_apps_script: "\ea93";
$mrv-chat_bubble: "\ea94";
$mrv-check: "\f186";
$mrv-check_box: "\ea95";
$mrv-check_box_outline_blank: "\ea96";
$mrv-check_circle: "\ea97";
$mrv-checklist: "\ea98";
$mrv-checklist_rtl: "\ea99";
$mrv-checkroom: "\ea9a";
$mrv-chevron_left: "\ea9b";
$mrv-chevron_right: "\ea9c";
$mrv-child_care: "\ea9d";
$mrv-child_friendly: "\ea9e";
$mrv-chrome_reader_mode: "\ea9f";
$mrv-chromecast_2: "\eaa0";
$mrv-chromecast_device: "\eaa1";
$mrv-church: "\eaa2";
$mrv-circle: "\eaa3";
$mrv-circle_notifications: "\eaa4";
$mrv-clarify: "\eaa5";
$mrv-clean_hands: "\eaa6";
$mrv-cleaning_services: "\eaa7";
$mrv-clear_all: "\eaa8";
$mrv-clear_day: "\eaa9";
$mrv-clear_night: "\eaaa";
$mrv-close: "\f187";
$mrv-close_fullscreen: "\eaab";
$mrv-closed_caption: "\eaac";
$mrv-closed_caption_disabled: "\eaad";
$mrv-cloud: "\eaae";
$mrv-cloud_circle: "\eaaf";
$mrv-cloud_domains: "\eab0";
$mrv-cloud_done: "\eab1";
$mrv-cloud_download: "\eab2";
$mrv-cloud_off: "\eab3";
$mrv-cloud_queue: "\eab4";
$mrv-cloud_sync: "\eab5";
$mrv-cloud_upload: "\eab6";
$mrv-cloudbuild: "\eab7";
$mrv-cloudy: "\eab8";
$mrv-cloudy_filled: "\eab9";
$mrv-cloudy_snowing: "\eaba";
$mrv-co_present: "\eabb";
$mrv-co2: "\eabc";
$mrv-code: "\eabd";
$mrv-code_off: "\eabe";
$mrv-coffee: "\eabf";
$mrv-coffee_maker: "\eac0";
$mrv-collections_bookmark: "\eac1";
$mrv-colorize: "\eac2";
$mrv-comment: "\eac3";
$mrv-comment_bank: "\eac4";
$mrv-comments_disabled: "\eac5";
$mrv-commit: "\eac6";
$mrv-communication: "\eac7";
$mrv-commute: "\eac8";
$mrv-compare: "\eac9";
$mrv-compare_arrows: "\eaca";
$mrv-compass_calibration: "\eacb";
$mrv-component_exchange: "\eacc";
$mrv-compost: "\eacd";
$mrv-compress: "\eace";
$mrv-computer: "\eacf";
$mrv-confirmation_number: "\ead0";
$mrv-connect_without_contact: "\ead1";
$mrv-connected_tv: "\ead2";
$mrv-connecting_airports: "\ead3";
$mrv-construction: "\ead4";
$mrv-contact_mail: "\ead5";
$mrv-contact_page: "\ead6";
$mrv-contact_phone: "\ead7";
$mrv-contact_support: "\f188";
$mrv-contactless: "\ead8";
$mrv-contacts: "\ead9";
$mrv-content_copy: "\eada";
$mrv-content_cut: "\eadb";
$mrv-content_paste: "\eadc";
$mrv-content_paste_go: "\eadd";
$mrv-content_paste_off: "\eade";
$mrv-content_paste_search: "\eadf";
$mrv-contrast: "\eae0";
$mrv-control_camera: "\eae1";
$mrv-control_point_duplicate: "\eae2";
$mrv-controller_gen: "\eae3";
$mrv-conversion_path: "\eae4";
$mrv-cookie: "\eae5";
$mrv-cooking: "\eae6";
$mrv-copy_all: "\eae7";
$mrv-copyright: "\eae8";
$mrv-coronavirus: "\eae9";
$mrv-corporate_fare: "\eaea";
$mrv-cottage: "\eaeb";
$mrv-countertops: "\eaec";
$mrv-create_new_folder: "\eaed";
$mrv-credit_card: "\eaee";
$mrv-credit_card_off: "\eaef";
$mrv-credit_score: "\eaf0";
$mrv-crib: "\eaf1";
$mrv-crisis_alert: "\eaf2";
$mrv-crop: "\eaf3";
$mrv-crop_3_2: "\eaf4";
$mrv-crop_5_4: "\eaf5";
$mrv-crop_7_5: "\eaf6";
$mrv-crop_16_9: "\eaf7";
$mrv-crop_free: "\eaf8";
$mrv-crop_landscape: "\eaf9";
$mrv-crop_portrait: "\eafa";
$mrv-crop_rotate: "\eafb";
$mrv-crop_square: "\eafc";
$mrv-cruelty_free: "\eafd";
$mrv-css: "\eafe";
$mrv-currency_bitcoin: "\eaff";
$mrv-currency_exchange: "\eb00";
$mrv-currency_franc: "\eb01";
$mrv-currency_lira: "\eb02";
$mrv-currency_pound: "\eb03";
$mrv-currency_ruble: "\eb04";
$mrv-currency_rupee: "\eb05";
$mrv-currency_yen: "\eb06";
$mrv-currency_yuan: "\eb07";
$mrv-curtains: "\eb08";
$mrv-curtains_closed: "\eb09";
$mrv-cut: "\eb0a";
$mrv-cyclone: "\eb0b";
$mrv-dangerous: "\eb0c";
$mrv-dark_mode: "\eb0d";
$mrv-dashboard: "\eb0e";
$mrv-dashboard_customize: "\eb0f";
$mrv-data_array: "\eb10";
$mrv-data_exploration: "\eb11";
$mrv-data_object: "\eb12";
$mrv-data_saver_on: "\eb13";
$mrv-data_thresholding: "\eb14";
$mrv-data_usage: "\eb15";
$mrv-database: "\eb16";
$mrv-dataset: "\eb17";
$mrv-dataset_linked: "\eb18";
$mrv-date_range: "\eb19";
$mrv-deblur: "\eb1a";
$mrv-deck: "\eb1b";
$mrv-dehaze: "\eb1c";
$mrv-delete: "\eb1d";
$mrv-delete_forever: "\eb1e";
$mrv-delete_sweep: "\eb1f";
$mrv-density_large: "\eb20";
$mrv-density_medium: "\eb21";
$mrv-density_small: "\eb22";
$mrv-departure_board: "\eb23";
$mrv-description: "\eb24";
$mrv-deselect: "\eb25";
$mrv-design_services: "\eb26";
$mrv-desk: "\eb27";
$mrv-desktop_access_disabled: "\eb28";
$mrv-desktop_mac: "\eb29";
$mrv-desktop_windows: "\eb2a";
$mrv-details: "\eb2b";
$mrv-detector_smoke: "\eb2c";
$mrv-developer_board: "\eb2d";
$mrv-developer_board_off: "\eb2e";
$mrv-developer_mode: "\eb2f";
$mrv-device_hub: "\eb30";
$mrv-device_thermostat: "\eb31";
$mrv-device_unknown: "\eb32";
$mrv-devices: "\eb33";
$mrv-devices_fold: "\eb34";
$mrv-devices_other: "\eb35";
$mrv-dialer_sip: "\eb36";
$mrv-dialpad: "\eb37";
$mrv-diamond: "\eb38";
$mrv-difference: "\eb39";
$mrv-digital_out_of_home: "\eb3a";
$mrv-dining: "\eb3b";
$mrv-dinner_dining: "\eb3c";
$mrv-directions: "\eb3d";
$mrv-directions_bike: "\eb3e";
$mrv-directions_boat: "\eb3f";
$mrv-directions_bus: "\eb40";
$mrv-directions_car: "\eb41";
$mrv-directions_off: "\eb42";
$mrv-directions_railway: "\eb43";
$mrv-directions_run: "\eb44";
$mrv-directions_subway: "\eb45";
$mrv-directions_walk: "\eb46";
$mrv-dirty_lens: "\eb47";
$mrv-disabled_by_default: "\eb48";
$mrv-disabled_visible: "\eb49";
$mrv-disc_full: "\eb4a";
$mrv-discover_tune: "\eb4b";
$mrv-dishwasher_gen: "\eb4c";
$mrv-display_settings: "\eb4d";
$mrv-dns: "\eb4e";
$mrv-do_not_disturb_off: "\eb4f";
$mrv-do_not_disturb_on: "\eb50";
$mrv-do_not_disturb_on_total_silence: "\eb51";
$mrv-do_not_step: "\eb52";
$mrv-do_not_touch: "\eb53";
$mrv-dock: "\eb54";
$mrv-docs: "\eb55";
$mrv-docs_add_on: "\eb56";
$mrv-docs_apps_script: "\eb57";
$mrv-document_scanner: "\eb58";
$mrv-domain: "\eb59";
$mrv-domain_add: "\eb5a";
$mrv-domain_disabled: "\eb5b";
$mrv-domain_verification: "\eb5c";
$mrv-done: "\eb5d";
$mrv-done_all: "\eb5e";
$mrv-done_outline: "\eb5f";
$mrv-donut_large: "\eb60";
$mrv-donut_small: "\eb61";
$mrv-door_back: "\eb62";
$mrv-door_front: "\eb63";
$mrv-door_sliding: "\eb64";
$mrv-doorbell: "\eb65";
$mrv-doorbell_3p: "\eb66";
$mrv-double_arrow: "\eb67";
$mrv-downhill_skiing: "\eb68";
$mrv-download: "\eb69";
$mrv-download_done: "\eb6a";
$mrv-download_for_offline: "\eb6b";
$mrv-downloading: "\eb6c";
$mrv-draft: "\eb6d";
$mrv-drafts: "\eb6e";
$mrv-drag_handle: "\eb6f";
$mrv-drag_indicator: "\eb70";
$mrv-draw: "\eb71";
$mrv-dresser: "\eb72";
$mrv-drive_file_move: "\eb73";
$mrv-drive_file_move_outline: "\eb74";
$mrv-drive_file_move_rtl: "\eb75";
$mrv-drive_file_rename_outline: "\eb76";
$mrv-drive_folder_upload: "\eb77";
$mrv-drive_shortcut: "\eb78";
$mrv-dry: "\eb79";
$mrv-dry_cleaning: "\eb7a";
$mrv-duo: "\eb7b";
$mrv-dvr: "\eb7c";
$mrv-dynamic_feed: "\eb7d";
$mrv-dynamic_form: "\eb7e";
$mrv-e_mobiledata: "\eb7f";
$mrv-e911_avatar: "\eb80";
$mrv-e911_emergency: "\eb81";
$mrv-earbuds: "\eb82";
$mrv-earbuds_battery: "\eb83";
$mrv-east: "\eb84";
$mrv-eco: "\eb85";
$mrv-edgesensor_high: "\eb86";
$mrv-edgesensor_low: "\eb87";
$mrv-edit: "\f189";
$mrv-edit_attributes: "\eb88";
$mrv-edit_calendar: "\eb89";
$mrv-edit_location: "\eb8a";
$mrv-edit_location_alt: "\eb8b";
$mrv-edit_note: "\eb8c";
$mrv-edit_notifications: "\eb8d";
$mrv-edit_off: "\eb8e";
$mrv-edit_road: "\eb8f";
$mrv-egg: "\eb90";
$mrv-egg_alt: "\eb91";
$mrv-eject: "\eb92";
$mrv-elderly: "\eb93";
$mrv-elderly_woman: "\eb94";
$mrv-electric_bike: "\eb95";
$mrv-electric_bolt: "\eb96";
$mrv-electric_car: "\eb97";
$mrv-electric_meter: "\eb98";
$mrv-electric_moped: "\eb99";
$mrv-electric_rickshaw: "\eb9a";
$mrv-electric_scooter: "\eb9b";
$mrv-electrical_services: "\eb9c";
$mrv-elevator: "\eb9d";
$mrv-emergency: "\eb9e";
$mrv-emergency_heat: "\eb9f";
$mrv-emergency_home: "\eba0";
$mrv-emergency_recording: "\eba1";
$mrv-emergency_share: "\eba2";
$mrv-emoji_flags: "\eba3";
$mrv-emoji_food_beverage: "\eba4";
$mrv-emoji_nature: "\eba5";
$mrv-emoji_objects: "\eba6";
$mrv-emoji_people: "\eba7";
$mrv-emoji_symbols: "\eba8";
$mrv-emoji_transportation: "\eba9";
$mrv-enable: "\ebaa";
$mrv-energy_program_saving: "\ebab";
$mrv-energy_program_time_used: "\ebac";
$mrv-energy_savings_leaf: "\ebad";
$mrv-engineering: "\ebae";
$mrv-enhanced_encryption: "\ebaf";
$mrv-equalizer: "\ebb0";
$mrv-error: "\ebb1";
$mrv-escalator: "\ebb2";
$mrv-escalator_warning: "\ebb3";
$mrv-euro: "\ebb4";
$mrv-euro_symbol: "\ebb5";
$mrv-ev_shadow: "\ebb6";
$mrv-ev_station: "\ebb7";
$mrv-event: "\ebb8";
$mrv-event_available: "\ebb9";
$mrv-event_busy: "\ebba";
$mrv-event_note: "\ebbb";
$mrv-event_repeat: "\ebbc";
$mrv-event_seat: "\ebbd";
$mrv-event_upcoming: "\ebbe";
$mrv-exclamation: "\ebbf";
$mrv-exit_to_app: "\ebc0";
$mrv-expand: "\ebc1";
$mrv-expand_circle_down: "\ebc2";
$mrv-expand_less: "\ebc3";
$mrv-expand_more: "\ebc4";
$mrv-explicit: "\ebc5";
$mrv-explore: "\ebc6";
$mrv-explore_off: "\ebc7";
$mrv-exposure: "\ebc8";
$mrv-exposure_neg_1: "\ebc9";
$mrv-exposure_neg_2: "\ebca";
$mrv-exposure_plus_1: "\ebcb";
$mrv-exposure_plus_2: "\ebcc";
$mrv-exposure_zero: "\ebcd";
$mrv-extension: "\ebce";
$mrv-extension_off: "\ebcf";
$mrv-face: "\ebd0";
$mrv-face_retouching_natural: "\ebd1";
$mrv-face_retouching_off: "\ebd2";
$mrv-fact_check: "\ebd3";
$mrv-factory: "\ebd4";
$mrv-family_restroom: "\ebd5";
$mrv-fast_forward: "\ebd6";
$mrv-fast_rewind: "\ebd7";
$mrv-fastfood: "\ebd8";
$mrv-faucet: "\ebd9";
$mrv-favorite: "\ebda";
$mrv-favorite_outline: "\ebdb";
$mrv-fax: "\ebdc";
$mrv-featured_play_list: "\ebdd";
$mrv-featured_video: "\ebde";
$mrv-feed: "\ebdf";
$mrv-female: "\ebe0";
$mrv-fence: "\ebe1";
$mrv-festival: "\ebe2";
$mrv-fiber_dvr: "\ebe3";
$mrv-fiber_manual_record: "\ebe4";
$mrv-fiber_new: "\ebe5";
$mrv-fiber_pin: "\ebe6";
$mrv-fiber_smart_record: "\ebe7";
$mrv-file_copy: "\ebe8";
$mrv-file_download: "\ebe9";
$mrv-file_download_done: "\ebea";
$mrv-file_download_off: "\ebeb";
$mrv-file_open: "\ebec";
$mrv-file_present: "\ebed";
$mrv-file_upload: "\ebee";
$mrv-files: "\ebef";
$mrv-filter: "\ebf0";
$mrv-filter_1: "\ebf1";
$mrv-filter_2: "\ebf2";
$mrv-filter_3: "\ebf3";
$mrv-filter_4: "\ebf4";
$mrv-filter_5: "\ebf5";
$mrv-filter_6: "\ebf6";
$mrv-filter_7: "\ebf7";
$mrv-filter_8: "\ebf8";
$mrv-filter_9: "\ebf9";
$mrv-filter_9_plus: "\ebfa";
$mrv-filter_alt: "\ebfb";
$mrv-filter_alt_off: "\ebfc";
$mrv-filter_b_and_w: "\ebfd";
$mrv-filter_center_focus: "\ebfe";
$mrv-filter_drama: "\ebff";
$mrv-filter_frames: "\ec00";
$mrv-filter_hdr: "\ec01";
$mrv-filter_list: "\ec02";
$mrv-filter_list_off: "\ec03";
$mrv-filter_none: "\ec04";
$mrv-filter_tilt_shift: "\ec05";
$mrv-filter_vintage: "\ec06";
$mrv-find_in_page: "\ec07";
$mrv-find_replace: "\ec08";
$mrv-fingerprint: "\ec09";
$mrv-fire_extinguisher: "\ec0a";
$mrv-fire_hydrant: "\ec0b";
$mrv-fire_truck: "\ec0c";
$mrv-fireplace: "\ec0d";
$mrv-first_page: "\ec0e";
$mrv-fishfood: "\ec0f";
$mrv-fit_screen: "\ec10";
$mrv-fitness_center: "\ec11";
$mrv-flag: "\ec12";
$mrv-flag_circle: "\ec13";
$mrv-flaky: "\ec14";
$mrv-flare: "\ec15";
$mrv-flash_auto: "\ec16";
$mrv-flash_off: "\ec17";
$mrv-flash_on: "\ec18";
$mrv-flashlight_off: "\ec19";
$mrv-flashlight_on: "\ec1a";
$mrv-flatware: "\ec1b";
$mrv-flight: "\ec1c";
$mrv-flight_class: "\ec1d";
$mrv-flight_land: "\ec1e";
$mrv-flight_takeoff: "\ec1f";
$mrv-flip: "\ec20";
$mrv-flip_camera_android: "\ec21";
$mrv-flip_camera_ios: "\ec22";
$mrv-flip_to_back: "\ec23";
$mrv-flip_to_front: "\ec24";
$mrv-flood: "\ec25";
$mrv-flutter: "\ec26";
$mrv-flutter_dash: "\ec27";
$mrv-fmd_bad: "\ec28";
$mrv-foggy: "\ec29";
$mrv-folder: "\ec2a";
$mrv-folder_copy: "\ec2b";
$mrv-folder_delete: "\ec2c";
$mrv-folder_off: "\ec2d";
$mrv-folder_open: "\ec2e";
$mrv-folder_shared: "\ec2f";
$mrv-folder_special: "\ec30";
$mrv-folder_zip: "\ec31";
$mrv-follow_the_signs: "\ec32";
$mrv-font_download: "\ec33";
$mrv-font_download_off: "\ec34";
$mrv-food_bank: "\ec35";
$mrv-forest: "\ec36";
$mrv-fork_left: "\ec37";
$mrv-fork_right: "\ec38";
$mrv-format_align_center: "\ec39";
$mrv-format_align_justify: "\ec3a";
$mrv-format_align_left: "\ec3b";
$mrv-format_align_right: "\ec3c";
$mrv-format_bold: "\ec3d";
$mrv-format_clear: "\ec3e";
$mrv-format_color_fill: "\ec3f";
$mrv-format_color_reset: "\ec40";
$mrv-format_color_text: "\ec41";
$mrv-format_indent_decrease: "\ec42";
$mrv-format_indent_increase: "\ec43";
$mrv-format_italic: "\ec44";
$mrv-format_line_spacing: "\ec45";
$mrv-format_list_bulleted: "\ec46";
$mrv-format_list_numbered: "\ec47";
$mrv-format_list_numbered_rtl: "\ec48";
$mrv-format_overline: "\ec49";
$mrv-format_paint: "\ec4a";
$mrv-format_quote: "\ec4b";
$mrv-format_shapes: "\ec4c";
$mrv-format_size: "\ec4d";
$mrv-format_strikethrough: "\ec4e";
$mrv-format_textdirection_l_to_r: "\ec4f";
$mrv-format_textdirection_r_to_l: "\ec50";
$mrv-format_underlined: "\ec51";
$mrv-forms_add_on: "\ec52";
$mrv-forms_apps_script: "\ec53";
$mrv-fort: "\ec54";
$mrv-forum: "\ec55";
$mrv-forward: "\ec56";
$mrv-forward_5: "\ec57";
$mrv-forward_10: "\ec58";
$mrv-forward_30: "\ec59";
$mrv-forward_to_inbox: "\ec5a";
$mrv-foundation: "\ec5b";
$mrv-free_cancellation: "\ec5c";
$mrv-front_hand: "\ec5d";
$mrv-full_stacked_bar_chart: "\ec5e";
$mrv-fullscreen: "\ec5f";
$mrv-fullscreen_exit: "\ec60";
$mrv-functions: "\ec61";
$mrv-g_mobiledata2: "\ec62";
$mrv-g_translate: "\ec63";
$mrv-garage: "\ec64";
$mrv-garage_home: "\ec65";
$mrv-gas_meter: "\ec66";
$mrv-gate: "\ec67";
$mrv-gavel: "\ec68";
$mrv-gbadge: "\ec69";
$mrv-gboard: "\ec6a";
$mrv-generating_tokens: "\ec6b";
$mrv-gesture: "\ec6c";
$mrv-gfit_health: "\ec6d";
$mrv-gif: "\ec6e";
$mrv-gif_box: "\ec6f";
$mrv-girl: "\ec70";
$mrv-gite: "\ec71";
$mrv-gleaf: "\ec72";
$mrv-gmail_add_on: "\ec73";
$mrv-gmail_apps_script: "\ec74";
$mrv-gmail_rooms: "\ec75";
$mrv-golf_course: "\ec76";
$mrv-google_fi: "\ec77";
$mrv-google_guarantee: "\ec78";
$mrv-google_plus_reshare: "\ec79";
$mrv-google_travel: "\ec7a";
$mrv-google_travel_outline: "\ec7b";
$mrv-google_tv: "\ec7c";
$mrv-google_wifi: "\ec7d";
$mrv-googler_travel: "\ec7e";
$mrv-gpay: "\ec7f";
$mrv-gpp_bad: "\ec80";
$mrv-gpp_maybe: "\ec81";
$mrv-grade: "\ec82";
$mrv-gradient: "\ec83";
$mrv-grading: "\ec84";
$mrv-grain: "\ec85";
$mrv-graphic_eq: "\ec86";
$mrv-grass: "\ec87";
$mrv-grid_3x3: "\ec88";
$mrv-grid_4x4: "\ec89";
$mrv-grid_goldenratio: "\ec8a";
$mrv-grid_off: "\ec8b";
$mrv-grid_on: "\ec8c";
$mrv-grid_view: "\ec8d";
$mrv-group: "\ec8e";
$mrv-group_add: "\ec8f";
$mrv-group_off: "\ec90";
$mrv-group_remove: "\ec91";
$mrv-group_work: "\ec92";
$mrv-grouped_bar_chart: "\ec93";
$mrv-h_mobiledata: "\ec94";
$mrv-h_plus_mobiledata: "\ec95";
$mrv-hail: "\ec96";
$mrv-hand_gesture: "\ec97";
$mrv-handshake: "\ec98";
$mrv-handyman: "\ec99";
$mrv-hardware: "\ec9a";
$mrv-hd: "\ec9b";
$mrv-hdr_auto: "\ec9c";
$mrv-hdr_auto_select: "\ec9d";
$mrv-hdr_enhanced_select: "\ec9e";
$mrv-hdr_off: "\ec9f";
$mrv-hdr_off_select: "\eca0";
$mrv-hdr_on: "\eca1";
$mrv-hdr_on_select: "\eca2";
$mrv-hdr_plus: "\eca3";
$mrv-hdr_strong: "\eca4";
$mrv-hdr_weak: "\eca5";
$mrv-headphones: "\eca6";
$mrv-headphones_battery: "\eca7";
$mrv-headset_mic: "\eca8";
$mrv-headset_off: "\eca9";
$mrv-healing: "\ecaa";
$mrv-health_and_safety: "\ecab";
$mrv-hearing: "\ecac";
$mrv-hearing_disabled: "\ecad";
$mrv-heart_broken: "\ecae";
$mrv-heat_pump: "\ecaf";
$mrv-height: "\ecb0";
$mrv-help: "\ecb1";
$mrv-help_center: "\ecb2";
$mrv-hevc: "\ecb3";
$mrv-hexagon: "\ecb4";
$mrv-hide: "\ecb5";
$mrv-hide_image: "\ecb6";
$mrv-hide_source: "\ecb7";
$mrv-high_quality: "\ecb8";
$mrv-highlight: "\ecb9";
$mrv-hiking: "\ecba";
$mrv-history: "\ecbb";
$mrv-history_edu: "\ecbc";
$mrv-history_toggle_off: "\ecbd";
$mrv-hive: "\ecbe";
$mrv-hls: "\ecbf";
$mrv-hls_off: "\ecc0";
$mrv-holiday_village: "\ecc1";
$mrv-home: "\ecc2";
$mrv-home_app_logo: "\ecc3";
$mrv-home_iot_device: "\ecc4";
$mrv-home_max: "\ecc5";
$mrv-home_max_dots: "\ecc6";
$mrv-home_mini: "\ecc7";
$mrv-home_pin: "\ecc8";
$mrv-home_repair_service: "\ecc9";
$mrv-home_speaker: "\ecca";
$mrv-home_work: "\eccb";
$mrv-horizontal_distribute: "\eccc";
$mrv-horizontal_rule: "\eccd";
$mrv-horizontal_split: "\ecce";
$mrv-hot_tub: "\eccf";
$mrv-hotel: "\ecd0";
$mrv-hotel_class: "\ecd1";
$mrv-hourglass_bottom: "\ecd2";
$mrv-hourglass_disabled: "\ecd3";
$mrv-hourglass_empty: "\ecd4";
$mrv-hourglass_full: "\ecd5";
$mrv-hourglass_top: "\ecd6";
$mrv-house: "\ecd7";
$mrv-house_siding: "\ecd8";
$mrv-houseboat: "\ecd9";
$mrv-how_to_reg: "\ecda";
$mrv-how_to_vote: "\ecdb";
$mrv-html: "\ecdc";
$mrv-http: "\ecdd";
$mrv-hub: "\ecde";
$mrv-humidity_high: "\ecdf";
$mrv-humidity_low: "\ece0";
$mrv-humidity_mid: "\ece1";
$mrv-hvac: "\ece2";
$mrv-ice_skating: "\ece3";
$mrv-icecream: "\ece4";
$mrv-identity_platform: "\ece5";
$mrv-image: "\ece6";
$mrv-image_aspect_ratio: "\ece7";
$mrv-image_not_supported: "\ece8";
$mrv-image_search: "\ece9";
$mrv-imagesearch_roller: "\ecea";
$mrv-imagesmode: "\eceb";
$mrv-import_contacts: "\ecec";
$mrv-important_devices: "\eced";
$mrv-in_home_mode: "\ecee";
$mrv-inbox: "\ecef";
$mrv-incomplete_circle: "\ecf0";
$mrv-indeterminate_check_box: "\ecf1";
$mrv-info: "\ecf2";
$mrv-input: "\ecf3";
$mrv-insert_chart: "\ecf4";
$mrv-insert_page_break: "\ecf5";
$mrv-insights: "\ecf6";
$mrv-install_desktop: "\ecf7";
$mrv-install_mobile: "\ecf8";
$mrv-integration_instructions: "\ecf9";
$mrv-interests: "\ecfa";
$mrv-interpreter_mode: "\ecfb";
$mrv-inventory: "\ecfc";
$mrv-inventory_2: "\ecfd";
$mrv-invert_colors: "\ecfe";
$mrv-invert_colors_off: "\ecff";
$mrv-ios_share-path1: "\ed00";
$mrv-ios_share-path2: "\ed01";
$mrv-iron: "\ed02";
$mrv-javascript: "\ed03";
$mrv-join_full: "\ed04";
$mrv-join_inner: "\ed05";
$mrv-join_left: "\ed06";
$mrv-join_right: "\ed07";
$mrv-kayaking: "\ed08";
$mrv-kebab_dining: "\ed09";
$mrv-kettle: "\ed0a";
$mrv-key: "\ed0b";
$mrv-key_off: "\ed0c";
$mrv-key_visualizer: "\ed0d";
$mrv-keyboard: "\ed0e";
$mrv-keyboard_alt: "\ed0f";
$mrv-keyboard_arrow_down: "\f18a";
$mrv-keyboard_arrow_left: "\ed10";
$mrv-keyboard_arrow_right: "\ed11";
$mrv-keyboard_arrow_up: "\ed12";
$mrv-keyboard_backspace: "\ed13";
$mrv-keyboard_capslock: "\ed14";
$mrv-keyboard_command_key: "\ed15";
$mrv-keyboard_control_key: "\ed16";
$mrv-keyboard_double_arrow_down: "\ed17";
$mrv-keyboard_double_arrow_left: "\ed18";
$mrv-keyboard_double_arrow_right: "\ed19";
$mrv-keyboard_double_arrow_up: "\ed1a";
$mrv-keyboard_hide: "\ed1b";
$mrv-keyboard_option_key: "\ed1c";
$mrv-keyboard_return: "\ed1d";
$mrv-keyboard_tab: "\ed1e";
$mrv-keyboard_voice: "\ed1f";
$mrv-king_bed: "\ed20";
$mrv-kitchen: "\ed21";
$mrv-kitesurfing: "\ed22";
$mrv-kormo: "\ed23";
$mrv-label: "\ed24";
$mrv-label_important: "\ed25";
$mrv-label_off: "\ed26";
$mrv-lan: "\ed27";
$mrv-landscape: "\ed28";
$mrv-landslide: "\ed29";
$mrv-language: "\ed2a";
$mrv-laptop_chromebook: "\ed2b";
$mrv-laptop_mac: "\ed2c";
$mrv-laptop_windows: "\ed2d";
$mrv-last_page: "\ed2e";
$mrv-layers: "\ed2f";
$mrv-layers_clear: "\ed30";
$mrv-leaderboard: "\ed31";
$mrv-leak_add: "\ed32";
$mrv-leak_remove: "\ed33";
$mrv-legend_toggle: "\ed34";
$mrv-lens: "\ed35";
$mrv-lens_blur: "\ed36";
$mrv-library_add: "\ed37";
$mrv-library_add_check: "\ed38";
$mrv-library_books: "\ed39";
$mrv-library_music: "\ed3a";
$mrv-lift_to_talk: "\ed3b";
$mrv-light: "\ed3c";
$mrv-light_mode: "\ed3d";
$mrv-lightbulb: "\ed3e";
$mrv-lightbulb_circle: "\ed3f";
$mrv-line_axis: "\ed40";
$mrv-line_style: "\ed41";
$mrv-line_weight: "\ed42";
$mrv-linear_scale: "\ed43";
$mrv-link: "\ed44";
$mrv-link_off: "\ed45";
$mrv-linked_camera: "\ed46";
$mrv-liquor: "\ed47";
$mrv-list: "\ed48";
$mrv-list_alt: "\ed49";
$mrv-live_help: "\ed4a";
$mrv-live_tv: "\ed4b";
$mrv-living: "\ed4c";
$mrv-local_activity: "\ed4d";
$mrv-local_atm: "\ed4e";
$mrv-local_bar: "\ed4f";
$mrv-local_cafe: "\ed50";
$mrv-local_car_wash: "\ed51";
$mrv-local_convenience_store: "\ed52";
$mrv-local_dining: "\ed53";
$mrv-local_drink: "\ed54";
$mrv-local_fire_department: "\ed55";
$mrv-local_florist: "\ed56";
$mrv-local_gas_station: "\ed57";
$mrv-local_hospital: "\ed58";
$mrv-local_laundry_service: "\ed59";
$mrv-local_library: "\ed5a";
$mrv-local_mall: "\ed5b";
$mrv-local_parking: "\ed5c";
$mrv-local_pharmacy: "\ed5d";
$mrv-local_pizza: "\ed5e";
$mrv-local_police: "\ed5f";
$mrv-local_post_office: "\ed60";
$mrv-local_see: "\ed61";
$mrv-local_shipping: "\ed62";
$mrv-local_taxi: "\ed63";
$mrv-location_automation: "\ed64";
$mrv-location_away: "\ed65";
$mrv-location_city: "\ed66";
$mrv-location_disabled: "\ed67";
$mrv-location_home: "\ed68";
$mrv-location_off: "\ed69";
$mrv-location_searching: "\ed6a";
$mrv-lock: "\ed6b";
$mrv-lock_clock: "\ed6c";
$mrv-lock_open: "\ed6d";
$mrv-lock_person: "\ed6e";
$mrv-lock_reset: "\ed6f";
$mrv-login: "\ed70";
$mrv-logo_dev: "\ed71";
$mrv-logout: "\ed72";
$mrv-looks: "\ed73";
$mrv-looks_3: "\ed74";
$mrv-looks_4: "\ed75";
$mrv-looks_5: "\ed76";
$mrv-looks_6: "\ed77";
$mrv-looks_one: "\ed78";
$mrv-looks_two: "\ed79";
$mrv-loupe: "\ed7a";
$mrv-low_priority: "\ed7b";
$mrv-loyalty: "\ed7c";
$mrv-lte_mobiledata: "\ed7d";
$mrv-lte_plus_mobiledata: "\ed7e";
$mrv-luggage: "\ed7f";
$mrv-lunch_dining: "\ed80";
$mrv-lyrics: "\ed81";
$mrv-machine_image: "\ed82";
$mrv-magic_button: "\ed83";
$mrv-mail: "\ed84";
$mrv-mail_lock: "\ed85";
$mrv-male: "\ed86";
$mrv-man: "\ed87";
$mrv-manage_accounts: "\ed88";
$mrv-manage_history: "\ed89";
$mrv-manage_search: "\ed8a";
$mrv-map: "\ed8b";
$mrv-maps_ugc: "\ed8c";
$mrv-margin: "\ed8d";
$mrv-mark_as_unread: "\ed8e";
$mrv-mark_chat_read: "\ed8f";
$mrv-mark_chat_unread: "\ed90";
$mrv-mark_email_read: "\ed91";
$mrv-mark_email_unread: "\ed92";
$mrv-mark_unread_chat_alt: "\ed93";
$mrv-markunread_mailbox: "\ed94";
$mrv-masks: "\ed95";
$mrv-matter: "\ed96";
$mrv-maximize: "\ed97";
$mrv-media_bluetooth_off: "\ed98";
$mrv-media_bluetooth_on: "\ed99";
$mrv-mediation: "\ed9a";
$mrv-medical_information: "\ed9b";
$mrv-medical_services: "\ed9c";
$mrv-medication: "\ed9d";
$mrv-medication_liquid: "\ed9e";
$mrv-meeting_room: "\ed9f";
$mrv-memory: "\eda0";
$mrv-menu: "\eda1";
$mrv-menu_book: "\eda2";
$mrv-menu_open: "\eda3";
$mrv-merge: "\eda4";
$mrv-merge_type: "\eda5";
$mrv-mfg_nest_yale_lock: "\eda6";
$mrv-mic: "\eda7";
$mrv-mic_external_off: "\eda8";
$mrv-mic_external_on: "\eda9";
$mrv-mic_off: "\edaa";
$mrv-microwave: "\edab";
$mrv-microwave_gen: "\edac";
$mrv-military_tech: "\edad";
$mrv-minimize: "\edae";
$mrv-minor_crash: "\edaf";
$mrv-missed_video_call: "\edb0";
$mrv-mms: "\edb1";
$mrv-mobile_friendly: "\edb2";
$mrv-mobile_off: "\edb3";
$mrv-mobile_screen_share: "\edb4";
$mrv-mobiledata_off: "\edb5";
$mrv-mode_comment: "\edb6";
$mrv-mode_cool: "\edb7";
$mrv-mode_cool_off: "\edb8";
$mrv-mode_fan: "\edb9";
$mrv-mode_fan_off: "\edba";
$mrv-mode_heat: "\edbb";
$mrv-mode_heat_cool: "\edbc";
$mrv-mode_heat_off: "\edbd";
$mrv-mode_night: "\edbe";
$mrv-mode_of_travel: "\edbf";
$mrv-mode_off_on: "\edc0";
$mrv-mode_standby: "\edc1";
$mrv-model_training: "\edc2";
$mrv-monetization_on: "\edc3";
$mrv-money: "\edc4";
$mrv-money_off: "\edc5";
$mrv-monitor: "\edc6";
$mrv-monitor_heart: "\edc7";
$mrv-monitor_weight: "\edc8";
$mrv-monitoring: "\edc9";
$mrv-monochrome_photos: "\edca";
$mrv-mood: "\edcb";
$mrv-mood_bad: "\edcc";
$mrv-mop: "\edcd";
$mrv-more: "\edce";
$mrv-more_down: "\edcf";
$mrv-more_horiz: "\edd0";
$mrv-more_time: "\edd1";
$mrv-more_up: "\edd2";
$mrv-more_vert: "\edd3";
$mrv-mosque: "\edd4";
$mrv-motion_blur: "\edd5";
$mrv-motion_photos_auto: "\edd6";
$mrv-motion_photos_off: "\edd7";
$mrv-motion_photos_paused: "\edd8";
$mrv-motion_sense: "\edd9";
$mrv-motorcycle: "\edda";
$mrv-mouse: "\eddb";
$mrv-move_down: "\eddc";
$mrv-move_to_inbox: "\eddd";
$mrv-move_up: "\edde";
$mrv-movie: "\eddf";
$mrv-movie_filter: "\ede0";
$mrv-moving: "\ede1";
$mrv-mp23: "\ede2";
$mrv-multicooker: "\ede3";
$mrv-multiline_chart: "\ede4";
$mrv-multiple_stop: "\ede5";
$mrv-museum: "\ede6";
$mrv-music_note: "\ede7";
$mrv-music_off: "\ede8";
$mrv-music_video: "\ede9";
$mrv-my_location: "\edea";
$mrv-nat: "\edeb";
$mrv-nature: "\edec";
$mrv-nature_people: "\eded";
$mrv-navigate_before: "\edee";
$mrv-navigate_next: "\edef";
$mrv-navigation: "\edf0";
$mrv-near_me: "\edf1";
$mrv-near_me_disabled: "\edf2";
$mrv-nearby_error: "\edf3";
$mrv-nearby_off: "\edf4";
$mrv-nearby_share: "\edf5";
$mrv-nest_audio: "\edf6";
$mrv-nest_cam_indoor: "\edf7";
$mrv-nest_cam_iq: "\edf8";
$mrv-nest_cam_iq_outdoor: "\edf9";
$mrv-nest_cam_outdoor: "\edfa";
$mrv-nest_cam_wired_stand: "\edfb";
$mrv-nest_connect: "\edfc";
$mrv-nest_detect: "\edfd";
$mrv-nest_display: "\edfe";
$mrv-nest_display_max: "\edff";
$mrv-nest_eco: "\ee00";
$mrv-nest_heat_link_e: "\ee01";
$mrv-nest_heat_link_gen_3: "\ee02";
$mrv-nest_hello_doorbell: "\ee03";
$mrv-nest_mini: "\ee04";
$mrv-nest_remote: "\ee05";
$mrv-nest_remote_comfort_sensor: "\ee06";
$mrv-nest_secure_alarm: "\ee07";
$mrv-nest_tag: "\ee08";
$mrv-nest_thermostat_e_eu: "\ee09";
$mrv-nest_thermostat_gen_3: "\ee0a";
$mrv-nest_thermostat_sensor: "\ee0b";
$mrv-nest_thermostat_sensor_eu: "\ee0c";
$mrv-nest_thermostat_zirconium_eu: "\ee0d";
$mrv-nest_wifi_gale: "\ee0e";
$mrv-nest_wifi_mistral: "\ee0f";
$mrv-nest_wifi_point: "\ee10";
$mrv-nest_wifi_point_vento: "\ee11";
$mrv-nest_wifi_router: "\ee12";
$mrv-network_cell: "\ee13";
$mrv-network_check: "\ee14";
$mrv-network_intelligence: "\ee15";
$mrv-network_locked: "\ee16";
$mrv-network_ping: "\ee17";
$mrv-network_wifi: "\ee18";
$mrv-network_wifi_1_bar: "\ee19";
$mrv-network_wifi_2_bar: "\ee1a";
$mrv-network_wifi_3_bar: "\ee1b";
$mrv-new_label: "\ee1c";
$mrv-new_releases: "\ee1d";
$mrv-newspaper: "\ee1e";
$mrv-next_plan: "\ee1f";
$mrv-next_week: "\ee20";
$mrv-nfc: "\ee21";
$mrv-night_shelter: "\ee22";
$mrv-night_sight_auto: "\ee23";
$mrv-night_sight_auto_off: "\ee24";
$mrv-nightlife: "\ee25";
$mrv-nightlight: "\ee26";
$mrv-nights_stay: "\ee27";
$mrv-no_accounts: "\ee28";
$mrv-no_adult_content: "\ee29";
$mrv-no_backpack: "\ee2a";
$mrv-no_crash: "\ee2b";
$mrv-no_drinks: "\ee2c";
$mrv-no_encryption: "\ee2d";
$mrv-no_flash: "\ee2e";
$mrv-no_food: "\ee2f";
$mrv-no_luggage: "\ee30";
$mrv-no_meals: "\ee31";
$mrv-no_meeting_room: "\ee32";
$mrv-no_photography: "\ee33";
$mrv-no_sim: "\ee34";
$mrv-no_stroller: "\ee35";
$mrv-no_transfer: "\ee36";
$mrv-noise_aware: "\ee37";
$mrv-noise_control_off: "\ee38";
$mrv-nordic_walking: "\ee39";
$mrv-north: "\ee3a";
$mrv-north_east: "\ee3b";
$mrv-north_west: "\ee3c";
$mrv-not_accessible: "\ee3d";
$mrv-not_listed_location: "\ee3e";
$mrv-not_started: "\ee3f";
$mrv-note: "\ee40";
$mrv-note_add: "\ee41";
$mrv-note_alt: "\ee42";
$mrv-notes: "\ee43";
$mrv-notification_add: "\ee44";
$mrv-notification_important: "\ee45";
$mrv-notifications: "\ee46";
$mrv-notifications_active: "\ee47";
$mrv-notifications_off: "\ee48";
$mrv-notifications_paused: "\ee49";
$mrv-numbers: "\ee4a";
$mrv-offline_bolt: "\ee4b";
$mrv-offline_pin: "\ee4c";
$mrv-offline_share: "\ee4d";
$mrv-oil_barrel: "\ee4e";
$mrv-on_device_training: "\ee4f";
$mrv-online_prediction: "\ee50";
$mrv-opacity: "\ee51";
$mrv-open_in_browser: "\ee52";
$mrv-open_in_full: "\ee53";
$mrv-open_in_new: "\ee54";
$mrv-open_in_new_off: "\ee55";
$mrv-open_with: "\ee56";
$mrv-other_houses: "\ee57";
$mrv-outbound: "\ee58";
$mrv-outbox: "\ee59";
$mrv-outdoor_garden: "\ee5a";
$mrv-outdoor_grill: "\ee5b";
$mrv-outgoing_mail: "\ee5c";
$mrv-outlet: "\ee5d";
$mrv-output: "\ee5e";
$mrv-oven_gen: "\ee5f";
$mrv-packet_mirroring: "\ee60";
$mrv-padding: "\ee61";
$mrv-pages: "\ee62";
$mrv-pageview: "\ee63";
$mrv-paid: "\ee64";
$mrv-palette: "\ee65";
$mrv-pan_tool: "\ee66";
$mrv-pan_tool_alt: "\ee67";
$mrv-panorama: "\ee68";
$mrv-panorama_fish_eye: "\ee69";
$mrv-panorama_horizontal: "\ee6a";
$mrv-panorama_photosphere: "\ee6b";
$mrv-panorama_vertical: "\ee6c";
$mrv-panorama_wide_angle: "\ee6d";
$mrv-paragliding: "\ee6e";
$mrv-park: "\ee6f";
$mrv-partly_cloudy_day: "\ee70";
$mrv-partly_cloudy_night: "\ee71";
$mrv-party_mode: "\ee72";
$mrv-password: "\ee73";
$mrv-pattern: "\ee74";
$mrv-pause: "\ee75";
$mrv-pause_circle: "\ee76";
$mrv-pause_presentation: "\ee77";
$mrv-payments: "\ee78";
$mrv-pedal_bike: "\ee79";
$mrv-pending: "\ee7a";
$mrv-pending_actions: "\ee7b";
$mrv-pentagon: "\ee7c";
$mrv-percent: "\ee7d";
$mrv-pergola: "\ee7e";
$mrv-perm_camera_mic: "\ee7f";
$mrv-perm_contact_calendar: "\ee80";
$mrv-perm_data_setting: "\ee81";
$mrv-perm_device_information: "\ee82";
$mrv-perm_media: "\ee83";
$mrv-perm_phone_msg: "\ee84";
$mrv-perm_scan_wifi: "\ee85";
$mrv-person: "\ee86";
$mrv-person_add: "\ee87";
$mrv-person_add_disabled: "\ee88";
$mrv-person_filled: "\f18b";
$mrv-person_off: "\ee89";
$mrv-person_pin: "\ee8a";
$mrv-person_pin_circle: "\ee8b";
$mrv-person_remove: "\ee8c";
$mrv-person_search: "\ee8d";
$mrv-personal_injury: "\ee8e";
$mrv-personalized_recommendations: "\ee8f";
$mrv-pest_control: "\ee90";
$mrv-pest_control_rodent: "\ee91";
$mrv-pets: "\ee92";
$mrv-phishing: "\ee93";
$mrv-phone_android: "\ee94";
$mrv-phone_bluetooth_speaker: "\ee95";
$mrv-phone_callback: "\ee96";
$mrv-phone_disabled: "\ee97";
$mrv-phone_enabled: "\ee98";
$mrv-phone_forwarded: "\ee99";
$mrv-phone_in_talk: "\ee9a";
$mrv-phone_iphone: "\ee9b";
$mrv-phone_locked: "\ee9c";
$mrv-phone_missed: "\ee9d";
$mrv-phone_paused: "\ee9e";
$mrv-phonelink_erase: "\ee9f";
$mrv-phonelink_lock: "\eea0";
$mrv-phonelink_off: "\eea1";
$mrv-phonelink_ring: "\eea2";
$mrv-phonelink_setup: "\eea3";
$mrv-photo: "\eea4";
$mrv-photo_album: "\eea5";
$mrv-photo_camera: "\eea6";
$mrv-photo_camera_back: "\eea7";
$mrv-photo_camera_front: "\eea8";
$mrv-photo_filter: "\eea9";
$mrv-photo_frame: "\eeaa";
$mrv-photo_library: "\eeab";
$mrv-photo_size_select_large: "\eeac";
$mrv-photo_size_select_small: "\eead";
$mrv-php: "\eeae";
$mrv-piano: "\eeaf";
$mrv-piano_off: "\eeb0";
$mrv-picture_as_pdf: "\eeb1";
$mrv-picture_in_picture: "\eeb2";
$mrv-picture_in_picture_alt: "\eeb3";
$mrv-pie_chart: "\eeb4";
$mrv-pin: "\eeb5";
$mrv-pin_drop: "\eeb6";
$mrv-pin_end: "\eeb7";
$mrv-pin_invoke: "\eeb8";
$mrv-pinch: "\eeb9";
$mrv-pinch_zoom_in: "\eeba";
$mrv-pinch_zoom_out: "\eebb";
$mrv-pivot_table_chart: "\eebc";
$mrv-place_item: "\eebd";
$mrv-plagiarism: "\eebe";
$mrv-play_arrow: "\eebf";
$mrv-play_circle: "\eec0";
$mrv-play_disabled: "\eec1";
$mrv-play_for_work: "\eec2";
$mrv-play_lesson: "\eec3";
$mrv-play_pause: "\eec4";
$mrv-play_services: "\eec5";
$mrv-playlist_add: "\eec6";
$mrv-playlist_add_check: "\eec7";
$mrv-playlist_add_check_circle: "\eec8";
$mrv-playlist_add_circle: "\eec9";
$mrv-playlist_play: "\eeca";
$mrv-playlist_remove: "\eecb";
$mrv-plumbing: "\eecc";
$mrv-podcasts: "\eecd";
$mrv-podcasts_app: "\eece";
$mrv-point_of_sale: "\eecf";
$mrv-policy: "\eed0";
$mrv-polyline: "\eed1";
$mrv-polymer: "\eed2";
$mrv-pool: "\eed3";
$mrv-portable_wifi_off: "\eed4";
$mrv-post_add: "\eed5";
$mrv-power: "\eed6";
$mrv-power_input: "\eed7";
$mrv-power_off: "\eed8";
$mrv-power_settings_new: "\eed9";
$mrv-precision_manufacturing: "\eeda";
$mrv-pregnant_woman: "\eedb";
$mrv-present_to_all: "\eedc";
$mrv-preview: "\eedd";
$mrv-price_change: "\eede";
$mrv-price_check: "\eedf";
$mrv-print: "\eee0";
$mrv-print_disabled: "\eee1";
$mrv-priority: "\eee2";
$mrv-priority_high: "\eee3";
$mrv-privacy: "\eee4";
$mrv-privacy_tip: "\eee5";
$mrv-private_connectivity: "\eee6";
$mrv-production_quantity_limits: "\eee7";
$mrv-propane: "\eee8";
$mrv-propane_tank: "\eee9";
$mrv-psychology: "\eeea";
$mrv-public: "\eeeb";
$mrv-public_off: "\eeec";
$mrv-publish: "\eeed";
$mrv-published_with_changes: "\eeee";
$mrv-punch_clock: "\eeef";
$mrv-qr_code: "\eef0";
$mrv-qr_code_2: "\eef1";
$mrv-qr_code_scanner: "\eef2";
$mrv-query_stats: "\eef3";
$mrv-question_mark: "\eef4";
$mrv-queue_music: "\eef5";
$mrv-queue_play_next: "\eef6";
$mrv-quick_phrases: "\eef7";
$mrv-quickreply: "\eef8";
$mrv-quiz: "\eef9";
$mrv-r_mobiledata: "\eefa";
$mrv-radar: "\eefb";
$mrv-radio: "\eefc";
$mrv-radio_button_checked: "\eefd";
$mrv-radio_button_unchecked: "\eefe";
$mrv-railway_alert: "\eeff";
$mrv-rainy: "\ef00";
$mrv-ramen_dining: "\ef01";
$mrv-ramp_left: "\ef02";
$mrv-ramp_right: "\ef03";
$mrv-range_hood: "\ef04";
$mrv-rate_review: "\ef05";
$mrv-raw_off: "\ef06";
$mrv-raw_on: "\ef07";
$mrv-read_more: "\ef08";
$mrv-real_estate_agent: "\ef09";
$mrv-receipt: "\ef0a";
$mrv-receipt_long: "\ef0b";
$mrv-recent_actors: "\ef0c";
$mrv-recommend: "\ef0d";
$mrv-record_voice_over: "\ef0e";
$mrv-rectangle: "\ef0f";
$mrv-recycling: "\ef10";
$mrv-redeem: "\ef11";
$mrv-redo: "\ef12";
$mrv-reduce_capacity: "\ef13";
$mrv-refresh: "\ef14";
$mrv-remember_me: "\ef15";
$mrv-remote_gen: "\ef16";
$mrv-remove: "\ef17";
$mrv-remove_done: "\ef18";
$mrv-remove_from_queue: "\ef19";
$mrv-remove_moderator: "\ef1a";
$mrv-remove_road: "\ef1b";
$mrv-remove_shopping_cart: "\ef1c";
$mrv-reorder: "\ef1d";
$mrv-repeat: "\ef1e";
$mrv-repeat_on: "\ef1f";
$mrv-repeat_one: "\ef20";
$mrv-repeat_one_on: "\ef21";
$mrv-replay: "\ef22";
$mrv-replay_5: "\ef23";
$mrv-replay_10: "\ef24";
$mrv-replay_30: "\ef25";
$mrv-replay_circle_filled: "\ef26";
$mrv-reply: "\ef27";
$mrv-reply_all: "\ef28";
$mrv-report: "\ef29";
$mrv-report_off: "\ef2a";
$mrv-request_page: "\ef2b";
$mrv-request_quote: "\ef2c";
$mrv-reset_tv: "\ef2d";
$mrv-restart_alt: "\ef2e";
$mrv-restaurant: "\ef2f";
$mrv-restaurant_menu: "\ef30";
$mrv-restore_from_trash: "\ef31";
$mrv-restore_page: "\ef32";
$mrv-retention: "\ef33";
$mrv-reviews: "\ef34";
$mrv-rice_bowl: "\ef35";
$mrv-ring_volume: "\ef36";
$mrv-rocket: "\ef37";
$mrv-rocket_launch: "\ef38";
$mrv-roller_shades: "\ef39";
$mrv-roller_shades_closed: "\ef3a";
$mrv-roller_skating: "\ef3b";
$mrv-roofing: "\ef3c";
$mrv-room_preferences: "\ef3d";
$mrv-room_service: "\ef3e";
$mrv-rotate_90_degrees_ccw: "\ef3f";
$mrv-rotate_90_degrees_cw: "\ef40";
$mrv-rotate_left: "\ef41";
$mrv-rotate_right: "\ef42";
$mrv-roundabout_left: "\ef43";
$mrv-roundabout_right: "\ef44";
$mrv-rounded_corner: "\ef45";
$mrv-route: "\ef46";
$mrv-router: "\ef47";
$mrv-routine: "\ef48";
$mrv-rowing: "\ef49";
$mrv-rss_feed: "\ef4a";
$mrv-rsvp: "\ef4b";
$mrv-rtt: "\ef4c";
$mrv-rule: "\ef4d";
$mrv-rule_folder: "\ef4e";
$mrv-run_circle: "\ef4f";
$mrv-running_with_errors: "\ef50";
$mrv-rv_hookup: "\ef51";
$mrv-safety_check: "\ef52";
$mrv-safety_divider: "\ef53";
$mrv-sailing: "\ef54";
$mrv-sanitizer: "\ef55";
$mrv-satellite: "\ef56";
$mrv-satellite_alt: "\ef57";
$mrv-save: "\ef58";
$mrv-save_as: "\ef59";
$mrv-saved_search: "\ef5a";
$mrv-savings: "\ef5b";
$mrv-scale: "\ef5c";
$mrv-scanner: "\ef5d";
$mrv-scatter_plot: "\ef5e";
$mrv-scene: "\ef5f";
$mrv-schedule: "\ef60";
$mrv-schedule_send: "\ef61";
$mrv-schema: "\ef62";
$mrv-school: "\ef63";
$mrv-science: "\ef64";
$mrv-score: "\ef65";
$mrv-scoreboard: "\ef66";
$mrv-screen_attention: "\ef67";
$mrv-screen_attention_off: "\ef68";
$mrv-screen_lock_landscape: "\ef69";
$mrv-screen_lock_portrait: "\ef6a";
$mrv-screen_lock_rotation: "\ef6b";
$mrv-screen_rotation: "\ef6c";
$mrv-screen_rotation_alt: "\ef6d";
$mrv-screen_search_desktop: "\ef6e";
$mrv-screen_share: "\ef6f";
$mrv-screenshot: "\ef70";
$mrv-screenshot_monitor: "\ef71";
$mrv-scuba_diving: "\ef72";
$mrv-sd: "\ef73";
$mrv-sd_card: "\ef74";
$mrv-sd_card_alert: "\ef75";
$mrv-search: "\f18c";
$mrv-search_off: "\ef76";
$mrv-security: "\ef77";
$mrv-security_pro_monitoring: "\ef78";
$mrv-security_update_good: "\ef79";
$mrv-security_update_warning: "\ef7a";
$mrv-segment: "\ef7b";
$mrv-select_all: "\ef7c";
$mrv-select_check_box: "\ef7d";
$mrv-self_improvement: "\ef7e";
$mrv-sell: "\ef7f";
$mrv-send: "\ef80";
$mrv-send_and_archive: "\ef81";
$mrv-send_time_extension: "\ef82";
$mrv-send_to_mobile: "\ef83";
$mrv-sensor_door: "\ef84";
$mrv-sensor_occupied: "\ef85";
$mrv-sensor_window: "\ef86";
$mrv-sensors: "\ef87";
$mrv-sensors_off: "\ef88";
$mrv-sentiment_dissatisfied: "\ef89";
$mrv-sentiment_extremely_dissatisfied: "\ef8a";
$mrv-sentiment_neutral: "\ef8b";
$mrv-sentiment_satisfied: "\ef8c";
$mrv-sentiment_very_dissatisfied: "\ef8d";
$mrv-sentiment_very_satisfied: "\f18d";
$mrv-service_directory: "\ef8e";
$mrv-set_meal: "\ef8f";
$mrv-settings: "\ef90";
$mrv-settings_accessibility: "\ef91";
$mrv-settings_applications: "\ef92";
$mrv-settings_backup_restore: "\ef93";
$mrv-settings_bluetooth: "\ef94";
$mrv-settings_brightness: "\ef95";
$mrv-settings_cell: "\ef96";
$mrv-settings_ethernet: "\ef97";
$mrv-settings_input_antenna: "\ef98";
$mrv-settings_input_component: "\ef99";
$mrv-settings_input_hdmi: "\ef9a";
$mrv-settings_input_svideo: "\ef9b";
$mrv-settings_overscan: "\ef9c";
$mrv-settings_phone: "\ef9d";
$mrv-settings_power: "\ef9e";
$mrv-settings_remote: "\ef9f";
$mrv-settings_suggest: "\efa0";
$mrv-settings_system_daydream: "\efa1";
$mrv-settings_voice: "\efa2";
$mrv-settop_component: "\efa3";
$mrv-severe_cold: "\efa4";
$mrv-share: "\efa5";
$mrv-share_location: "\efa6";
$mrv-sheets: "\efa7";
$mrv-sheets_add_on: "\efa8";
$mrv-sheets_apps_script: "\efa9";
$mrv-shield: "\efaa";
$mrv-shield_moon: "\efab";
$mrv-shop: "\efac";
$mrv-shop_two: "\efad";
$mrv-shopping_bag: "\efae";
$mrv-shopping_basket: "\efaf";
$mrv-shopping_cart: "\efb0";
$mrv-shopping_cart_checkout: "\efb1";
$mrv-short_text: "\efb2";
$mrv-show_chart: "\efb3";
$mrv-shower: "\efb4";
$mrv-shuffle: "\efb5";
$mrv-shuffle_on: "\efb6";
$mrv-shutter_speed: "\efb7";
$mrv-sick: "\efb8";
$mrv-sign_language: "\efb9";
$mrv-signal_cellular_0_bar: "\efba";
$mrv-signal_cellular_1_bar: "\efbb";
$mrv-signal_cellular_2_bar: "\efbc";
$mrv-signal_cellular_3_bar: "\efbd";
$mrv-signal_cellular_4_bar: "\efbe";
$mrv-signal_cellular_alt: "\efbf";
$mrv-signal_cellular_alt_1_bar: "\efc0";
$mrv-signal_cellular_alt_2_bar: "\efc1";
$mrv-signal_cellular_connected_no_internet_0_bar: "\efc2";
$mrv-signal_cellular_connected_no_internet_4_bar: "\efc3";
$mrv-signal_cellular_nodata: "\efc4";
$mrv-signal_cellular_null: "\efc5";
$mrv-signal_cellular_off: "\efc6";
$mrv-signal_disconnected: "\efc7";
$mrv-signal_wifi_0_bar: "\efc8";
$mrv-signal_wifi_4_bar: "\efc9";
$mrv-signal_wifi_bad: "\efca";
$mrv-signal_wifi_off: "\efcb";
$mrv-signal_wifi_statusbar_not_connected: "\efcc";
$mrv-signal_wifi_statusbar_null: "\efcd";
$mrv-signpost: "\efce";
$mrv-sim_card: "\efcf";
$mrv-sim_card_download: "\efd0";
$mrv-single_bed: "\efd1";
$mrv-sip: "\efd2";
$mrv-skateboarding: "\efd3";
$mrv-skip_next: "\efd4";
$mrv-skip_previous: "\efd5";
$mrv-sledding: "\efd6";
$mrv-slides: "\efd7";
$mrv-slides_add_on: "\efd8";
$mrv-slides_apps_script: "\efd9";
$mrv-slideshow: "\efda";
$mrv-slow_motion_video: "\efdb";
$mrv-smart_button: "\efdc";
$mrv-smart_display: "\efdd";
$mrv-smart_outlet: "\efde";
$mrv-smart_screen: "\efdf";
$mrv-smart_toy: "\efe0";
$mrv-smartphone: "\efe1";
$mrv-smoke_free: "\efe2";
$mrv-smoking_rooms: "\efe3";
$mrv-sms: "\efe4";
$mrv-sms_failed: "\efe5";
$mrv-snippet_folder: "\efe6";
$mrv-snooze: "\efe7";
$mrv-snowboarding: "\efe8";
$mrv-snowing: "\efe9";
$mrv-snowmobile: "\efea";
$mrv-snowshoeing: "\efeb";
$mrv-soap: "\efec";
$mrv-social_distance: "\efed";
$mrv-solar_power: "\efee";
$mrv-sort: "\efef";
$mrv-sort_by_alpha: "\eff0";
$mrv-sos: "\eff1";
$mrv-sound_detection_dog_barking: "\eff2";
$mrv-sound_detection_glass_break: "\eff3";
$mrv-sound_detection_loud_sound: "\eff4";
$mrv-sound_sensing: "\eff5";
$mrv-soup_kitchen: "\eff6";
$mrv-south: "\eff7";
$mrv-south_america: "\eff8";
$mrv-south_east: "\eff9";
$mrv-south_west: "\effa";
$mrv-spa: "\effb";
$mrv-space_bar: "\effc";
$mrv-space_dashboard: "\effd";
$mrv-spatial_audio: "\effe";
$mrv-spatial_audio_off: "\efff";
$mrv-spatial_tracking: "\f000";
$mrv-speaker: "\f001";
$mrv-speaker_group: "\f002";
$mrv-speaker_notes: "\f003";
$mrv-speaker_notes_off: "\f004";
$mrv-speaker_phone: "\f005";
$mrv-speed: "\f006";
$mrv-spellcheck: "\f007";
$mrv-splitscreen: "\f008";
$mrv-spoke: "\f009";
$mrv-sports: "\f00a";
$mrv-sports_bar: "\f00b";
$mrv-sports_baseball: "\f00c";
$mrv-sports_basketball: "\f00d";
$mrv-sports_cricket: "\f00e";
$mrv-sports_esports: "\f00f";
$mrv-sports_football: "\f010";
$mrv-sports_golf: "\f011";
$mrv-sports_gymnastics: "\f012";
$mrv-sports_handball: "\f013";
$mrv-sports_hockey: "\f014";
$mrv-sports_kabaddi: "\f015";
$mrv-sports_martial_arts: "\f016";
$mrv-sports_mma: "\f017";
$mrv-sports_motorsports: "\f018";
$mrv-sports_rugby: "\f019";
$mrv-sports_score: "\f01a";
$mrv-sports_soccer: "\f01b";
$mrv-sports_tennis: "\f01c";
$mrv-sports_volleyball: "\f01d";
$mrv-sprinkler: "\f01e";
$mrv-square: "\f01f";
$mrv-square_foot: "\f020";
$mrv-ssid_chart: "\f021";
$mrv-stabilization: "\f022";
$mrv-stabilization_action: "\f023";
$mrv-stabilization_lock: "\f024";
$mrv-stabilization_pan: "\f025";
$mrv-stacked_bar_chart: "\f026";
$mrv-stacked_line_chart: "\f027";
$mrv-stadia_controller: "\f028";
$mrv-stadium: "\f029";
$mrv-stairs: "\f02a";
$mrv-star: "\f02b";
$mrv-star_half: "\f02c";
$mrv-star_outline: "\f02d";
$mrv-stars: "\f02e";
$mrv-start: "\f02f";
$mrv-stateful: "\f030";
$mrv-stay_current_landscape: "\f031";
$mrv-stay_current_portrait: "\f032";
$mrv-stay_primary_landscape: "\f033";
$mrv-stay_primary_portrait: "\f034";
$mrv-sticky_note_2: "\f035";
$mrv-stop: "\f036";
$mrv-stop_circle: "\f037";
$mrv-stop_screen_share: "\f038";
$mrv-storage: "\f039";
$mrv-store: "\f03a";
$mrv-storefront: "\f03b";
$mrv-storm: "\f03c";
$mrv-straight: "\f03d";
$mrv-straighten: "\f03e";
$mrv-stream: "\f03f";
$mrv-streetview: "\f040";
$mrv-strikethrough_s: "\f041";
$mrv-stroller: "\f042";
$mrv-style: "\f043";
$mrv-styler: "\f044";
$mrv-subdirectory_arrow_left: "\f045";
$mrv-subdirectory_arrow_right: "\f046";
$mrv-subject: "\f047";
$mrv-subscript: "\f048";
$mrv-subscriptions: "\f049";
$mrv-subtitles: "\f04a";
$mrv-subtitles_off: "\f04b";
$mrv-subway: "\f04c";
$mrv-summarize: "\f04d";
$mrv-sunny: "\f04e";
$mrv-sunny_snowing: "\f04f";
$mrv-superscript: "\f050";
$mrv-supervised_user_circle: "\f051";
$mrv-supervisor_account: "\f052";
$mrv-support: "\f053";
$mrv-support_agent: "\f054";
$mrv-surfing: "\f055";
$mrv-surround_sound: "\f056";
$mrv-swap_calls: "\f057";
$mrv-swap_horiz: "\f058";
$mrv-swap_horizontal_circle: "\f059";
$mrv-swap_vert: "\f05a";
$mrv-swap_vertical_circle: "\f05b";
$mrv-swipe: "\f05c";
$mrv-swipe_down: "\f05d";
$mrv-swipe_down_alt: "\f05e";
$mrv-swipe_left: "\f05f";
$mrv-swipe_left_alt: "\f060";
$mrv-swipe_right: "\f061";
$mrv-swipe_right_alt: "\f062";
$mrv-swipe_up: "\f063";
$mrv-swipe_up_alt: "\f064";
$mrv-swipe_vertical: "\f065";
$mrv-switch: "\f066";
$mrv-switch_access_shortcut: "\f067";
$mrv-switch_access_shortcut_add: "\f068";
$mrv-switch_account: "\f069";
$mrv-switch_camera: "\f06a";
$mrv-switch_left: "\f06b";
$mrv-switch_right: "\f06c";
$mrv-switch_video: "\f06d";
$mrv-synagogue: "\f06e";
$mrv-sync: "\f06f";
$mrv-sync_alt: "\f070";
$mrv-sync_disabled: "\f071";
$mrv-sync_lock: "\f072";
$mrv-sync_problem: "\f073";
$mrv-system_update: "\f074";
$mrv-system_update_alt: "\f075";
$mrv-tab: "\f076";
$mrv-tab_unselected: "\f077";
$mrv-table: "\f078";
$mrv-table_bar: "\f079";
$mrv-table_chart: "\f07a";
$mrv-table_restaurant: "\f07b";
$mrv-table_rows: "\f07c";
$mrv-table_view: "\f07d";
$mrv-tablet: "\f07e";
$mrv-tablet_android: "\f07f";
$mrv-tablet_mac: "\f080";
$mrv-tag: "\f081";
$mrv-takeout_dining: "\f082";
$mrv-tamper_detection_off: "\f083";
$mrv-tap_and_play: "\f084";
$mrv-tapas: "\f085";
$mrv-task: "\f086";
$mrv-task_alt: "\f087";
$mrv-taxi_alert: "\f088";
$mrv-team_dashboard: "\f089";
$mrv-temple_buddhist: "\f08a";
$mrv-temple_hindu: "\f08b";
$mrv-tenancy: "\f08c";
$mrv-terminal: "\f08d";
$mrv-text_decrease: "\f08e";
$mrv-text_fields: "\f08f";
$mrv-text_format: "\f090";
$mrv-text_increase: "\f091";
$mrv-text_rotate_up: "\f092";
$mrv-text_rotate_vertical: "\f093";
$mrv-text_rotation_angledown: "\f094";
$mrv-text_rotation_angleup: "\f095";
$mrv-text_rotation_down: "\f096";
$mrv-text_rotation_none: "\f097";
$mrv-text_snippet: "\f098";
$mrv-text_to_speech: "\f099";
$mrv-texture: "\f09a";
$mrv-theater_comedy: "\f09b";
$mrv-theaters: "\f09c";
$mrv-thermometer: "\f09d";
$mrv-thermostat: "\f09e";
$mrv-thermostat_auto: "\f09f";
$mrv-thermostat_carbon: "\f0a0";
$mrv-threat_intelligence: "\f0a1";
$mrv-thumb_down: "\f0a2";
$mrv-thumb_down_off: "\f0a3";
$mrv-thumb_up: "\f0a4";
$mrv-thumb_up_off: "\f0a5";
$mrv-thumbs_up_down: "\f0a6";
$mrv-thumbs_up_down_off: "\f0a7";
$mrv-thunderstorm: "\f0a8";
$mrv-time_auto: "\f0a9";
$mrv-timelapse: "\f0aa";
$mrv-timeline: "\f0ab";
$mrv-timer: "\f0ac";
$mrv-timer_3: "\f0ad";
$mrv-timer_3_alt_1: "\f0ae";
$mrv-timer_3_select: "\f0af";
$mrv-timer_10: "\f0b0";
$mrv-timer_10_alt_1: "\f0b1";
$mrv-timer_10_select: "\f0b2";
$mrv-timer_off: "\f0b3";
$mrv-tips_and_updates: "\f0b4";
$mrv-tire_repair: "\f0b5";
$mrv-title: "\f0b6";
$mrv-toc: "\f0b7";
$mrv-today: "\f0b8";
$mrv-toggle_off: "\f0b9";
$mrv-toggle_on: "\f0ba";
$mrv-token: "\f0bb";
$mrv-toll: "\f0bc";
$mrv-tonality: "\f0bd";
$mrv-topic: "\f0be";
$mrv-tornado: "\f0bf";
$mrv-touch_app: "\f0c0";
$mrv-tour: "\f0c1";
$mrv-toys: "\f0c2";
$mrv-track_changes: "\f0c3";
$mrv-traffic: "\f0c4";
$mrv-trail_length: "\f0c5";
$mrv-trail_length_medium: "\f0c6";
$mrv-trail_length_short: "\f0c7";
$mrv-train: "\f0c8";
$mrv-tram: "\f0c9";
$mrv-transfer_within_a_station: "\f0ca";
$mrv-transform: "\f0cb";
$mrv-transgender: "\f0cc";
$mrv-transit_enterexit: "\f0cd";
$mrv-translate: "\f0ce";
$mrv-transportation: "\f0cf";
$mrv-travel_explore: "\f0d0";
$mrv-trending_down: "\f0d1";
$mrv-trending_flat: "\f0d2";
$mrv-trending_up: "\f0d3";
$mrv-trip_origin: "\f0d4";
$mrv-tsunami: "\f0d5";
$mrv-tty: "\f0d6";
$mrv-tune: "\f0d7";
$mrv-turn_left: "\f0d8";
$mrv-turn_right: "\f0d9";
$mrv-turn_sharp_left: "\f0da";
$mrv-turn_sharp_right: "\f0db";
$mrv-turn_slight_left: "\f0dc";
$mrv-turn_slight_right: "\f0dd";
$mrv-tv: "\f0de";
$mrv-tv_gen: "\f0df";
$mrv-tv_off: "\f0e0";
$mrv-tv_with_assistant: "\f0e1";
$mrv-two_wheeler: "\f0e2";
$mrv-type_specimen: "\f0e3";
$mrv-u_turn_left: "\f0e4";
$mrv-u_turn_right: "\f0e5";
$mrv-umbrella: "\f0e6";
$mrv-unarchive: "\f0e7";
$mrv-unblock_flipped: "\f0e8";
$mrv-undo: "\f0e9";
$mrv-unfold_less: "\f0ea";
$mrv-unfold_more: "\f0eb";
$mrv-unpin: "\f0ec";
$mrv-unpublished: "\f0ed";
$mrv-unsubscribe: "\f0ee";
$mrv-upcoming: "\f0ef";
$mrv-update: "\f0f0";
$mrv-update_disabled: "\f0f1";
$mrv-upgrade: "\f0f2";
$mrv-upload: "\f0f3";
$mrv-upload_file: "\f0f4";
$mrv-usb: "\f0f5";
$mrv-usb_off: "\f0f6";
$mrv-vaccines: "\f0f7";
$mrv-valve: "\f0f8";
$mrv-vape_free: "\f0f9";
$mrv-vaping_rooms: "\f0fa";
$mrv-verified: "\f0fb";
$mrv-verified_user: "\f0fc";
$mrv-vertical_align_bottom: "\f0fd";
$mrv-vertical_align_center: "\f0fe";
$mrv-vertical_align_top: "\f0ff";
$mrv-vertical_distribute: "\f100";
$mrv-vertical_shades: "\f101";
$mrv-vertical_shades_closed: "\f102";
$mrv-vertical_split: "\f103";
$mrv-vibration: "\f104";
$mrv-video_audio_call: "\f105";
$mrv-video_call: "\f106";
$mrv-video_camera_back: "\f107";
$mrv-video_camera_front: "\f108";
$mrv-video_file: "\f109";
$mrv-video_label: "\f10a";
$mrv-video_library: "\f10b";
$mrv-video_settings: "\f10c";
$mrv-video_stable: "\f10d";
$mrv-videocam: "\f10e";
$mrv-videocam_off: "\f10f";
$mrv-videogame_asset: "\f110";
$mrv-videogame_asset_off: "\f111";
$mrv-view_agenda: "\f112";
$mrv-view_array: "\f113";
$mrv-view_carousel: "\f114";
$mrv-view_column: "\f115";
$mrv-view_comfy: "\f116";
$mrv-view_comfy_alt: "\f117";
$mrv-view_compact: "\f118";
$mrv-view_compact_alt: "\f119";
$mrv-view_cozy: "\f11a";
$mrv-view_day: "\f11b";
$mrv-view_headline: "\f11c";
$mrv-view_in_ar: "\f11d";
$mrv-view_in_ar_new: "\f11e";
$mrv-view_kanban: "\f11f";
$mrv-view_list: "\f120";
$mrv-view_module: "\f121";
$mrv-view_quilt: "\f122";
$mrv-view_sidebar: "\f123";
$mrv-view_stream: "\f124";
$mrv-view_timeline: "\f125";
$mrv-view_week: "\f126";
$mrv-vignette: "\f127";
$mrv-villa: "\f128";
$mrv-visibility: "\f129";
$mrv-visibility_off: "\f12a";
$mrv-voice_chat: "\f12b";
$mrv-voice_over_off: "\f12c";
$mrv-voicemail: "\f12d";
$mrv-volcano: "\f12e";
$mrv-volume_down: "\f12f";
$mrv-volume_down_alt: "\f130";
$mrv-volume_mute: "\f131";
$mrv-volume_off: "\f132";
$mrv-volume_up: "\f133";
$mrv-volunteer_activism: "\f134";
$mrv-vpn_googleone: "\f135";
$mrv-vpn_key: "\f136";
$mrv-vpn_key_off: "\f137";
$mrv-vpn_lock: "\f138";
$mrv-vrpano: "\f139";
$mrv-wallet: "\f13a";
$mrv-wallpaper: "\f13b";
$mrv-warehouse: "\f13c";
$mrv-warning: "\f13d";
$mrv-wash: "\f13e";
$mrv-watch: "\f13f";
$mrv-watch_off: "\f140";
$mrv-water: "\f141";
$mrv-water_damage: "\f142";
$mrv-water_drop: "\f143";
$mrv-water_heater: "\f144";
$mrv-waterfall_chart: "\f145";
$mrv-waves: "\f146";
$mrv-waving_hand: "\f147";
$mrv-wb_auto: "\f148";
$mrv-wb_incandescent: "\f149";
$mrv-wb_iridescent: "\f14a";
$mrv-wb_shade: "\f14b";
$mrv-wb_sunny: "\f14c";
$mrv-wb_twilight: "\f14d";
$mrv-wc: "\f14e";
$mrv-web: "\f14f";
$mrv-web_asset: "\f150";
$mrv-web_asset_off: "\f151";
$mrv-web_stories: "\f152";
$mrv-webhook: "\f153";
$mrv-weekend: "\f154";
$mrv-west: "\f155";
$mrv-whatshot: "\f156";
$mrv-wheelchair_pickup: "\f157";
$mrv-where_to_vote: "\f158";
$mrv-whiteboard: "\f159";
$mrv-widgets: "\f15a";
$mrv-width_full: "\f15b";
$mrv-width_normal: "\f15c";
$mrv-width_wide: "\f15d";
$mrv-wifi: "\f15e";
$mrv-wifi_1_bar: "\f15f";
$mrv-wifi_2_bar: "\f160";
$mrv-wifi_calling: "\f161";
$mrv-wifi_calling_1: "\f162";
$mrv-wifi_calling_2: "\f163";
$mrv-wifi_calling_3: "\f164";
$mrv-wifi_channel: "\f165";
$mrv-wifi_find: "\f166";
$mrv-wifi_lock: "\f167";
$mrv-wifi_off: "\f168";
$mrv-wifi_password: "\f169";
$mrv-wifi_protected_setup: "\f16a";
$mrv-wifi_tethering: "\f16b";
$mrv-wifi_tethering_error: "\f16c";
$mrv-wifi_tethering_off: "\f16d";
$mrv-wind_power: "\f16e";
$mrv-window: "\f16f";
$mrv-wine_bar: "\f170";
$mrv-woman: "\f171";
$mrv-work: "\f172";
$mrv-work_history: "\f173";
$mrv-workbench: "\f174";
$mrv-workspace_premium: "\f175";
$mrv-workspaces: "\f176";
$mrv-wrap_text: "\f177";
$mrv-wrong_location: "\f178";
$mrv-wysiwyg: "\f179";
$mrv-yard: "\f17a";
$mrv-youtube_music: "\f17b";
$mrv-youtube_searched_for: "\f17c";
$mrv-youtube_tv: "\f17d";
$mrv-zoom_in: "\f17e";
$mrv-zoom_in_map: "\f17f";
$mrv-zoom_out: "\f180";
$mrv-zoom_out_map: "\f181";

