.reply-review-input {
  .ant-upload-wrapper {
    .ant-upload-drag {
      @apply bg-white border-none h-[42px];
      .ant-upload {
        @apply p-0;
      }
    }
  }
  .ant-upload-wrapper.ant-upload-wrapper.dragger-hase-file {
    @apply flex;
    .ant-upload-drag {
      @apply w-20 h-20 flex-[0_0_80px];
      @apply border border-dashed border-grey-300 #{!important};
    }
  }
}
.reply-review-input {
  @apply px-[13px] pt-3 pb-1;
  .reply-mentions-input {
    .reply-mentions-input__control {
      .reply-mentions-input__highlighter {
        strong {
          white-space: pre-wrap !important;
          word-wrap: break-word;
        }
      }
    }
  }
}
