// @import "../styles/font.scss";
@import "../styles/components/index.scss";
@import "../styles/icon.scss";
@import "../styles/modules/home.scss";
@import "../styles/modules/modalPreviewFile.scss";
@import "../styles//variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
html,
body,
.h-screen {
  height: 100%;

  // -webkit-overflow-scrolling: touch !important;
  // overflow: auto !important;

  min-height: -webkit-fill-available !important;
}
.shadow-light-2 {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 2px 4px 0px rgba(0, 0, 0, 0.04) !important;
}
body {
  min-height: -webkit-fill-available;
  @apply text-grey-700 bg-grey-100;
  * {
    // font-family: "Inter";
    @apply font-Inter;
    word-break: break-word;
  }
}
.layout {
  max-width: 1096px;
  margin: auto;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
}
* {
  @apply box-border antialiased;
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  @apply bg-transparent rounded;
}

*::-webkit-scrollbar {
  @apply w-1.5;
}
*::-webkit-scrollbar {
  @apply h-1.5;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ratio-square {
  width: 100%;
  padding-bottom: 75%;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded;
}
.bg-header {
  background-image: linear-gradient(
      90.01deg,
      #000e41 11.79%,
      rgba(13, 15, 20, 0) 57.41%
    ),
    linear-gradient(
      90.01deg,
      rgba(13, 15, 20, 0.22) 70.38%,
      rgba(0, 14, 65, 0.92) 98.33%
    ),
    url("/images/background.jpg");
  background-size: cover;

  @apply w-full;
}
textarea:focus,
input:focus {
  outline: none;
}
a {
  @apply text-blue-500 hover:text-blue-400 duration-300;
}

.break-word {
  word-break: break-word;
}

.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-placeholder {
  @apply text-grey-600 leading-9;
}
