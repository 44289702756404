.meey-button {
  @apply px-4 py-2 rounded outline-none shadow-none flex items-center duration-300 text-white;
  &.meey-button-prefix-icon {
    i {
      @apply mr-2;
    }
  }
  &.meey-button-primary {
    &.meey-button-outline {
      @apply bg-transparent border border-solid border-blue-500 text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-500;
      }
      &:active {
        @apply bg-blue-100;
      }
      &:focus {
        @apply bg-white;
      }
      &:focus:active {
        @apply bg-blue-100;
      }
      &:disabled {
        @apply border-grey-200 text-grey-400 cursor-not-allowed;
      }
    }

    &.meey-button-filled {
      @apply bg-blue-500 border border-solid border-blue-500 text-white font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-400 border-blue-400;
      }
      &:active {
        @apply bg-blue-600 border-blue-400;
      }
      &:focus {
        @apply bg-blue-700 border-blue-700;
      }
      &:focus:active {
        @apply bg-blue-600 border-blue-600;
      }
      &:disabled {
        @apply border-grey-200 bg-grey-200  text-grey-400 cursor-not-allowed;
      }
    }

    &.meey-button-link {
      @apply bg-white border border-solid border-transparent text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-transparent;
      }
      &:active {
        @apply border-transparent bg-transparent;
      }
      &:focus {
        @apply border-transparent bg-transparent;
      }
      &:focus:active {
        @apply border-transparent bg-transparent;
      }
      &:disabled {
        @apply text-blue-100 cursor-not-allowed;
      }
    }
    &.meey-button-link.meey-button-icon-only {
      @apply bg-transparent p-2.5;
    }
  }
  &.meey-button-secondary {
    &.meey-button-outline {
      @apply bg-transparent border border-solid border-blue-500 text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-500;
      }
      &:active {
        @apply bg-blue-100;
      }
      &:focus {
        @apply bg-white;
      }
      &:focus:active {
        @apply bg-blue-100;
      }
      &:disabled {
        @apply border-grey-200 text-grey-400 cursor-not-allowed;
      }
    }

    &.meey-button-filled {
      @apply bg-blue-100 border border-solid border-blue-100 text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-200 border-blue-200;
      }
      &:active {
        @apply bg-blue-600 border-blue-400;
      }
      &:focus {
        @apply bg-blue-200 border-blue-200 text-blue-600;
      }
      &:focus:active {
        @apply bg-blue-200 border-blue-200 text-blue-600;
      }
      &:disabled {
        @apply border-grey-200 bg-grey-200  text-grey-400 cursor-not-allowed;
      }
    }

    &.meey-button-link {
      @apply bg-white border border-solid border-transparent text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-500;
      }
      &:active {
        @apply border-blue-500 bg-blue-500;
      }
      &:focus {
        @apply border-blue-600;
      }
      &:focus:active {
        @apply bg-blue-500;
      }
      &:disabled {
        @apply text-blue-100 cursor-not-allowed;
      }
    }
    &.meey-button-link.meey-button-icon-only {
      @apply bg-transparent p-2.5;
    }
  }
  &.meey-button-white {
    &.meey-button-outline {
      @apply bg-transparent border border-solid border-white text-white font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-[rgba(255,255,255,0.2)];
      }
      &:active {
        @apply bg-[rgba(255,255,255,0.3)];
      }
      &:focus {
        @apply bg-[rgba(255,255,255,0.5)];
      }
      &:focus:active {
        @apply bg-[rgba(255,255,255,0.3)];
      }
      &:disabled {
        @apply border-blue-200 text-blue-100 cursor-not-allowed;
      }
    }

    &.meey-button-filled {
      @apply bg-blue-500 border border-solid border-blue-500 text-white font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-400 border-blue-400;
      }
      &:active {
        @apply bg-blue-600 border-blue-400;
      }
      &:focus {
        @apply bg-blue-700 border-blue-700;
      }
      &:focus:active {
        @apply bg-blue-600 border-blue-600;
      }
      &:disabled {
        @apply border-blue-500  text-blue-200 cursor-not-allowed;
      }
    }

    &.meey-button-link {
      @apply bg-white border border-solid border-transparent text-blue-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-blue-500;
      }
      &:active {
        @apply border-blue-500 bg-blue-500;
      }
      &:focus {
        @apply border-blue-600;
      }
      &:focus:active {
        @apply bg-blue-500;
      }
      &:disabled {
        @apply text-blue-100 cursor-not-allowed;
      }
    }
  }

  &.meey-button-grey {
    &.meey-button-outline {
      @apply bg-transparent border border-solid border-grey-300 text-grey-600 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-grey-300;
      }
      &:active {
        @apply bg-grey-200;
      }
      &:focus {
        @apply bg-grey-200 text-grey-800;
      }
      &:focus:active {
        @apply bg-grey-200;
      }
      &:disabled {
        @apply border-blue-200 text-blue-100 cursor-not-allowed;
      }
    }

    &.meey-button-filled {
      @apply bg-grey-100 border border-solid border-grey-100 text-grey-600 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-grey-200 border-grey-200;
      }
      &:active {
        @apply bg-grey-200 border-grey-200;
      }
      &:focus {
        @apply bg-grey-200 border-grey-200 text-grey-800;
      }
      &:focus:active {
        @apply bg-grey-200 border-grey-200;
      }
      &:disabled {
        @apply border-grey-100  text-grey-300 cursor-not-allowed;
      }
    }

    &.meey-button-link {
      @apply bg-white border border-solid border-transparent text-grey-500 font-medium cursor-pointer duration-300;

      &:hover {
        @apply bg-grey-500 text-white;
      }
      &:active {
        @apply border-grey-500 bg-grey-500;
      }
      &:focus {
        @apply border-grey-600;
      }
      &:focus:active {
        @apply bg-grey-500;
      }
      &:disabled {
        @apply text-grey-100 cursor-not-allowed;
      }
    }
    &.meey-button-link.meey-button-icon-only {
      @apply bg-transparent;
    }
  }

  // &.meey-button-default {
  // 	&.meey-button-outline {
  // 		@apply bg-white border border-solid border-blue-500 text-blue-500 font-medium cursor-pointer duration-300;

  // 		&:hover {
  // 			@apply bg-blue-500 ;
  // 		}
  // 		&:active {
  // 			@apply bg-blue-100;
  // 		}
  // 		&:focus {
  // 			@apply bg-white;
  // 		}
  // 		&:focus:active {
  // 			@apply bg-blue-100;
  // 		}
  // 		&:disabled {
  // 			@apply border-blue-200 text-blue-100 cursor-not-allowed;
  // 		}
  // 	}

  // 	&.meey-button-filled {
  // 		@apply bg-blue-400 border border-solid border-blue-400 text-white font-medium cursor-pointer duration-300;

  // 		&:hover {
  // 			@apply bg-blue-400;
  // 		}
  // 		&:active {
  // 			@apply bg-blue-600;
  // 		}
  // 		&:focus {
  // 			@apply bg-blue-700;
  // 		}
  // 		&:focus:active {
  // 			@apply bg-blue-600;
  // 		}
  // 		&:disabled {
  // 			@apply border-blue-500  text-blue-200 cursor-not-allowed;
  // 		}
  // 	}

  // 	&.meey-button-link {
  // 		@apply bg-white border border-solid border-transparent text-blue-500 font-medium cursor-pointer duration-300;

  // 		&:hover {
  // 			@apply bg-blue-500 ;
  // 		}
  // 		&:active {
  // 			@apply border-blue-500 bg-blue-500 ;
  // 		}
  // 		&:focus {
  // 			@apply border-blue-600;
  // 		}
  // 		&:focus:active {
  // 			@apply bg-blue-500 ;
  // 		}
  // 		&:disabled {
  // 			@apply text-blue-100 cursor-not-allowed;
  // 		}
  // 	}
  // }
}
