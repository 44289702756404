.wrap-modal {
  @apply fixed w-screen top-0 left-0 h-screen flex items-center justify-center z-40 bg-[rgba(0,0,0,0.5)];
  .wrap-modal-content {
    @apply rounded-lg min-w-[20rem] bg-white;
    .modal-header {
      @apply bg-blue-50 py-3 px-6 flex items-center justify-between rounded-t-lg text-fs-20 font-semibold text-blue-600;
    }
    .modal-body {
      @apply p-4;
    }
    .modal-footer {
      @apply flex justify-end gap-4 py-3 px-4 border-t border-t-grey-200;
    }
  }
}
.ant-modal-confirm-content {
  @apply w-full;
}
