.home-tabs {
  @apply flex;
  .home-tab {
    @apply font-medium flex items-center gap-1.5 text-grey-500 px-4 py-2.5 cursor-pointer relative;
    &::after {
      content: "";
      @apply absolute w-full h-full bottom-0 left-0 border-b-2 duration-300 border-b-transparent;
    }
    &:hover {
      @apply text-blue-500;
    }
    &:hover::after {
      @apply border-b-blue-500;
    }
    &.home-tab-active {
      @apply text-blue-500;
      &::after {
        @apply border-b-blue-500;
      }
    }
  }
}
