.form-item {
  @apply mb-5;
  .form-item-error {
    @apply text-red-500 text-fs-14;
  }
  .form-item-label {
    @apply text-fs-14 text-grey-600 mb-1;
    label {
      height: fit-content !important;
    }
  }
  &.form-item-error {
    .mrv-input {
      @apply border-red-500;
    }
  }
}

.ant-form-item {
  @apply mb-5;
  .ant-form-item-label {
    @apply mb-1 pb-0;
    label {
      @apply text-grey-600 font-medium relative h-fit;
      &::before {
        @apply absolute -right-4;
      }
      &::after {
        margin: -3px !important;
      }
    }
  }
}
.ant-form-item.detail-rate {
  .ant-form-item-label {
    @apply mb-0 flex items-center;
    label {
      @apply text-fs-12 mb-0;
    }
  }
}
