.mrv-input {
  @apply border border-grey-200 py-2 px-3 rounded-md w-full;
}
.ant-rate-star.ant-rate-star-zero {
  div {
    div {
      @apply text-grey-300 #{!important};
    }
  }
}
.ant-rate .ant-rate-star-second {
  @apply text-grey-300 #{!important};
}
.ant-rate-star.ant-rate-star-full {
  div {
    div {
      @apply text-[#FFC633] #{!important};
    }
  }
}
.ant-rate-star.ant-rate-star-half {
  div {
    .ant-rate-star-first {
      @apply text-[#FFC633] #{!important};
    }
    .ant-rate-star-second {
      @apply text-grey-300 #{!important};
    }
  }
}
.ant-input {
  @apply border-grey-200;
}
.ant-input.ant-input-lg {
  @apply h-[44px];
}
.ant-input-focused {
  border-color: #4096ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  border-inline-end-width: 1px;
  outline: 0;
}
