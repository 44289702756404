// .ant-notification-notice-wrapper {
//   @apply relative #{!important};
// }

.ant-notification .ant-notification-notice {
  @apply p-3.5;
  .ant-notification-notice-content {
    @apply mr-4;
    .ant-notification-notice-message {
      @apply text-grey-900 font-medium mb-0;
    }
    .ant-notification-notice-description {
      @apply text-grey-700;
    }
  }
  .ant-notification-notice-close {
    @apply right-3 top-4;
  }
}
