.meey-popover {
  @apply relative;
  .popover-overlay {
    @apply fixed z-[31] shadow-md  bg-white pt-2  overflow-auto;
    &.popover-overlay-bottom-left {
      @apply scale-0 -translate-x-1/2 -translate-y-1/2;
    }
    &.popover-overlay-top-center {
      @apply scale-0 -translate-x-1/2 -translate-y-1/2;
    }
    // &.popover-overlay-bottom-right {
    //   @apply scale-0 translate-x-1/2 -translate-y-1/2 right-0;
    // }
    // &.popover-overlay-left-top {
    //   @apply scale-0 translate-x-1/2 translate-y-1/2 left-0;
    // }

    &.popover-overlay-open {
      @apply scale-100 translate-x-0 translate-y-0;
    }
  }
}
