
@import "./variables.scss";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url($icon-font-path) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mrv {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mrv-building {
  &:before {
    content: $mrv-building; 
  }
}
.mrv-review {
  &:before {
    content: $mrv-review; 
  }
}
.mrv-crown1 {
  &:before {
    content: $mrv-crown1; 
  }
}
.mrv-crown {
  &:before {
    content: $mrv-crown; 
  }
}
.mrv-k {
  &:before {
    content: $mrv-k;
  }
}
.mrv-k_plus {
  &:before {
    content: $mrv-k_plus;
  }
}
.mrv-x_mobiledata {
  &:before {
    content: $mrv-x_mobiledata;
  }
}
.mrv-k1 {
  &:before {
    content: $mrv-k1;
  }
}
.mrv-k_plus1 {
  &:before {
    content: $mrv-k_plus1;
  }
}
.mrv-mp {
  &:before {
    content: $mrv-mp;
  }
}
.mrv-d_rotation {
  &:before {
    content: $mrv-d_rotation;
  }
}
.mrv-g_mobiledata {
  &:before {
    content: $mrv-g_mobiledata;
  }
}
.mrv-k2 {
  &:before {
    content: $mrv-k2;
  }
}
.mrv-k_plus2 {
  &:before {
    content: $mrv-k_plus2;
  }
}
.mrv-mp1 {
  &:before {
    content: $mrv-mp1;
  }
}
.mrv-p {
  &:before {
    content: $mrv-p;
  }
}
.mrv-g_mobiledata1 {
  &:before {
    content: $mrv-g_mobiledata1;
  }
}
.mrv-g_plus_mobiledata {
  &:before {
    content: $mrv-g_plus_mobiledata;
  }
}
.mrv-k3 {
  &:before {
    content: $mrv-k3;
  }
}
.mrv-k_plus3 {
  &:before {
    content: $mrv-k_plus3;
  }
}
.mrv-mp2 {
  &:before {
    content: $mrv-mp2;
  }
}
.mrv-g {
  &:before {
    content: $mrv-g;
  }
}
.mrv-k4 {
  &:before {
    content: $mrv-k4;
  }
}
.mrv-k_plus4 {
  &:before {
    content: $mrv-k_plus4;
  }
}
.mrv-mp3 {
  &:before {
    content: $mrv-mp3;
  }
}
.mrv-ft_apart {
  &:before {
    content: $mrv-ft_apart;
  }
}
.mrv-k5 {
  &:before {
    content: $mrv-k5;
  }
}
.mrv-k_plus5 {
  &:before {
    content: $mrv-k_plus5;
  }
}
.mrv-mp4 {
  &:before {
    content: $mrv-mp4;
  }
}
.mrv-k6 {
  &:before {
    content: $mrv-k6;
  }
}
.mrv-k_plus6 {
  &:before {
    content: $mrv-k_plus6;
  }
}
.mrv-mp5 {
  &:before {
    content: $mrv-mp5;
  }
}
.mrv-k7 {
  &:before {
    content: $mrv-k7;
  }
}
.mrv-k_plus7 {
  &:before {
    content: $mrv-k_plus7;
  }
}
.mrv-mp6 {
  &:before {
    content: $mrv-mp6;
  }
}
.mrv-k8 {
  &:before {
    content: $mrv-k8;
  }
}
.mrv-k_plus8 {
  &:before {
    content: $mrv-k_plus8;
  }
}
.mrv-mp7 {
  &:before {
    content: $mrv-mp7;
  }
}
.mrv-k9 {
  &:before {
    content: $mrv-k9;
  }
}
.mrv-mp8 {
  &:before {
    content: $mrv-mp8;
  }
}
.mrv-mp9 {
  &:before {
    content: $mrv-mp9;
  }
}
.mrv-mp10 {
  &:before {
    content: $mrv-mp10;
  }
}
.mrv-mp11 {
  &:before {
    content: $mrv-mp11;
  }
}
.mrv-mp12 {
  &:before {
    content: $mrv-mp12;
  }
}
.mrv-mp13 {
  &:before {
    content: $mrv-mp13;
  }
}
.mrv-mp14 {
  &:before {
    content: $mrv-mp14;
  }
}
.mrv-mp15 {
  &:before {
    content: $mrv-mp15;
  }
}
.mrv-up_rating {
  &:before {
    content: $mrv-up_rating;
  }
}
.mrv-mp16 {
  &:before {
    content: $mrv-mp16;
  }
}
.mrv-mp17 {
  &:before {
    content: $mrv-mp17;
  }
}
.mrv-mp18 {
  &:before {
    content: $mrv-mp18;
  }
}
.mrv-mp19 {
  &:before {
    content: $mrv-mp19;
  }
}
.mrv-mp20 {
  &:before {
    content: $mrv-mp20;
  }
}
.mrv-mp21 {
  &:before {
    content: $mrv-mp21;
  }
}
.mrv-mp22 {
  &:before {
    content: $mrv-mp22;
  }
}
.mrv-fps {
  &:before {
    content: $mrv-fps;
  }
}
.mrv-fps_select {
  &:before {
    content: $mrv-fps_select;
  }
}
.mrv-fps1 {
  &:before {
    content: $mrv-fps1;
  }
}
.mrv-fps_select1 {
  &:before {
    content: $mrv-fps_select1;
  }
}
.mrv-123 {
  &:before {
    content: $mrv-123;
  }
}
.mrv-360 {
  &:before {
    content: $mrv-360;
  }
}
.mrv-abc {
  &:before {
    content: $mrv-abc;
  }
}
.mrv-ac_unit {
  &:before {
    content: $mrv-ac_unit;
  }
}
.mrv-accessibility {
  &:before {
    content: $mrv-accessibility;
  }
}
.mrv-accessibility_new {
  &:before {
    content: $mrv-accessibility_new;
  }
}
.mrv-accessible {
  &:before {
    content: $mrv-accessible;
  }
}
.mrv-accessible_forward {
  &:before {
    content: $mrv-accessible_forward;
  }
}
.mrv-account_balance {
  &:before {
    content: $mrv-account_balance;
  }
}
.mrv-account_balance_wallet {
  &:before {
    content: $mrv-account_balance_wallet;
  }
}
.mrv-account_box {
  &:before {
    content: $mrv-account_box;
  }
}
.mrv-account_circle {
  &:before {
    content: $mrv-account_circle;
  }
}
.mrv-account_circle_full {
  &:before {
    content: $mrv-account_circle_full;
  }
}
.mrv-account_tree {
  &:before {
    content: $mrv-account_tree;
  }
}
.mrv-ad_units {
  &:before {
    content: $mrv-ad_units;
  }
}
.mrv-adb {
  &:before {
    content: $mrv-adb;
  }
}
.mrv-add {
  &:before {
    content: $mrv-add; 
  }
}
.mrv-add_a_photo {
  &:before {
    content: $mrv-add_a_photo;
  }
}
.mrv-add_alert {
  &:before {
    content: $mrv-add_alert;
  }
}
.mrv-add_box {
  &:before {
    content: $mrv-add_box;
  }
}
.mrv-add_business {
  &:before {
    content: $mrv-add_business;
  }
}
.mrv-add_call {
  &:before {
    content: $mrv-add_call;
  }
}
.mrv-add_card {
  &:before {
    content: $mrv-add_card;
  }
}
.mrv-add_chart {
  &:before {
    content: $mrv-add_chart;
  }
}
.mrv-add_circle {
  &:before {
    content: $mrv-add_circle;
  }
}
.mrv-add_comment {
  &:before {
    content: $mrv-add_comment;
  }
}
.mrv-add_link {
  &:before {
    content: $mrv-add_link;
  }
}
.mrv-add_location {
  &:before {
    content: $mrv-add_location;
  }
}
.mrv-add_location_alt {
  &:before {
    content: $mrv-add_location_alt;
  }
}
.mrv-add_moderator {
  &:before {
    content: $mrv-add_moderator;
  }
}
.mrv-add_photo_alternate {
  &:before {
    content: $mrv-add_photo_alternate; 
  }
}
.mrv-add_reaction {
  &:before {
    content: $mrv-add_reaction;
  }
}
.mrv-add_road {
  &:before {
    content: $mrv-add_road;
  }
}
.mrv-add_shopping_cart {
  &:before {
    content: $mrv-add_shopping_cart;
  }
}
.mrv-add_shortcut_to_drive {
  &:before {
    content: $mrv-add_shortcut_to_drive;
  }
}
.mrv-add_task {
  &:before {
    content: $mrv-add_task;
  }
}
.mrv-add_to_drive {
  &:before {
    content: $mrv-add_to_drive;
  }
}
.mrv-add_to_home_screen {
  &:before {
    content: $mrv-add_to_home_screen;
  }
}
.mrv-add_to_photos {
  &:before {
    content: $mrv-add_to_photos;
  }
}
.mrv-add_to_queue {
  &:before {
    content: $mrv-add_to_queue;
  }
}
.mrv-adf_scanner {
  &:before {
    content: $mrv-adf_scanner;
  }
}
.mrv-adjust {
  &:before {
    content: $mrv-adjust;
  }
}
.mrv-admin_panel_settings {
  &:before {
    content: $mrv-admin_panel_settings;
  }
}
.mrv-ads_click {
  &:before {
    content: $mrv-ads_click;
  }
}
.mrv-agriculture {
  &:before {
    content: $mrv-agriculture;
  }
}
.mrv-air {
  &:before {
    content: $mrv-air;
  }
}
.mrv-air_freshener {
  &:before {
    content: $mrv-air_freshener;
  }
}
.mrv-air_purifier_gen {
  &:before {
    content: $mrv-air_purifier_gen;
  }
}
.mrv-airline_seat_flat {
  &:before {
    content: $mrv-airline_seat_flat;
  }
}
.mrv-airline_seat_flat_angled {
  &:before {
    content: $mrv-airline_seat_flat_angled;
  }
}
.mrv-airline_seat_individual_suite {
  &:before {
    content: $mrv-airline_seat_individual_suite;
  }
}
.mrv-airline_seat_legroom_extra {
  &:before {
    content: $mrv-airline_seat_legroom_extra;
  }
}
.mrv-airline_seat_legroom_normal {
  &:before {
    content: $mrv-airline_seat_legroom_normal;
  }
}
.mrv-airline_seat_legroom_reduced {
  &:before {
    content: $mrv-airline_seat_legroom_reduced;
  }
}
.mrv-airline_seat_recline_extra {
  &:before {
    content: $mrv-airline_seat_recline_extra;
  }
}
.mrv-airline_seat_recline_normal {
  &:before {
    content: $mrv-airline_seat_recline_normal;
  }
}
.mrv-airline_stops {
  &:before {
    content: $mrv-airline_stops;
  }
}
.mrv-airlines {
  &:before {
    content: $mrv-airlines;
  }
}
.mrv-airplane_ticket {
  &:before {
    content: $mrv-airplane_ticket;
  }
}
.mrv-airplanemode_active {
  &:before {
    content: $mrv-airplanemode_active;
  }
}
.mrv-airplanemode_inactive {
  &:before {
    content: $mrv-airplanemode_inactive;
  }
}
.mrv-airplay {
  &:before {
    content: $mrv-airplay;
  }
}
.mrv-airport_shuttle {
  &:before {
    content: $mrv-airport_shuttle;
  }
}
.mrv-airware {
  &:before {
    content: $mrv-airware;
  }
}
.mrv-alarm {
  &:before {
    content: $mrv-alarm;
  }
}
.mrv-alarm_add {
  &:before {
    content: $mrv-alarm_add;
  }
}
.mrv-alarm_off {
  &:before {
    content: $mrv-alarm_off;
  }
}
.mrv-alarm_on {
  &:before {
    content: $mrv-alarm_on;
  }
}
.mrv-album {
  &:before {
    content: $mrv-album;
  }
}
.mrv-align_horizontal_center {
  &:before {
    content: $mrv-align_horizontal_center;
  }
}
.mrv-align_horizontal_left {
  &:before {
    content: $mrv-align_horizontal_left;
  }
}
.mrv-align_horizontal_right {
  &:before {
    content: $mrv-align_horizontal_right;
  }
}
.mrv-align_vertical_bottom {
  &:before {
    content: $mrv-align_vertical_bottom;
  }
}
.mrv-align_vertical_center {
  &:before {
    content: $mrv-align_vertical_center;
  }
}
.mrv-align_vertical_top {
  &:before {
    content: $mrv-align_vertical_top;
  }
}
.mrv-all_inbox {
  &:before {
    content: $mrv-all_inbox;
  }
}
.mrv-all_inclusive {
  &:before {
    content: $mrv-all_inclusive;
  }
}
.mrv-all_out {
  &:before {
    content: $mrv-all_out;
  }
}
.mrv-alt_route {
  &:before {
    content: $mrv-alt_route;
  }
}
.mrv-alternate_email {
  &:before {
    content: $mrv-alternate_email;
  }
}
.mrv-amp_error {
  &:before {
    content: $mrv-amp_error;
  }
}
.mrv-amp_stories {
  &:before {
    content: $mrv-amp_stories;
  }
}
.mrv-analytics {
  &:before {
    content: $mrv-analytics;
  }
}
.mrv-anchor {
  &:before {
    content: $mrv-anchor;
  }
}
.mrv-android {
  &:before {
    content: $mrv-android;
  }
}
.mrv-android_calculator {
  &:before {
    content: $mrv-android_calculator;
  }
}
.mrv-android_camera {
  &:before {
    content: $mrv-android_camera;
  }
}
.mrv-android_chat {
  &:before {
    content: $mrv-android_chat;
  }
}
.mrv-android_contacts {
  &:before {
    content: $mrv-android_contacts;
  }
}
.mrv-android_dialer {
  &:before {
    content: $mrv-android_dialer;
  }
}
.mrv-android_emergency_location_service {
  &:before {
    content: $mrv-android_emergency_location_service;
  }
}
.mrv-android_family_link {
  &:before {
    content: $mrv-android_family_link;
  }
}
.mrv-android_find_my_device {
  &:before {
    content: $mrv-android_find_my_device;
  }
}
.mrv-android_google_home {
  &:before {
    content: $mrv-android_google_home;
  }
}
.mrv-android_messages {
  &:before {
    content: $mrv-android_messages;
  }
}
.mrv-android_now_playing {
  &:before {
    content: $mrv-android_now_playing;
  }
}
.mrv-android_recorder {
  &:before {
    content: $mrv-android_recorder;
  }
}
.mrv-android_safety {
  &:before {
    content: $mrv-android_safety;
  }
}
.mrv-animation {
  &:before {
    content: $mrv-animation;
  }
}
.mrv-aod {
  &:before {
    content: $mrv-aod;
  }
}
.mrv-apartment {
  &:before {
    content: $mrv-apartment;
  }
}
.mrv-api {
  &:before {
    content: $mrv-api;
  }
}
.mrv-app_blocking {
  &:before {
    content: $mrv-app_blocking;
  }
}
.mrv-app_registration {
  &:before {
    content: $mrv-app_registration;
  }
}
.mrv-app_shortcut {
  &:before {
    content: $mrv-app_shortcut;
  }
}
.mrv-approval {
  &:before {
    content: $mrv-approval;
  }
}
.mrv-apps {
  &:before {
    content: $mrv-apps;
  }
}
.mrv-apps_outage {
  &:before {
    content: $mrv-apps_outage;
  }
}
.mrv-architecture {
  &:before {
    content: $mrv-architecture;
  }
}
.mrv-archive {
  &:before {
    content: $mrv-archive;
  }
}
.mrv-area_chart {
  &:before {
    content: $mrv-area_chart;
  }
}
.mrv-arrow_back {
  &:before {
    content: $mrv-arrow_back;
  }
}
.mrv-arrow_back_ios {
  &:before {
    content: $mrv-arrow_back_ios;
  }
}
.mrv-arrow_back_ios_new {
  &:before {
    content: $mrv-arrow_back_ios_new;
  }
}
.mrv-arrow_circle_down {
  &:before {
    content: $mrv-arrow_circle_down;
  }
}
.mrv-arrow_circle_left {
  &:before {
    content: $mrv-arrow_circle_left;
  }
}
.mrv-arrow_circle_right {
  &:before {
    content: $mrv-arrow_circle_right;
  }
}
.mrv-arrow_circle_up {
  &:before {
    content: $mrv-arrow_circle_up;
  }
}
.mrv-arrow_downward {
  &:before {
    content: $mrv-arrow_downward;
  }
}
.mrv-arrow_drop_down {
  &:before {
    content: $mrv-arrow_drop_down;
  }
}
.mrv-arrow_drop_down_circle {
  &:before {
    content: $mrv-arrow_drop_down_circle;
  }
}
.mrv-arrow_drop_up {
  &:before {
    content: $mrv-arrow_drop_up;
  }
}
.mrv-arrow_forward {
  &:before {
    content: $mrv-arrow_forward;
  }
}
.mrv-arrow_forward_ios {
  &:before {
    content: $mrv-arrow_forward_ios;
  }
}
.mrv-arrow_left {
  &:before {
    content: $mrv-arrow_left;
  }
}
.mrv-arrow_right {
  &:before {
    content: $mrv-arrow_right;
  }
}
.mrv-arrow_right_alt {
  &:before {
    content: $mrv-arrow_right_alt;
  }
}
.mrv-arrow_upward {
  &:before {
    content: $mrv-arrow_upward;
  }
}
.mrv-art_track {
  &:before {
    content: $mrv-art_track;
  }
}
.mrv-article {
  &:before {
    content: $mrv-article;
  }
}
.mrv-aspect_ratio {
  &:before {
    content: $mrv-aspect_ratio;
  }
}
.mrv-assignment {
  &:before {
    content: $mrv-assignment;
  }
}
.mrv-assignment_ind {
  &:before {
    content: $mrv-assignment_ind;
  }
}
.mrv-assignment_late {
  &:before {
    content: $mrv-assignment_late;
  }
}
.mrv-assignment_return {
  &:before {
    content: $mrv-assignment_return;
  }
}
.mrv-assignment_returned {
  &:before {
    content: $mrv-assignment_returned;
  }
}
.mrv-assignment_turned_in {
  &:before {
    content: $mrv-assignment_turned_in;
  }
}
.mrv-assistant {
  &:before {
    content: $mrv-assistant;
  }
}
.mrv-assistant_direction {
  &:before {
    content: $mrv-assistant_direction;
  }
}
.mrv-assistant_navigation {
  &:before {
    content: $mrv-assistant_navigation;
  }
}
.mrv-assured_workload {
  &:before {
    content: $mrv-assured_workload;
  }
}
.mrv-astrophotography_auto {
  &:before {
    content: $mrv-astrophotography_auto;
  }
}
.mrv-astrophotography_off {
  &:before {
    content: $mrv-astrophotography_off;
  }
}
.mrv-atm {
  &:before {
    content: $mrv-atm;
  }
}
.mrv-atr {
  &:before {
    content: $mrv-atr;
  }
}
.mrv-attach_email {
  &:before {
    content: $mrv-attach_email;
  }
}
.mrv-attach_file {
  &:before {
    content: $mrv-attach_file; 
  }
}
.mrv-attach_money {
  &:before {
    content: $mrv-attach_money;
  }
}
.mrv-attachment {
  &:before {
    content: $mrv-attachment;
  }
}
.mrv-attractions {
  &:before {
    content: $mrv-attractions;
  }
}
.mrv-attribution {
  &:before {
    content: $mrv-attribution;
  }
}
.mrv-audio_file {
  &:before {
    content: $mrv-audio_file;
  }
}
.mrv-authenticator {
  &:before {
    content: $mrv-authenticator;
  }
}
.mrv-auto_awesome {
  &:before {
    content: $mrv-auto_awesome;
  }
}
.mrv-auto_awesome_mosaic {
  &:before {
    content: $mrv-auto_awesome_mosaic;
  }
}
.mrv-auto_awesome_motion {
  &:before {
    content: $mrv-auto_awesome_motion;
  }
}
.mrv-auto_delete {
  &:before {
    content: $mrv-auto_delete;
  }
}
.mrv-auto_graph {
  &:before {
    content: $mrv-auto_graph;
  }
}
.mrv-auto_mode {
  &:before {
    content: $mrv-auto_mode;
  }
}
.mrv-auto_read_pause {
  &:before {
    content: $mrv-auto_read_pause;
  }
}
.mrv-auto_read_play {
  &:before {
    content: $mrv-auto_read_play;
  }
}
.mrv-auto_stories {
  &:before {
    content: $mrv-auto_stories;
  }
}
.mrv-autofps_select {
  &:before {
    content: $mrv-autofps_select;
  }
}
.mrv-autorenew {
  &:before {
    content: $mrv-autorenew;
  }
}
.mrv-av_timer .path1 {
  &:before {
    content: $mrv-av_timer-path1;
    color: rgb(255, 255, 255);
  }
}
.mrv-av_timer .path2 {
  &:before {
    content: $mrv-av_timer-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.mrv-baby_changing_station {
  &:before {
    content: $mrv-baby_changing_station;
  }
}
.mrv-back_hand {
  &:before {
    content: $mrv-back_hand;
  }
}
.mrv-background_replace {
  &:before {
    content: $mrv-background_replace;
  }
}
.mrv-backpack {
  &:before {
    content: $mrv-backpack;
  }
}
.mrv-backspace {
  &:before {
    content: $mrv-backspace;
  }
}
.mrv-backup {
  &:before {
    content: $mrv-backup;
  }
}
.mrv-backup_table {
  &:before {
    content: $mrv-backup_table;
  }
}
.mrv-badge {
  &:before {
    content: $mrv-badge;
  }
}
.mrv-badge_critical_battery {
  &:before {
    content: $mrv-badge_critical_battery;
  }
}
.mrv-bakery_dining {
  &:before {
    content: $mrv-bakery_dining;
  }
}
.mrv-balance {
  &:before {
    content: $mrv-balance;
  }
}
.mrv-balcony {
  &:before {
    content: $mrv-balcony;
  }
}
.mrv-ballot {
  &:before {
    content: $mrv-ballot;
  }
}
.mrv-bar_chart {
  &:before {
    content: $mrv-bar_chart;
  }
}
.mrv-batch_prediction {
  &:before {
    content: $mrv-batch_prediction;
  }
}
.mrv-bathroom {
  &:before {
    content: $mrv-bathroom;
  }
}
.mrv-bathtub {
  &:before {
    content: $mrv-bathtub;
  }
}
.mrv-battery_0_bar {
  &:before {
    content: $mrv-battery_0_bar;
  }
}
.mrv-battery_1_bar {
  &:before {
    content: $mrv-battery_1_bar;
  }
}
.mrv-battery_2_bar {
  &:before {
    content: $mrv-battery_2_bar;
  }
}
.mrv-battery_3_bar {
  &:before {
    content: $mrv-battery_3_bar;
  }
}
.mrv-battery_4_bar {
  &:before {
    content: $mrv-battery_4_bar;
  }
}
.mrv-battery_5_bar {
  &:before {
    content: $mrv-battery_5_bar;
  }
}
.mrv-battery_6_bar {
  &:before {
    content: $mrv-battery_6_bar;
  }
}
.mrv-battery_20 {
  &:before {
    content: $mrv-battery_20;
  }
}
.mrv-battery_30 {
  &:before {
    content: $mrv-battery_30;
  }
}
.mrv-battery_50 {
  &:before {
    content: $mrv-battery_50;
  }
}
.mrv-battery_60 {
  &:before {
    content: $mrv-battery_60;
  }
}
.mrv-battery_80 {
  &:before {
    content: $mrv-battery_80;
  }
}
.mrv-battery_90 {
  &:before {
    content: $mrv-battery_90;
  }
}
.mrv-battery_alert {
  &:before {
    content: $mrv-battery_alert;
  }
}
.mrv-battery_charging_20 {
  &:before {
    content: $mrv-battery_charging_20;
  }
}
.mrv-battery_charging_30 {
  &:before {
    content: $mrv-battery_charging_30;
  }
}
.mrv-battery_charging_50 {
  &:before {
    content: $mrv-battery_charging_50;
  }
}
.mrv-battery_charging_60 {
  &:before {
    content: $mrv-battery_charging_60;
  }
}
.mrv-battery_charging_80 {
  &:before {
    content: $mrv-battery_charging_80;
  }
}
.mrv-battery_charging_90 {
  &:before {
    content: $mrv-battery_charging_90;
  }
}
.mrv-battery_charging_full {
  &:before {
    content: $mrv-battery_charging_full;
  }
}
.mrv-battery_full {
  &:before {
    content: $mrv-battery_full;
  }
}
.mrv-battery_full_alt {
  &:before {
    content: $mrv-battery_full_alt;
  }
}
.mrv-battery_low {
  &:before {
    content: $mrv-battery_low;
  }
}
.mrv-battery_saver {
  &:before {
    content: $mrv-battery_saver;
  }
}
.mrv-battery_unknown {
  &:before {
    content: $mrv-battery_unknown;
  }
}
.mrv-battery_very_low {
  &:before {
    content: $mrv-battery_very_low;
  }
}
.mrv-beach_access {
  &:before {
    content: $mrv-beach_access;
  }
}
.mrv-bed {
  &:before {
    content: $mrv-bed;
  }
}
.mrv-bedroom_baby {
  &:before {
    content: $mrv-bedroom_baby;
  }
}
.mrv-bedroom_child {
  &:before {
    content: $mrv-bedroom_child;
  }
}
.mrv-bedroom_parent {
  &:before {
    content: $mrv-bedroom_parent;
  }
}
.mrv-bedtime {
  &:before {
    content: $mrv-bedtime;
  }
}
.mrv-bedtime_off {
  &:before {
    content: $mrv-bedtime_off;
  }
}
.mrv-beenhere {
  &:before {
    content: $mrv-beenhere;
  }
}
.mrv-bento {
  &:before {
    content: $mrv-bento;
  }
}
.mrv-bike_scooter {
  &:before {
    content: $mrv-bike_scooter;
  }
}
.mrv-biotech {
  &:before {
    content: $mrv-biotech;
  }
}
.mrv-blanket {
  &:before {
    content: $mrv-blanket;
  }
}
.mrv-blender {
  &:before {
    content: $mrv-blender;
  }
}
.mrv-blinds {
  &:before {
    content: $mrv-blinds;
  }
}
.mrv-blinds_closed {
  &:before {
    content: $mrv-blinds_closed;
  }
}
.mrv-block {
  &:before {
    content: $mrv-block;
  }
}
.mrv-bloodtype {
  &:before {
    content: $mrv-bloodtype;
  }
}
.mrv-bluenote {
  &:before {
    content: $mrv-bluenote;
  }
}
.mrv-bluetooth {
  &:before {
    content: $mrv-bluetooth;
  }
}
.mrv-bluetooth_connected {
  &:before {
    content: $mrv-bluetooth_connected;
  }
}
.mrv-bluetooth_disabled {
  &:before {
    content: $mrv-bluetooth_disabled;
  }
}
.mrv-bluetooth_drive {
  &:before {
    content: $mrv-bluetooth_drive;
  }
}
.mrv-bluetooth_searching {
  &:before {
    content: $mrv-bluetooth_searching;
  }
}
.mrv-blur_circular {
  &:before {
    content: $mrv-blur_circular;
  }
}
.mrv-blur_linear {
  &:before {
    content: $mrv-blur_linear;
  }
}
.mrv-blur_medium {
  &:before {
    content: $mrv-blur_medium;
  }
}
.mrv-blur_off {
  &:before {
    content: $mrv-blur_off;
  }
}
.mrv-blur_on {
  &:before {
    content: $mrv-blur_on;
  }
}
.mrv-blur_short {
  &:before {
    content: $mrv-blur_short;
  }
}
.mrv-bnagent {
  &:before {
    content: $mrv-bnagent;
  }
}
.mrv-bolt {
  &:before {
    content: $mrv-bolt;
  }
}
.mrv-book {
  &:before {
    content: $mrv-book;
  }
}
.mrv-book_online {
  &:before {
    content: $mrv-book_online;
  }
}
.mrv-bookmark {
  &:before {
    content: $mrv-bookmark;
  }
}
.mrv-bookmark_add {
  &:before {
    content: $mrv-bookmark_add;
  }
}
.mrv-bookmark_added {
  &:before {
    content: $mrv-bookmark_added;
  }
}
.mrv-bookmark_remove {
  &:before {
    content: $mrv-bookmark_remove;
  }
}
.mrv-bookmarks {
  &:before {
    content: $mrv-bookmarks;
  }
}
.mrv-border_all {
  &:before {
    content: $mrv-border_all;
  }
}
.mrv-border_bottom {
  &:before {
    content: $mrv-border_bottom;
  }
}
.mrv-border_clear {
  &:before {
    content: $mrv-border_clear;
  }
}
.mrv-border_color {
  &:before {
    content: $mrv-border_color;
  }
}
.mrv-border_horizontal {
  &:before {
    content: $mrv-border_horizontal;
  }
}
.mrv-border_inner {
  &:before {
    content: $mrv-border_inner;
  }
}
.mrv-border_left {
  &:before {
    content: $mrv-border_left;
  }
}
.mrv-border_outer {
  &:before {
    content: $mrv-border_outer;
  }
}
.mrv-border_right {
  &:before {
    content: $mrv-border_right;
  }
}
.mrv-border_style {
  &:before {
    content: $mrv-border_style;
  }
}
.mrv-border_top {
  &:before {
    content: $mrv-border_top;
  }
}
.mrv-border_vertical {
  &:before {
    content: $mrv-border_vertical;
  }
}
.mrv-boy {
  &:before {
    content: $mrv-boy;
  }
}
.mrv-branding_watermark {
  &:before {
    content: $mrv-branding_watermark;
  }
}
.mrv-breakfast_dining {
  &:before {
    content: $mrv-breakfast_dining;
  }
}
.mrv-breaking_news_alt_1 {
  &:before {
    content: $mrv-breaking_news_alt_1;
  }
}
.mrv-brightness_1 {
  &:before {
    content: $mrv-brightness_1;
  }
}
.mrv-brightness_2 {
  &:before {
    content: $mrv-brightness_2;
  }
}
.mrv-brightness_3 {
  &:before {
    content: $mrv-brightness_3;
  }
}
.mrv-brightness_4 {
  &:before {
    content: $mrv-brightness_4;
  }
}
.mrv-brightness_5 {
  &:before {
    content: $mrv-brightness_5;
  }
}
.mrv-brightness_6 {
  &:before {
    content: $mrv-brightness_6;
  }
}
.mrv-brightness_7 {
  &:before {
    content: $mrv-brightness_7;
  }
}
.mrv-brightness_auto {
  &:before {
    content: $mrv-brightness_auto;
  }
}
.mrv-brightness_high {
  &:before {
    content: $mrv-brightness_high;
  }
}
.mrv-brightness_low {
  &:before {
    content: $mrv-brightness_low;
  }
}
.mrv-brightness_medium {
  &:before {
    content: $mrv-brightness_medium;
  }
}
.mrv-bring_your_own_ip {
  &:before {
    content: $mrv-bring_your_own_ip;
  }
}
.mrv-broadcast_on_home {
  &:before {
    content: $mrv-broadcast_on_home;
  }
}
.mrv-broadcast_on_personal {
  &:before {
    content: $mrv-broadcast_on_personal;
  }
}
.mrv-broken_image {
  &:before {
    content: $mrv-broken_image;
  }
}
.mrv-browse_gallery {
  &:before {
    content: $mrv-browse_gallery;
  }
}
.mrv-browser_updated {
  &:before {
    content: $mrv-browser_updated;
  }
}
.mrv-brunch_dining {
  &:before {
    content: $mrv-brunch_dining;
  }
}
.mrv-brush {
  &:before {
    content: $mrv-brush;
  }
}
.mrv-bubble_chart {
  &:before {
    content: $mrv-bubble_chart;
  }
}
.mrv-bug_report {
  &:before {
    content: $mrv-bug_report;
  }
}
.mrv-build {
  &:before {
    content: $mrv-build;
  }
}
.mrv-build_circle {
  &:before {
    content: $mrv-build_circle;
  }
}
.mrv-bungalow {
  &:before {
    content: $mrv-bungalow;
  }
}
.mrv-burst_mode {
  &:before {
    content: $mrv-burst_mode;
  }
}
.mrv-bus_alert {
  &:before {
    content: $mrv-bus_alert;
  }
}
.mrv-business_center {
  &:before {
    content: $mrv-business_center;
  }
}
.mrv-cabin {
  &:before {
    content: $mrv-cabin;
  }
}
.mrv-cable {
  &:before {
    content: $mrv-cable;
  }
}
.mrv-cached {
  &:before {
    content: $mrv-cached;
  }
}
.mrv-cake {
  &:before {
    content: $mrv-cake;
  }
}
.mrv-calculate {
  &:before {
    content: $mrv-calculate;
  }
}
.mrv-calendar_add_on {
  &:before {
    content: $mrv-calendar_add_on;
  }
}
.mrv-calendar_apps_script {
  &:before {
    content: $mrv-calendar_apps_script;
  }
}
.mrv-calendar_month {
  &:before {
    content: $mrv-calendar_month;
  }
}
.mrv-calendar_today {
  &:before {
    content: $mrv-calendar_today;
  }
}
.mrv-calendar_view_day {
  &:before {
    content: $mrv-calendar_view_day;
  }
}
.mrv-calendar_view_month {
  &:before {
    content: $mrv-calendar_view_month;
  }
}
.mrv-calendar_view_week {
  &:before {
    content: $mrv-calendar_view_week;
  }
}
.mrv-call {
  &:before {
    content: $mrv-call;
  }
}
.mrv-call_end {
  &:before {
    content: $mrv-call_end;
  }
}
.mrv-call_made {
  &:before {
    content: $mrv-call_made;
  }
}
.mrv-call_merge {
  &:before {
    content: $mrv-call_merge;
  }
}
.mrv-call_missed {
  &:before {
    content: $mrv-call_missed;
  }
}
.mrv-call_missed_outgoing {
  &:before {
    content: $mrv-call_missed_outgoing;
  }
}
.mrv-call_received {
  &:before {
    content: $mrv-call_received;
  }
}
.mrv-call_split {
  &:before {
    content: $mrv-call_split;
  }
}
.mrv-call_to_action {
  &:before {
    content: $mrv-call_to_action;
  }
}
.mrv-camera {
  &:before {
    content: $mrv-camera;
  }
}
.mrv-camera_enhance {
  &:before {
    content: $mrv-camera_enhance;
  }
}
.mrv-camera_front {
  &:before {
    content: $mrv-camera_front;
  }
}
.mrv-camera_indoor {
  &:before {
    content: $mrv-camera_indoor;
  }
}
.mrv-camera_outdoor {
  &:before {
    content: $mrv-camera_outdoor;
  }
}
.mrv-camera_rear {
  &:before {
    content: $mrv-camera_rear;
  }
}
.mrv-camera_roll {
  &:before {
    content: $mrv-camera_roll;
  }
}
.mrv-cameraswitch {
  &:before {
    content: $mrv-cameraswitch;
  }
}
.mrv-campaign {
  &:before {
    content: $mrv-campaign;
  }
}
.mrv-cancel {
  &:before {
    content: $mrv-cancel; 
  }
}
.mrv-cancel_presentation {
  &:before {
    content: $mrv-cancel_presentation;
  }
}
.mrv-cancel_schedule_send {
  &:before {
    content: $mrv-cancel_schedule_send;
  }
}
.mrv-candlestick_chart {
  &:before {
    content: $mrv-candlestick_chart;
  }
}
.mrv-car_crash {
  &:before {
    content: $mrv-car_crash;
  }
}
.mrv-car_rental {
  &:before {
    content: $mrv-car_rental;
  }
}
.mrv-car_repair {
  &:before {
    content: $mrv-car_repair;
  }
}
.mrv-card_membership {
  &:before {
    content: $mrv-card_membership;
  }
}
.mrv-card_travel {
  &:before {
    content: $mrv-card_travel;
  }
}
.mrv-carpenter {
  &:before {
    content: $mrv-carpenter;
  }
}
.mrv-cases {
  &:before {
    content: $mrv-cases;
  }
}
.mrv-casino {
  &:before {
    content: $mrv-casino;
  }
}
.mrv-cast {
  &:before {
    content: $mrv-cast;
  }
}
.mrv-cast_connected {
  &:before {
    content: $mrv-cast_connected;
  }
}
.mrv-cast_for_education {
  &:before {
    content: $mrv-cast_for_education;
  }
}
.mrv-castle {
  &:before {
    content: $mrv-castle;
  }
}
.mrv-category {
  &:before {
    content: $mrv-category;
  }
}
.mrv-celebration {
  &:before {
    content: $mrv-celebration;
  }
}
.mrv-cell_tower {
  &:before {
    content: $mrv-cell_tower;
  }
}
.mrv-cell_wifi {
  &:before {
    content: $mrv-cell_wifi;
  }
}
.mrv-center_focus_strong {
  &:before {
    content: $mrv-center_focus_strong;
  }
}
.mrv-center_focus_weak {
  &:before {
    content: $mrv-center_focus_weak;
  }
}
.mrv-chair {
  &:before {
    content: $mrv-chair;
  }
}
.mrv-chair_alt {
  &:before {
    content: $mrv-chair_alt;
  }
}
.mrv-chalet {
  &:before {
    content: $mrv-chalet;
  }
}
.mrv-change_circle {
  &:before {
    content: $mrv-change_circle;
  }
}
.mrv-change_history {
  &:before {
    content: $mrv-change_history;
  }
}
.mrv-charger {
  &:before {
    content: $mrv-charger;
  }
}
.mrv-charging_station {
  &:before {
    content: $mrv-charging_station;
  }
}
.mrv-chat {
  &:before {
    content: $mrv-chat;
  }
}
.mrv-chat_add_on {
  &:before {
    content: $mrv-chat_add_on;
  }
}
.mrv-chat_apps_script {
  &:before {
    content: $mrv-chat_apps_script;
  }
}
.mrv-chat_bubble {
  &:before {
    content: $mrv-chat_bubble;
  }
}
.mrv-check {
  &:before {
    content: $mrv-check; 
  }
}
.mrv-check_box {
  &:before {
    content: $mrv-check_box;
  }
}
.mrv-check_box_outline_blank {
  &:before {
    content: $mrv-check_box_outline_blank;
  }
}
.mrv-check_circle {
  &:before {
    content: $mrv-check_circle;
  }
}
.mrv-checklist {
  &:before {
    content: $mrv-checklist;
  }
}
.mrv-checklist_rtl {
  &:before {
    content: $mrv-checklist_rtl;
  }
}
.mrv-checkroom {
  &:before {
    content: $mrv-checkroom;
  }
}
.mrv-chevron_left {
  &:before {
    content: $mrv-chevron_left;
  }
}
.mrv-chevron_right {
  &:before {
    content: $mrv-chevron_right;
  }
}
.mrv-child_care {
  &:before {
    content: $mrv-child_care;
  }
}
.mrv-child_friendly {
  &:before {
    content: $mrv-child_friendly;
  }
}
.mrv-chrome_reader_mode {
  &:before {
    content: $mrv-chrome_reader_mode;
  }
}
.mrv-chromecast_2 {
  &:before {
    content: $mrv-chromecast_2;
  }
}
.mrv-chromecast_device {
  &:before {
    content: $mrv-chromecast_device;
  }
}
.mrv-church {
  &:before {
    content: $mrv-church;
  }
}
.mrv-circle {
  &:before {
    content: $mrv-circle;
  }
}
.mrv-circle_notifications {
  &:before {
    content: $mrv-circle_notifications;
  }
}
.mrv-clarify {
  &:before {
    content: $mrv-clarify;
  }
}
.mrv-clean_hands {
  &:before {
    content: $mrv-clean_hands;
  }
}
.mrv-cleaning_services {
  &:before {
    content: $mrv-cleaning_services;
  }
}
.mrv-clear_all {
  &:before {
    content: $mrv-clear_all;
  }
}
.mrv-clear_day {
  &:before {
    content: $mrv-clear_day;
  }
}
.mrv-clear_night {
  &:before {
    content: $mrv-clear_night;
  }
}
.mrv-close {
  &:before {
    content: $mrv-close; 
  }
}
.mrv-close_fullscreen {
  &:before {
    content: $mrv-close_fullscreen;
  }
}
.mrv-closed_caption {
  &:before {
    content: $mrv-closed_caption;
  }
}
.mrv-closed_caption_disabled {
  &:before {
    content: $mrv-closed_caption_disabled;
  }
}
.mrv-cloud {
  &:before {
    content: $mrv-cloud;
  }
}
.mrv-cloud_circle {
  &:before {
    content: $mrv-cloud_circle;
  }
}
.mrv-cloud_domains {
  &:before {
    content: $mrv-cloud_domains;
  }
}
.mrv-cloud_done {
  &:before {
    content: $mrv-cloud_done;
  }
}
.mrv-cloud_download {
  &:before {
    content: $mrv-cloud_download;
  }
}
.mrv-cloud_off {
  &:before {
    content: $mrv-cloud_off;
  }
}
.mrv-cloud_queue {
  &:before {
    content: $mrv-cloud_queue;
  }
}
.mrv-cloud_sync {
  &:before {
    content: $mrv-cloud_sync;
  }
}
.mrv-cloud_upload {
  &:before {
    content: $mrv-cloud_upload;
  }
}
.mrv-cloudbuild {
  &:before {
    content: $mrv-cloudbuild;
  }
}
.mrv-cloudy {
  &:before {
    content: $mrv-cloudy;
  }
}
.mrv-cloudy_filled {
  &:before {
    content: $mrv-cloudy_filled;
  }
}
.mrv-cloudy_snowing {
  &:before {
    content: $mrv-cloudy_snowing;
  }
}
.mrv-co_present {
  &:before {
    content: $mrv-co_present;
  }
}
.mrv-co2 {
  &:before {
    content: $mrv-co2;
  }
}
.mrv-code {
  &:before {
    content: $mrv-code;
  }
}
.mrv-code_off {
  &:before {
    content: $mrv-code_off;
  }
}
.mrv-coffee {
  &:before {
    content: $mrv-coffee;
  }
}
.mrv-coffee_maker {
  &:before {
    content: $mrv-coffee_maker;
  }
}
.mrv-collections_bookmark {
  &:before {
    content: $mrv-collections_bookmark;
  }
}
.mrv-colorize {
  &:before {
    content: $mrv-colorize;
  }
}
.mrv-comment {
  &:before {
    content: $mrv-comment;
  }
}
.mrv-comment_bank {
  &:before {
    content: $mrv-comment_bank;
  }
}
.mrv-comments_disabled {
  &:before {
    content: $mrv-comments_disabled;
  }
}
.mrv-commit {
  &:before {
    content: $mrv-commit;
  }
}
.mrv-communication {
  &:before {
    content: $mrv-communication;
  }
}
.mrv-commute {
  &:before {
    content: $mrv-commute;
  }
}
.mrv-compare {
  &:before {
    content: $mrv-compare;
  }
}
.mrv-compare_arrows {
  &:before {
    content: $mrv-compare_arrows;
  }
}
.mrv-compass_calibration {
  &:before {
    content: $mrv-compass_calibration;
  }
}
.mrv-component_exchange {
  &:before {
    content: $mrv-component_exchange;
  }
}
.mrv-compost {
  &:before {
    content: $mrv-compost;
  }
}
.mrv-compress {
  &:before {
    content: $mrv-compress;
  }
}
.mrv-computer {
  &:before {
    content: $mrv-computer;
  }
}
.mrv-confirmation_number {
  &:before {
    content: $mrv-confirmation_number;
  }
}
.mrv-connect_without_contact {
  &:before {
    content: $mrv-connect_without_contact;
  }
}
.mrv-connected_tv {
  &:before {
    content: $mrv-connected_tv;
  }
}
.mrv-connecting_airports {
  &:before {
    content: $mrv-connecting_airports;
  }
}
.mrv-construction {
  &:before {
    content: $mrv-construction;
  }
}
.mrv-contact_mail {
  &:before {
    content: $mrv-contact_mail;
  }
}
.mrv-contact_page {
  &:before {
    content: $mrv-contact_page;
  }
}
.mrv-contact_phone {
  &:before {
    content: $mrv-contact_phone;
  }
}
.mrv-contact_support {
  &:before {
    content: $mrv-contact_support; 
  }
}
.mrv-contactless {
  &:before {
    content: $mrv-contactless;
  }
}
.mrv-contacts {
  &:before {
    content: $mrv-contacts;
  }
}
.mrv-content_copy {
  &:before {
    content: $mrv-content_copy;
  }
}
.mrv-content_cut {
  &:before {
    content: $mrv-content_cut;
  }
}
.mrv-content_paste {
  &:before {
    content: $mrv-content_paste;
  }
}
.mrv-content_paste_go {
  &:before {
    content: $mrv-content_paste_go;
  }
}
.mrv-content_paste_off {
  &:before {
    content: $mrv-content_paste_off;
  }
}
.mrv-content_paste_search {
  &:before {
    content: $mrv-content_paste_search;
  }
}
.mrv-contrast {
  &:before {
    content: $mrv-contrast;
  }
}
.mrv-control_camera {
  &:before {
    content: $mrv-control_camera;
  }
}
.mrv-control_point_duplicate {
  &:before {
    content: $mrv-control_point_duplicate;
  }
}
.mrv-controller_gen {
  &:before {
    content: $mrv-controller_gen;
  }
}
.mrv-conversion_path {
  &:before {
    content: $mrv-conversion_path;
  }
}
.mrv-cookie {
  &:before {
    content: $mrv-cookie;
  }
}
.mrv-cooking {
  &:before {
    content: $mrv-cooking;
  }
}
.mrv-copy_all {
  &:before {
    content: $mrv-copy_all;
  }
}
.mrv-copyright {
  &:before {
    content: $mrv-copyright;
  }
}
.mrv-coronavirus {
  &:before {
    content: $mrv-coronavirus;
  }
}
.mrv-corporate_fare {
  &:before {
    content: $mrv-corporate_fare;
  }
}
.mrv-cottage {
  &:before {
    content: $mrv-cottage;
  }
}
.mrv-countertops {
  &:before {
    content: $mrv-countertops;
  }
}
.mrv-create_new_folder {
  &:before {
    content: $mrv-create_new_folder;
  }
}
.mrv-credit_card {
  &:before {
    content: $mrv-credit_card;
  }
}
.mrv-credit_card_off {
  &:before {
    content: $mrv-credit_card_off;
  }
}
.mrv-credit_score {
  &:before {
    content: $mrv-credit_score;
  }
}
.mrv-crib {
  &:before {
    content: $mrv-crib;
  }
}
.mrv-crisis_alert {
  &:before {
    content: $mrv-crisis_alert;
  }
}
.mrv-crop {
  &:before {
    content: $mrv-crop;
  }
}
.mrv-crop_3_2 {
  &:before {
    content: $mrv-crop_3_2;
  }
}
.mrv-crop_5_4 {
  &:before {
    content: $mrv-crop_5_4;
  }
}
.mrv-crop_7_5 {
  &:before {
    content: $mrv-crop_7_5;
  }
}
.mrv-crop_16_9 {
  &:before {
    content: $mrv-crop_16_9;
  }
}
.mrv-crop_free {
  &:before {
    content: $mrv-crop_free;
  }
}
.mrv-crop_landscape {
  &:before {
    content: $mrv-crop_landscape;
  }
}
.mrv-crop_portrait {
  &:before {
    content: $mrv-crop_portrait;
  }
}
.mrv-crop_rotate {
  &:before {
    content: $mrv-crop_rotate;
  }
}
.mrv-crop_square {
  &:before {
    content: $mrv-crop_square;
  }
}
.mrv-cruelty_free {
  &:before {
    content: $mrv-cruelty_free;
  }
}
.mrv-css {
  &:before {
    content: $mrv-css;
  }
}
.mrv-currency_bitcoin {
  &:before {
    content: $mrv-currency_bitcoin;
  }
}
.mrv-currency_exchange {
  &:before {
    content: $mrv-currency_exchange;
  }
}
.mrv-currency_franc {
  &:before {
    content: $mrv-currency_franc;
  }
}
.mrv-currency_lira {
  &:before {
    content: $mrv-currency_lira;
  }
}
.mrv-currency_pound {
  &:before {
    content: $mrv-currency_pound;
  }
}
.mrv-currency_ruble {
  &:before {
    content: $mrv-currency_ruble;
  }
}
.mrv-currency_rupee {
  &:before {
    content: $mrv-currency_rupee;
  }
}
.mrv-currency_yen {
  &:before {
    content: $mrv-currency_yen;
  }
}
.mrv-currency_yuan {
  &:before {
    content: $mrv-currency_yuan;
  }
}
.mrv-curtains {
  &:before {
    content: $mrv-curtains;
  }
}
.mrv-curtains_closed {
  &:before {
    content: $mrv-curtains_closed;
  }
}
.mrv-cut {
  &:before {
    content: $mrv-cut;
  }
}
.mrv-cyclone {
  &:before {
    content: $mrv-cyclone;
  }
}
.mrv-dangerous {
  &:before {
    content: $mrv-dangerous;
  }
}
.mrv-dark_mode {
  &:before {
    content: $mrv-dark_mode;
  }
}
.mrv-dashboard {
  &:before {
    content: $mrv-dashboard;
  }
}
.mrv-dashboard_customize {
  &:before {
    content: $mrv-dashboard_customize;
  }
}
.mrv-data_array {
  &:before {
    content: $mrv-data_array;
  }
}
.mrv-data_exploration {
  &:before {
    content: $mrv-data_exploration;
  }
}
.mrv-data_object {
  &:before {
    content: $mrv-data_object;
  }
}
.mrv-data_saver_on {
  &:before {
    content: $mrv-data_saver_on;
  }
}
.mrv-data_thresholding {
  &:before {
    content: $mrv-data_thresholding;
  }
}
.mrv-data_usage {
  &:before {
    content: $mrv-data_usage;
  }
}
.mrv-database {
  &:before {
    content: $mrv-database;
  }
}
.mrv-dataset {
  &:before {
    content: $mrv-dataset;
  }
}
.mrv-dataset_linked {
  &:before {
    content: $mrv-dataset_linked;
  }
}
.mrv-date_range {
  &:before {
    content: $mrv-date_range;
  }
}
.mrv-deblur {
  &:before {
    content: $mrv-deblur;
  }
}
.mrv-deck {
  &:before {
    content: $mrv-deck;
  }
}
.mrv-dehaze {
  &:before {
    content: $mrv-dehaze;
  }
}
.mrv-delete {
  &:before {
    content: $mrv-delete;
  }
}
.mrv-delete_forever {
  &:before {
    content: $mrv-delete_forever;
  }
}
.mrv-delete_sweep {
  &:before {
    content: $mrv-delete_sweep;
  }
}
.mrv-density_large {
  &:before {
    content: $mrv-density_large;
  }
}
.mrv-density_medium {
  &:before {
    content: $mrv-density_medium;
  }
}
.mrv-density_small {
  &:before {
    content: $mrv-density_small;
  }
}
.mrv-departure_board {
  &:before {
    content: $mrv-departure_board;
  }
}
.mrv-description {
  &:before {
    content: $mrv-description;
  }
}
.mrv-deselect {
  &:before {
    content: $mrv-deselect;
  }
}
.mrv-design_services {
  &:before {
    content: $mrv-design_services;
  }
}
.mrv-desk {
  &:before {
    content: $mrv-desk;
  }
}
.mrv-desktop_access_disabled {
  &:before {
    content: $mrv-desktop_access_disabled;
  }
}
.mrv-desktop_mac {
  &:before {
    content: $mrv-desktop_mac;
  }
}
.mrv-desktop_windows {
  &:before {
    content: $mrv-desktop_windows;
  }
}
.mrv-details {
  &:before {
    content: $mrv-details;
  }
}
.mrv-detector_smoke {
  &:before {
    content: $mrv-detector_smoke;
  }
}
.mrv-developer_board {
  &:before {
    content: $mrv-developer_board;
  }
}
.mrv-developer_board_off {
  &:before {
    content: $mrv-developer_board_off;
  }
}
.mrv-developer_mode {
  &:before {
    content: $mrv-developer_mode;
  }
}
.mrv-device_hub {
  &:before {
    content: $mrv-device_hub;
  }
}
.mrv-device_thermostat {
  &:before {
    content: $mrv-device_thermostat;
  }
}
.mrv-device_unknown {
  &:before {
    content: $mrv-device_unknown;
  }
}
.mrv-devices {
  &:before {
    content: $mrv-devices;
  }
}
.mrv-devices_fold {
  &:before {
    content: $mrv-devices_fold;
  }
}
.mrv-devices_other {
  &:before {
    content: $mrv-devices_other;
  }
}
.mrv-dialer_sip {
  &:before {
    content: $mrv-dialer_sip;
  }
}
.mrv-dialpad {
  &:before {
    content: $mrv-dialpad;
  }
}
.mrv-diamond {
  &:before {
    content: $mrv-diamond;
  }
}
.mrv-difference {
  &:before {
    content: $mrv-difference;
  }
}
.mrv-digital_out_of_home {
  &:before {
    content: $mrv-digital_out_of_home;
  }
}
.mrv-dining {
  &:before {
    content: $mrv-dining;
  }
}
.mrv-dinner_dining {
  &:before {
    content: $mrv-dinner_dining;
  }
}
.mrv-directions {
  &:before {
    content: $mrv-directions;
  }
}
.mrv-directions_bike {
  &:before {
    content: $mrv-directions_bike;
  }
}
.mrv-directions_boat {
  &:before {
    content: $mrv-directions_boat;
  }
}
.mrv-directions_bus {
  &:before {
    content: $mrv-directions_bus;
  }
}
.mrv-directions_car {
  &:before {
    content: $mrv-directions_car;
  }
}
.mrv-directions_off {
  &:before {
    content: $mrv-directions_off;
  }
}
.mrv-directions_railway {
  &:before {
    content: $mrv-directions_railway;
  }
}
.mrv-directions_run {
  &:before {
    content: $mrv-directions_run;
  }
}
.mrv-directions_subway {
  &:before {
    content: $mrv-directions_subway;
  }
}
.mrv-directions_walk {
  &:before {
    content: $mrv-directions_walk;
  }
}
.mrv-dirty_lens {
  &:before {
    content: $mrv-dirty_lens;
  }
}
.mrv-disabled_by_default {
  &:before {
    content: $mrv-disabled_by_default;
  }
}
.mrv-disabled_visible {
  &:before {
    content: $mrv-disabled_visible;
  }
}
.mrv-disc_full {
  &:before {
    content: $mrv-disc_full;
  }
}
.mrv-discover_tune {
  &:before {
    content: $mrv-discover_tune;
  }
}
.mrv-dishwasher_gen {
  &:before {
    content: $mrv-dishwasher_gen;
  }
}
.mrv-display_settings {
  &:before {
    content: $mrv-display_settings;
  }
}
.mrv-dns {
  &:before {
    content: $mrv-dns;
  }
}
.mrv-do_not_disturb_off {
  &:before {
    content: $mrv-do_not_disturb_off;
  }
}
.mrv-do_not_disturb_on {
  &:before {
    content: $mrv-do_not_disturb_on;
  }
}
.mrv-do_not_disturb_on_total_silence {
  &:before {
    content: $mrv-do_not_disturb_on_total_silence;
  }
}
.mrv-do_not_step {
  &:before {
    content: $mrv-do_not_step;
  }
}
.mrv-do_not_touch {
  &:before {
    content: $mrv-do_not_touch;
  }
}
.mrv-dock {
  &:before {
    content: $mrv-dock;
  }
}
.mrv-docs {
  &:before {
    content: $mrv-docs;
  }
}
.mrv-docs_add_on {
  &:before {
    content: $mrv-docs_add_on;
  }
}
.mrv-docs_apps_script {
  &:before {
    content: $mrv-docs_apps_script;
  }
}
.mrv-document_scanner {
  &:before {
    content: $mrv-document_scanner;
  }
}
.mrv-domain {
  &:before {
    content: $mrv-domain;
  }
}
.mrv-domain_add {
  &:before {
    content: $mrv-domain_add;
  }
}
.mrv-domain_disabled {
  &:before {
    content: $mrv-domain_disabled;
  }
}
.mrv-domain_verification {
  &:before {
    content: $mrv-domain_verification;
  }
}
.mrv-done {
  &:before {
    content: $mrv-done;
  }
}
.mrv-done_all {
  &:before {
    content: $mrv-done_all;
  }
}
.mrv-done_outline {
  &:before {
    content: $mrv-done_outline;
  }
}
.mrv-donut_large {
  &:before {
    content: $mrv-donut_large;
  }
}
.mrv-donut_small {
  &:before {
    content: $mrv-donut_small;
  }
}
.mrv-door_back {
  &:before {
    content: $mrv-door_back;
  }
}
.mrv-door_front {
  &:before {
    content: $mrv-door_front;
  }
}
.mrv-door_sliding {
  &:before {
    content: $mrv-door_sliding;
  }
}
.mrv-doorbell {
  &:before {
    content: $mrv-doorbell;
  }
}
.mrv-doorbell_3p {
  &:before {
    content: $mrv-doorbell_3p;
  }
}
.mrv-double_arrow {
  &:before {
    content: $mrv-double_arrow;
  }
}
.mrv-downhill_skiing {
  &:before {
    content: $mrv-downhill_skiing;
  }
}
.mrv-download {
  &:before {
    content: $mrv-download;
  }
}
.mrv-download_done {
  &:before {
    content: $mrv-download_done;
  }
}
.mrv-download_for_offline {
  &:before {
    content: $mrv-download_for_offline;
  }
}
.mrv-downloading {
  &:before {
    content: $mrv-downloading;
  }
}
.mrv-draft {
  &:before {
    content: $mrv-draft;
  }
}
.mrv-drafts {
  &:before {
    content: $mrv-drafts;
  }
}
.mrv-drag_handle {
  &:before {
    content: $mrv-drag_handle;
  }
}
.mrv-drag_indicator {
  &:before {
    content: $mrv-drag_indicator;
  }
}
.mrv-draw {
  &:before {
    content: $mrv-draw;
  }
}
.mrv-dresser {
  &:before {
    content: $mrv-dresser;
  }
}
.mrv-drive_file_move {
  &:before {
    content: $mrv-drive_file_move;
  }
}
.mrv-drive_file_move_outline {
  &:before {
    content: $mrv-drive_file_move_outline;
  }
}
.mrv-drive_file_move_rtl {
  &:before {
    content: $mrv-drive_file_move_rtl;
  }
}
.mrv-drive_file_rename_outline {
  &:before {
    content: $mrv-drive_file_rename_outline;
  }
}
.mrv-drive_folder_upload {
  &:before {
    content: $mrv-drive_folder_upload;
  }
}
.mrv-drive_shortcut {
  &:before {
    content: $mrv-drive_shortcut;
  }
}
.mrv-dry {
  &:before {
    content: $mrv-dry;
  }
}
.mrv-dry_cleaning {
  &:before {
    content: $mrv-dry_cleaning;
  }
}
.mrv-duo {
  &:before {
    content: $mrv-duo;
  }
}
.mrv-dvr {
  &:before {
    content: $mrv-dvr;
  }
}
.mrv-dynamic_feed {
  &:before {
    content: $mrv-dynamic_feed;
  }
}
.mrv-dynamic_form {
  &:before {
    content: $mrv-dynamic_form;
  }
}
.mrv-e_mobiledata {
  &:before {
    content: $mrv-e_mobiledata;
  }
}
.mrv-e911_avatar {
  &:before {
    content: $mrv-e911_avatar;
  }
}
.mrv-e911_emergency {
  &:before {
    content: $mrv-e911_emergency;
  }
}
.mrv-earbuds {
  &:before {
    content: $mrv-earbuds;
  }
}
.mrv-earbuds_battery {
  &:before {
    content: $mrv-earbuds_battery;
  }
}
.mrv-east {
  &:before {
    content: $mrv-east;
  }
}
.mrv-eco {
  &:before {
    content: $mrv-eco;
  }
}
.mrv-edgesensor_high {
  &:before {
    content: $mrv-edgesensor_high;
  }
}
.mrv-edgesensor_low {
  &:before {
    content: $mrv-edgesensor_low;
  }
}
.mrv-edit {
  &:before {
    content: $mrv-edit; 
  }
}
.mrv-edit_attributes {
  &:before {
    content: $mrv-edit_attributes;
  }
}
.mrv-edit_calendar {
  &:before {
    content: $mrv-edit_calendar;
  }
}
.mrv-edit_location {
  &:before {
    content: $mrv-edit_location;
  }
}
.mrv-edit_location_alt {
  &:before {
    content: $mrv-edit_location_alt;
  }
}
.mrv-edit_note {
  &:before {
    content: $mrv-edit_note;
  }
}
.mrv-edit_notifications {
  &:before {
    content: $mrv-edit_notifications;
  }
}
.mrv-edit_off {
  &:before {
    content: $mrv-edit_off;
  }
}
.mrv-edit_road {
  &:before {
    content: $mrv-edit_road;
  }
}
.mrv-egg {
  &:before {
    content: $mrv-egg;
  }
}
.mrv-egg_alt {
  &:before {
    content: $mrv-egg_alt;
  }
}
.mrv-eject {
  &:before {
    content: $mrv-eject;
  }
}
.mrv-elderly {
  &:before {
    content: $mrv-elderly;
  }
}
.mrv-elderly_woman {
  &:before {
    content: $mrv-elderly_woman;
  }
}
.mrv-electric_bike {
  &:before {
    content: $mrv-electric_bike;
  }
}
.mrv-electric_bolt {
  &:before {
    content: $mrv-electric_bolt;
  }
}
.mrv-electric_car {
  &:before {
    content: $mrv-electric_car;
  }
}
.mrv-electric_meter {
  &:before {
    content: $mrv-electric_meter;
  }
}
.mrv-electric_moped {
  &:before {
    content: $mrv-electric_moped;
  }
}
.mrv-electric_rickshaw {
  &:before {
    content: $mrv-electric_rickshaw;
  }
}
.mrv-electric_scooter {
  &:before {
    content: $mrv-electric_scooter;
  }
}
.mrv-electrical_services {
  &:before {
    content: $mrv-electrical_services;
  }
}
.mrv-elevator {
  &:before {
    content: $mrv-elevator;
  }
}
.mrv-emergency {
  &:before {
    content: $mrv-emergency;
  }
}
.mrv-emergency_heat {
  &:before {
    content: $mrv-emergency_heat;
  }
}
.mrv-emergency_home {
  &:before {
    content: $mrv-emergency_home;
  }
}
.mrv-emergency_recording {
  &:before {
    content: $mrv-emergency_recording;
  }
}
.mrv-emergency_share {
  &:before {
    content: $mrv-emergency_share;
  }
}
.mrv-emoji_flags {
  &:before {
    content: $mrv-emoji_flags;
  }
}
.mrv-emoji_food_beverage {
  &:before {
    content: $mrv-emoji_food_beverage;
  }
}
.mrv-emoji_nature {
  &:before {
    content: $mrv-emoji_nature;
  }
}
.mrv-emoji_objects {
  &:before {
    content: $mrv-emoji_objects;
  }
}
.mrv-emoji_people {
  &:before {
    content: $mrv-emoji_people;
  }
}
.mrv-emoji_symbols {
  &:before {
    content: $mrv-emoji_symbols;
  }
}
.mrv-emoji_transportation {
  &:before {
    content: $mrv-emoji_transportation;
  }
}
.mrv-enable {
  &:before {
    content: $mrv-enable;
  }
}
.mrv-energy_program_saving {
  &:before {
    content: $mrv-energy_program_saving;
  }
}
.mrv-energy_program_time_used {
  &:before {
    content: $mrv-energy_program_time_used;
  }
}
.mrv-energy_savings_leaf {
  &:before {
    content: $mrv-energy_savings_leaf;
  }
}
.mrv-engineering {
  &:before {
    content: $mrv-engineering;
  }
}
.mrv-enhanced_encryption {
  &:before {
    content: $mrv-enhanced_encryption;
  }
}
.mrv-equalizer {
  &:before {
    content: $mrv-equalizer;
  }
}
.mrv-error {
  &:before {
    content: $mrv-error;
  }
}
.mrv-escalator {
  &:before {
    content: $mrv-escalator;
  }
}
.mrv-escalator_warning {
  &:before {
    content: $mrv-escalator_warning;
  }
}
.mrv-euro {
  &:before {
    content: $mrv-euro;
  }
}
.mrv-euro_symbol {
  &:before {
    content: $mrv-euro_symbol;
  }
}
.mrv-ev_shadow {
  &:before {
    content: $mrv-ev_shadow;
  }
}
.mrv-ev_station {
  &:before {
    content: $mrv-ev_station;
  }
}
.mrv-event {
  &:before {
    content: $mrv-event;
  }
}
.mrv-event_available {
  &:before {
    content: $mrv-event_available;
  }
}
.mrv-event_busy {
  &:before {
    content: $mrv-event_busy;
  }
}
.mrv-event_note {
  &:before {
    content: $mrv-event_note;
  }
}
.mrv-event_repeat {
  &:before {
    content: $mrv-event_repeat;
  }
}
.mrv-event_seat {
  &:before {
    content: $mrv-event_seat;
  }
}
.mrv-event_upcoming {
  &:before {
    content: $mrv-event_upcoming;
  }
}
.mrv-exclamation {
  &:before {
    content: $mrv-exclamation;
  }
}
.mrv-exit_to_app {
  &:before {
    content: $mrv-exit_to_app;
  }
}
.mrv-expand {
  &:before {
    content: $mrv-expand;
  }
}
.mrv-expand_circle_down {
  &:before {
    content: $mrv-expand_circle_down;
  }
}
.mrv-expand_less {
  &:before {
    content: $mrv-expand_less;
  }
}
.mrv-expand_more {
  &:before {
    content: $mrv-expand_more;
  }
}
.mrv-explicit {
  &:before {
    content: $mrv-explicit;
  }
}
.mrv-explore {
  &:before {
    content: $mrv-explore;
  }
}
.mrv-explore_off {
  &:before {
    content: $mrv-explore_off;
  }
}
.mrv-exposure {
  &:before {
    content: $mrv-exposure;
  }
}
.mrv-exposure_neg_1 {
  &:before {
    content: $mrv-exposure_neg_1;
  }
}
.mrv-exposure_neg_2 {
  &:before {
    content: $mrv-exposure_neg_2;
  }
}
.mrv-exposure_plus_1 {
  &:before {
    content: $mrv-exposure_plus_1;
  }
}
.mrv-exposure_plus_2 {
  &:before {
    content: $mrv-exposure_plus_2;
  }
}
.mrv-exposure_zero {
  &:before {
    content: $mrv-exposure_zero;
  }
}
.mrv-extension {
  &:before {
    content: $mrv-extension;
  }
}
.mrv-extension_off {
  &:before {
    content: $mrv-extension_off;
  }
}
.mrv-face {
  &:before {
    content: $mrv-face;
  }
}
.mrv-face_retouching_natural {
  &:before {
    content: $mrv-face_retouching_natural;
  }
}
.mrv-face_retouching_off {
  &:before {
    content: $mrv-face_retouching_off;
  }
}
.mrv-fact_check {
  &:before {
    content: $mrv-fact_check;
  }
}
.mrv-factory {
  &:before {
    content: $mrv-factory;
  }
}
.mrv-family_restroom {
  &:before {
    content: $mrv-family_restroom;
  }
}
.mrv-fast_forward {
  &:before {
    content: $mrv-fast_forward;
  }
}
.mrv-fast_rewind {
  &:before {
    content: $mrv-fast_rewind;
  }
}
.mrv-fastfood {
  &:before {
    content: $mrv-fastfood;
  }
}
.mrv-faucet {
  &:before {
    content: $mrv-faucet;
  }
}
.mrv-favorite {
  &:before {
    content: $mrv-favorite;
  }
}
.mrv-favorite_outline {
  &:before {
    content: $mrv-favorite_outline;
  }
}
.mrv-fax {
  &:before {
    content: $mrv-fax;
  }
}
.mrv-featured_play_list {
  &:before {
    content: $mrv-featured_play_list;
  }
}
.mrv-featured_video {
  &:before {
    content: $mrv-featured_video;
  }
}
.mrv-feed {
  &:before {
    content: $mrv-feed;
  }
}
.mrv-female {
  &:before {
    content: $mrv-female;
  }
}
.mrv-fence {
  &:before {
    content: $mrv-fence;
  }
}
.mrv-festival {
  &:before {
    content: $mrv-festival;
  }
}
.mrv-fiber_dvr {
  &:before {
    content: $mrv-fiber_dvr;
  }
}
.mrv-fiber_manual_record {
  &:before {
    content: $mrv-fiber_manual_record;
  }
}
.mrv-fiber_new {
  &:before {
    content: $mrv-fiber_new;
  }
}
.mrv-fiber_pin {
  &:before {
    content: $mrv-fiber_pin;
  }
}
.mrv-fiber_smart_record {
  &:before {
    content: $mrv-fiber_smart_record;
  }
}
.mrv-file_copy {
  &:before {
    content: $mrv-file_copy;
  }
}
.mrv-file_download {
  &:before {
    content: $mrv-file_download;
  }
}
.mrv-file_download_done {
  &:before {
    content: $mrv-file_download_done;
  }
}
.mrv-file_download_off {
  &:before {
    content: $mrv-file_download_off;
  }
}
.mrv-file_open {
  &:before {
    content: $mrv-file_open;
  }
}
.mrv-file_present {
  &:before {
    content: $mrv-file_present;
  }
}
.mrv-file_upload {
  &:before {
    content: $mrv-file_upload;
  }
}
.mrv-files {
  &:before {
    content: $mrv-files;
  }
}
.mrv-filter {
  &:before {
    content: $mrv-filter;
  }
}
.mrv-filter_1 {
  &:before {
    content: $mrv-filter_1;
  }
}
.mrv-filter_2 {
  &:before {
    content: $mrv-filter_2;
  }
}
.mrv-filter_3 {
  &:before {
    content: $mrv-filter_3;
  }
}
.mrv-filter_4 {
  &:before {
    content: $mrv-filter_4;
  }
}
.mrv-filter_5 {
  &:before {
    content: $mrv-filter_5;
  }
}
.mrv-filter_6 {
  &:before {
    content: $mrv-filter_6;
  }
}
.mrv-filter_7 {
  &:before {
    content: $mrv-filter_7;
  }
}
.mrv-filter_8 {
  &:before {
    content: $mrv-filter_8;
  }
}
.mrv-filter_9 {
  &:before {
    content: $mrv-filter_9;
  }
}
.mrv-filter_9_plus {
  &:before {
    content: $mrv-filter_9_plus;
  }
}
.mrv-filter_alt {
  &:before {
    content: $mrv-filter_alt;
  }
}
.mrv-filter_alt_off {
  &:before {
    content: $mrv-filter_alt_off;
  }
}
.mrv-filter_b_and_w {
  &:before {
    content: $mrv-filter_b_and_w;
  }
}
.mrv-filter_center_focus {
  &:before {
    content: $mrv-filter_center_focus;
  }
}
.mrv-filter_drama {
  &:before {
    content: $mrv-filter_drama;
  }
}
.mrv-filter_frames {
  &:before {
    content: $mrv-filter_frames;
  }
}
.mrv-filter_hdr {
  &:before {
    content: $mrv-filter_hdr;
  }
}
.mrv-filter_list {
  &:before {
    content: $mrv-filter_list;
  }
}
.mrv-filter_list_off {
  &:before {
    content: $mrv-filter_list_off;
  }
}
.mrv-filter_none {
  &:before {
    content: $mrv-filter_none;
  }
}
.mrv-filter_tilt_shift {
  &:before {
    content: $mrv-filter_tilt_shift;
  }
}
.mrv-filter_vintage {
  &:before {
    content: $mrv-filter_vintage;
  }
}
.mrv-find_in_page {
  &:before {
    content: $mrv-find_in_page;
  }
}
.mrv-find_replace {
  &:before {
    content: $mrv-find_replace;
  }
}
.mrv-fingerprint {
  &:before {
    content: $mrv-fingerprint;
  }
}
.mrv-fire_extinguisher {
  &:before {
    content: $mrv-fire_extinguisher;
  }
}
.mrv-fire_hydrant {
  &:before {
    content: $mrv-fire_hydrant;
  }
}
.mrv-fire_truck {
  &:before {
    content: $mrv-fire_truck;
  }
}
.mrv-fireplace {
  &:before {
    content: $mrv-fireplace;
  }
}
.mrv-first_page {
  &:before {
    content: $mrv-first_page;
  }
}
.mrv-fishfood {
  &:before {
    content: $mrv-fishfood;
  }
}
.mrv-fit_screen {
  &:before {
    content: $mrv-fit_screen;
  }
}
.mrv-fitness_center {
  &:before {
    content: $mrv-fitness_center;
  }
}
.mrv-flag {
  &:before {
    content: $mrv-flag;
  }
}
.mrv-flag_circle {
  &:before {
    content: $mrv-flag_circle;
  }
}
.mrv-flaky {
  &:before {
    content: $mrv-flaky;
  }
}
.mrv-flare {
  &:before {
    content: $mrv-flare;
  }
}
.mrv-flash_auto {
  &:before {
    content: $mrv-flash_auto;
  }
}
.mrv-flash_off {
  &:before {
    content: $mrv-flash_off;
  }
}
.mrv-flash_on {
  &:before {
    content: $mrv-flash_on;
  }
}
.mrv-flashlight_off {
  &:before {
    content: $mrv-flashlight_off;
  }
}
.mrv-flashlight_on {
  &:before {
    content: $mrv-flashlight_on;
  }
}
.mrv-flatware {
  &:before {
    content: $mrv-flatware;
  }
}
.mrv-flight {
  &:before {
    content: $mrv-flight;
  }
}
.mrv-flight_class {
  &:before {
    content: $mrv-flight_class;
  }
}
.mrv-flight_land {
  &:before {
    content: $mrv-flight_land;
  }
}
.mrv-flight_takeoff {
  &:before {
    content: $mrv-flight_takeoff;
  }
}
.mrv-flip {
  &:before {
    content: $mrv-flip;
  }
}
.mrv-flip_camera_android {
  &:before {
    content: $mrv-flip_camera_android;
  }
}
.mrv-flip_camera_ios {
  &:before {
    content: $mrv-flip_camera_ios;
  }
}
.mrv-flip_to_back {
  &:before {
    content: $mrv-flip_to_back;
  }
}
.mrv-flip_to_front {
  &:before {
    content: $mrv-flip_to_front;
  }
}
.mrv-flood {
  &:before {
    content: $mrv-flood;
  }
}
.mrv-flutter {
  &:before {
    content: $mrv-flutter;
  }
}
.mrv-flutter_dash {
  &:before {
    content: $mrv-flutter_dash;
  }
}
.mrv-fmd_bad {
  &:before {
    content: $mrv-fmd_bad;
  }
}
.mrv-foggy {
  &:before {
    content: $mrv-foggy;
  }
}
.mrv-folder {
  &:before {
    content: $mrv-folder;
  }
}
.mrv-folder_copy {
  &:before {
    content: $mrv-folder_copy;
  }
}
.mrv-folder_delete {
  &:before {
    content: $mrv-folder_delete;
  }
}
.mrv-folder_off {
  &:before {
    content: $mrv-folder_off;
  }
}
.mrv-folder_open {
  &:before {
    content: $mrv-folder_open;
  }
}
.mrv-folder_shared {
  &:before {
    content: $mrv-folder_shared;
  }
}
.mrv-folder_special {
  &:before {
    content: $mrv-folder_special;
  }
}
.mrv-folder_zip {
  &:before {
    content: $mrv-folder_zip;
  }
}
.mrv-follow_the_signs {
  &:before {
    content: $mrv-follow_the_signs;
  }
}
.mrv-font_download {
  &:before {
    content: $mrv-font_download;
  }
}
.mrv-font_download_off {
  &:before {
    content: $mrv-font_download_off;
  }
}
.mrv-food_bank {
  &:before {
    content: $mrv-food_bank;
  }
}
.mrv-forest {
  &:before {
    content: $mrv-forest;
  }
}
.mrv-fork_left {
  &:before {
    content: $mrv-fork_left;
  }
}
.mrv-fork_right {
  &:before {
    content: $mrv-fork_right;
  }
}
.mrv-format_align_center {
  &:before {
    content: $mrv-format_align_center;
  }
}
.mrv-format_align_justify {
  &:before {
    content: $mrv-format_align_justify;
  }
}
.mrv-format_align_left {
  &:before {
    content: $mrv-format_align_left;
  }
}
.mrv-format_align_right {
  &:before {
    content: $mrv-format_align_right;
  }
}
.mrv-format_bold {
  &:before {
    content: $mrv-format_bold;
  }
}
.mrv-format_clear {
  &:before {
    content: $mrv-format_clear;
  }
}
.mrv-format_color_fill {
  &:before {
    content: $mrv-format_color_fill;
  }
}
.mrv-format_color_reset {
  &:before {
    content: $mrv-format_color_reset;
  }
}
.mrv-format_color_text {
  &:before {
    content: $mrv-format_color_text;
  }
}
.mrv-format_indent_decrease {
  &:before {
    content: $mrv-format_indent_decrease;
  }
}
.mrv-format_indent_increase {
  &:before {
    content: $mrv-format_indent_increase;
  }
}
.mrv-format_italic {
  &:before {
    content: $mrv-format_italic;
  }
}
.mrv-format_line_spacing {
  &:before {
    content: $mrv-format_line_spacing;
  }
}
.mrv-format_list_bulleted {
  &:before {
    content: $mrv-format_list_bulleted;
  }
}
.mrv-format_list_numbered {
  &:before {
    content: $mrv-format_list_numbered;
  }
}
.mrv-format_list_numbered_rtl {
  &:before {
    content: $mrv-format_list_numbered_rtl;
  }
}
.mrv-format_overline {
  &:before {
    content: $mrv-format_overline;
  }
}
.mrv-format_paint {
  &:before {
    content: $mrv-format_paint;
  }
}
.mrv-format_quote {
  &:before {
    content: $mrv-format_quote;
  }
}
.mrv-format_shapes {
  &:before {
    content: $mrv-format_shapes;
  }
}
.mrv-format_size {
  &:before {
    content: $mrv-format_size;
  }
}
.mrv-format_strikethrough {
  &:before {
    content: $mrv-format_strikethrough;
  }
}
.mrv-format_textdirection_l_to_r {
  &:before {
    content: $mrv-format_textdirection_l_to_r;
  }
}
.mrv-format_textdirection_r_to_l {
  &:before {
    content: $mrv-format_textdirection_r_to_l;
  }
}
.mrv-format_underlined {
  &:before {
    content: $mrv-format_underlined;
  }
}
.mrv-forms_add_on {
  &:before {
    content: $mrv-forms_add_on;
  }
}
.mrv-forms_apps_script {
  &:before {
    content: $mrv-forms_apps_script;
  }
}
.mrv-fort {
  &:before {
    content: $mrv-fort;
  }
}
.mrv-forum {
  &:before {
    content: $mrv-forum;
  }
}
.mrv-forward {
  &:before {
    content: $mrv-forward;
  }
}
.mrv-forward_5 {
  &:before {
    content: $mrv-forward_5;
  }
}
.mrv-forward_10 {
  &:before {
    content: $mrv-forward_10;
  }
}
.mrv-forward_30 {
  &:before {
    content: $mrv-forward_30;
  }
}
.mrv-forward_to_inbox {
  &:before {
    content: $mrv-forward_to_inbox;
  }
}
.mrv-foundation {
  &:before {
    content: $mrv-foundation;
  }
}
.mrv-free_cancellation {
  &:before {
    content: $mrv-free_cancellation;
  }
}
.mrv-front_hand {
  &:before {
    content: $mrv-front_hand;
  }
}
.mrv-full_stacked_bar_chart {
  &:before {
    content: $mrv-full_stacked_bar_chart;
  }
}
.mrv-fullscreen {
  &:before {
    content: $mrv-fullscreen;
  }
}
.mrv-fullscreen_exit {
  &:before {
    content: $mrv-fullscreen_exit;
  }
}
.mrv-functions {
  &:before {
    content: $mrv-functions;
  }
}
.mrv-g_mobiledata2 {
  &:before {
    content: $mrv-g_mobiledata2;
  }
}
.mrv-g_translate {
  &:before {
    content: $mrv-g_translate;
  }
}
.mrv-garage {
  &:before {
    content: $mrv-garage;
  }
}
.mrv-garage_home {
  &:before {
    content: $mrv-garage_home;
  }
}
.mrv-gas_meter {
  &:before {
    content: $mrv-gas_meter;
  }
}
.mrv-gate {
  &:before {
    content: $mrv-gate;
  }
}
.mrv-gavel {
  &:before {
    content: $mrv-gavel;
  }
}
.mrv-gbadge {
  &:before {
    content: $mrv-gbadge;
  }
}
.mrv-gboard {
  &:before {
    content: $mrv-gboard;
  }
}
.mrv-generating_tokens {
  &:before {
    content: $mrv-generating_tokens;
  }
}
.mrv-gesture {
  &:before {
    content: $mrv-gesture;
  }
}
.mrv-gfit_health {
  &:before {
    content: $mrv-gfit_health;
  }
}
.mrv-gif {
  &:before {
    content: $mrv-gif;
  }
}
.mrv-gif_box {
  &:before {
    content: $mrv-gif_box;
  }
}
.mrv-girl {
  &:before {
    content: $mrv-girl;
  }
}
.mrv-gite {
  &:before {
    content: $mrv-gite;
  }
}
.mrv-gleaf {
  &:before {
    content: $mrv-gleaf;
  }
}
.mrv-gmail_add_on {
  &:before {
    content: $mrv-gmail_add_on;
  }
}
.mrv-gmail_apps_script {
  &:before {
    content: $mrv-gmail_apps_script;
  }
}
.mrv-gmail_rooms {
  &:before {
    content: $mrv-gmail_rooms;
  }
}
.mrv-golf_course {
  &:before {
    content: $mrv-golf_course;
  }
}
.mrv-google_fi {
  &:before {
    content: $mrv-google_fi;
  }
}
.mrv-google_guarantee {
  &:before {
    content: $mrv-google_guarantee;
  }
}
.mrv-google_plus_reshare {
  &:before {
    content: $mrv-google_plus_reshare;
  }
}
.mrv-google_travel {
  &:before {
    content: $mrv-google_travel;
  }
}
.mrv-google_travel_outline {
  &:before {
    content: $mrv-google_travel_outline;
  }
}
.mrv-google_tv {
  &:before {
    content: $mrv-google_tv;
  }
}
.mrv-google_wifi {
  &:before {
    content: $mrv-google_wifi;
  }
}
.mrv-googler_travel {
  &:before {
    content: $mrv-googler_travel;
  }
}
.mrv-gpay {
  &:before {
    content: $mrv-gpay;
  }
}
.mrv-gpp_bad {
  &:before {
    content: $mrv-gpp_bad;
  }
}
.mrv-gpp_maybe {
  &:before {
    content: $mrv-gpp_maybe;
  }
}
.mrv-grade {
  &:before {
    content: $mrv-grade;
  }
}
.mrv-gradient {
  &:before {
    content: $mrv-gradient;
  }
}
.mrv-grading {
  &:before {
    content: $mrv-grading;
  }
}
.mrv-grain {
  &:before {
    content: $mrv-grain;
  }
}
.mrv-graphic_eq {
  &:before {
    content: $mrv-graphic_eq;
  }
}
.mrv-grass {
  &:before {
    content: $mrv-grass;
  }
}
.mrv-grid_3x3 {
  &:before {
    content: $mrv-grid_3x3;
  }
}
.mrv-grid_4x4 {
  &:before {
    content: $mrv-grid_4x4;
  }
}
.mrv-grid_goldenratio {
  &:before {
    content: $mrv-grid_goldenratio;
  }
}
.mrv-grid_off {
  &:before {
    content: $mrv-grid_off;
  }
}
.mrv-grid_on {
  &:before {
    content: $mrv-grid_on;
  }
}
.mrv-grid_view {
  &:before {
    content: $mrv-grid_view;
  }
}
.mrv-group {
  &:before {
    content: $mrv-group;
  }
}
.mrv-group_add {
  &:before {
    content: $mrv-group_add;
  }
}
.mrv-group_off {
  &:before {
    content: $mrv-group_off;
  }
}
.mrv-group_remove {
  &:before {
    content: $mrv-group_remove;
  }
}
.mrv-group_work {
  &:before {
    content: $mrv-group_work;
  }
}
.mrv-grouped_bar_chart {
  &:before {
    content: $mrv-grouped_bar_chart;
  }
}
.mrv-h_mobiledata {
  &:before {
    content: $mrv-h_mobiledata;
  }
}
.mrv-h_plus_mobiledata {
  &:before {
    content: $mrv-h_plus_mobiledata;
  }
}
.mrv-hail {
  &:before {
    content: $mrv-hail;
  }
}
.mrv-hand_gesture {
  &:before {
    content: $mrv-hand_gesture;
  }
}
.mrv-handshake {
  &:before {
    content: $mrv-handshake;
  }
}
.mrv-handyman {
  &:before {
    content: $mrv-handyman;
  }
}
.mrv-hardware {
  &:before {
    content: $mrv-hardware;
  }
}
.mrv-hd {
  &:before {
    content: $mrv-hd;
  }
}
.mrv-hdr_auto {
  &:before {
    content: $mrv-hdr_auto;
  }
}
.mrv-hdr_auto_select {
  &:before {
    content: $mrv-hdr_auto_select;
  }
}
.mrv-hdr_enhanced_select {
  &:before {
    content: $mrv-hdr_enhanced_select;
  }
}
.mrv-hdr_off {
  &:before {
    content: $mrv-hdr_off;
  }
}
.mrv-hdr_off_select {
  &:before {
    content: $mrv-hdr_off_select;
  }
}
.mrv-hdr_on {
  &:before {
    content: $mrv-hdr_on;
  }
}
.mrv-hdr_on_select {
  &:before {
    content: $mrv-hdr_on_select;
  }
}
.mrv-hdr_plus {
  &:before {
    content: $mrv-hdr_plus;
  }
}
.mrv-hdr_strong {
  &:before {
    content: $mrv-hdr_strong;
  }
}
.mrv-hdr_weak {
  &:before {
    content: $mrv-hdr_weak;
  }
}
.mrv-headphones {
  &:before {
    content: $mrv-headphones;
  }
}
.mrv-headphones_battery {
  &:before {
    content: $mrv-headphones_battery;
  }
}
.mrv-headset_mic {
  &:before {
    content: $mrv-headset_mic;
  }
}
.mrv-headset_off {
  &:before {
    content: $mrv-headset_off;
  }
}
.mrv-healing {
  &:before {
    content: $mrv-healing;
  }
}
.mrv-health_and_safety {
  &:before {
    content: $mrv-health_and_safety;
  }
}
.mrv-hearing {
  &:before {
    content: $mrv-hearing;
  }
}
.mrv-hearing_disabled {
  &:before {
    content: $mrv-hearing_disabled;
  }
}
.mrv-heart_broken {
  &:before {
    content: $mrv-heart_broken;
  }
}
.mrv-heat_pump {
  &:before {
    content: $mrv-heat_pump;
  }
}
.mrv-height {
  &:before {
    content: $mrv-height;
  }
}
.mrv-help {
  &:before {
    content: $mrv-help;
  }
}
.mrv-help_center {
  &:before {
    content: $mrv-help_center;
  }
}
.mrv-hevc {
  &:before {
    content: $mrv-hevc;
  }
}
.mrv-hexagon {
  &:before {
    content: $mrv-hexagon;
  }
}
.mrv-hide {
  &:before {
    content: $mrv-hide;
  }
}
.mrv-hide_image {
  &:before {
    content: $mrv-hide_image;
  }
}
.mrv-hide_source {
  &:before {
    content: $mrv-hide_source;
  }
}
.mrv-high_quality {
  &:before {
    content: $mrv-high_quality;
  }
}
.mrv-highlight {
  &:before {
    content: $mrv-highlight;
  }
}
.mrv-hiking {
  &:before {
    content: $mrv-hiking;
  }
}
.mrv-history {
  &:before {
    content: $mrv-history;
  }
}
.mrv-history_edu {
  &:before {
    content: $mrv-history_edu;
  }
}
.mrv-history_toggle_off {
  &:before {
    content: $mrv-history_toggle_off;
  }
}
.mrv-hive {
  &:before {
    content: $mrv-hive;
  }
}
.mrv-hls {
  &:before {
    content: $mrv-hls;
  }
}
.mrv-hls_off {
  &:before {
    content: $mrv-hls_off;
  }
}
.mrv-holiday_village {
  &:before {
    content: $mrv-holiday_village;
  }
}
.mrv-home {
  &:before {
    content: $mrv-home;
  }
}
.mrv-home_app_logo {
  &:before {
    content: $mrv-home_app_logo;
  }
}
.mrv-home_iot_device {
  &:before {
    content: $mrv-home_iot_device;
  }
}
.mrv-home_max {
  &:before {
    content: $mrv-home_max;
  }
}
.mrv-home_max_dots {
  &:before {
    content: $mrv-home_max_dots;
  }
}
.mrv-home_mini {
  &:before {
    content: $mrv-home_mini;
  }
}
.mrv-home_pin {
  &:before {
    content: $mrv-home_pin;
  }
}
.mrv-home_repair_service {
  &:before {
    content: $mrv-home_repair_service;
  }
}
.mrv-home_speaker {
  &:before {
    content: $mrv-home_speaker;
  }
}
.mrv-home_work {
  &:before {
    content: $mrv-home_work;
  }
}
.mrv-horizontal_distribute {
  &:before {
    content: $mrv-horizontal_distribute;
  }
}
.mrv-horizontal_rule {
  &:before {
    content: $mrv-horizontal_rule;
  }
}
.mrv-horizontal_split {
  &:before {
    content: $mrv-horizontal_split;
  }
}
.mrv-hot_tub {
  &:before {
    content: $mrv-hot_tub;
  }
}
.mrv-hotel {
  &:before {
    content: $mrv-hotel;
  }
}
.mrv-hotel_class {
  &:before {
    content: $mrv-hotel_class;
  }
}
.mrv-hourglass_bottom {
  &:before {
    content: $mrv-hourglass_bottom;
  }
}
.mrv-hourglass_disabled {
  &:before {
    content: $mrv-hourglass_disabled;
  }
}
.mrv-hourglass_empty {
  &:before {
    content: $mrv-hourglass_empty;
  }
}
.mrv-hourglass_full {
  &:before {
    content: $mrv-hourglass_full;
  }
}
.mrv-hourglass_top {
  &:before {
    content: $mrv-hourglass_top;
  }
}
.mrv-house {
  &:before {
    content: $mrv-house;
  }
}
.mrv-house_siding {
  &:before {
    content: $mrv-house_siding;
  }
}
.mrv-houseboat {
  &:before {
    content: $mrv-houseboat;
  }
}
.mrv-how_to_reg {
  &:before {
    content: $mrv-how_to_reg;
  }
}
.mrv-how_to_vote {
  &:before {
    content: $mrv-how_to_vote;
  }
}
.mrv-html {
  &:before {
    content: $mrv-html;
  }
}
.mrv-http {
  &:before {
    content: $mrv-http;
  }
}
.mrv-hub {
  &:before {
    content: $mrv-hub;
  }
}
.mrv-humidity_high {
  &:before {
    content: $mrv-humidity_high;
  }
}
.mrv-humidity_low {
  &:before {
    content: $mrv-humidity_low;
  }
}
.mrv-humidity_mid {
  &:before {
    content: $mrv-humidity_mid;
  }
}
.mrv-hvac {
  &:before {
    content: $mrv-hvac;
  }
}
.mrv-ice_skating {
  &:before {
    content: $mrv-ice_skating;
  }
}
.mrv-icecream {
  &:before {
    content: $mrv-icecream;
  }
}
.mrv-identity_platform {
  &:before {
    content: $mrv-identity_platform;
  }
}
.mrv-image {
  &:before {
    content: $mrv-image;
  }
}
.mrv-image_aspect_ratio {
  &:before {
    content: $mrv-image_aspect_ratio;
  }
}
.mrv-image_not_supported {
  &:before {
    content: $mrv-image_not_supported;
  }
}
.mrv-image_search {
  &:before {
    content: $mrv-image_search;
  }
}
.mrv-imagesearch_roller {
  &:before {
    content: $mrv-imagesearch_roller;
  }
}
.mrv-imagesmode {
  &:before {
    content: $mrv-imagesmode;
  }
}
.mrv-import_contacts {
  &:before {
    content: $mrv-import_contacts;
  }
}
.mrv-important_devices {
  &:before {
    content: $mrv-important_devices;
  }
}
.mrv-in_home_mode {
  &:before {
    content: $mrv-in_home_mode;
  }
}
.mrv-inbox {
  &:before {
    content: $mrv-inbox;
  }
}
.mrv-incomplete_circle {
  &:before {
    content: $mrv-incomplete_circle;
  }
}
.mrv-indeterminate_check_box {
  &:before {
    content: $mrv-indeterminate_check_box;
  }
}
.mrv-info {
  &:before {
    content: $mrv-info;
  }
}
.mrv-input {
  &:before {
    content: $mrv-input;
  }
}
.mrv-insert_chart {
  &:before {
    content: $mrv-insert_chart;
  }
}
.mrv-insert_page_break {
  &:before {
    content: $mrv-insert_page_break;
  }
}
.mrv-insights {
  &:before {
    content: $mrv-insights;
  }
}
.mrv-install_desktop {
  &:before {
    content: $mrv-install_desktop;
  }
}
.mrv-install_mobile {
  &:before {
    content: $mrv-install_mobile;
  }
}
.mrv-integration_instructions {
  &:before {
    content: $mrv-integration_instructions;
  }
}
.mrv-interests {
  &:before {
    content: $mrv-interests;
  }
}
.mrv-interpreter_mode {
  &:before {
    content: $mrv-interpreter_mode;
  }
}
.mrv-inventory {
  &:before {
    content: $mrv-inventory;
  }
}
.mrv-inventory_2 {
  &:before {
    content: $mrv-inventory_2;
  }
}
.mrv-invert_colors {
  &:before {
    content: $mrv-invert_colors;
  }
}
.mrv-invert_colors_off {
  &:before {
    content: $mrv-invert_colors_off;
  }
}
.mrv-ios_share .path1 {
  &:before {
    content: $mrv-ios_share-path1;
    color: rgb(255, 255, 255);
  }
}
.mrv-ios_share .path2 {
  &:before {
    content: $mrv-ios_share-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.mrv-iron {
  &:before {
    content: $mrv-iron;
  }
}
.mrv-javascript {
  &:before {
    content: $mrv-javascript;
  }
}
.mrv-join_full {
  &:before {
    content: $mrv-join_full;
  }
}
.mrv-join_inner {
  &:before {
    content: $mrv-join_inner;
  }
}
.mrv-join_left {
  &:before {
    content: $mrv-join_left;
  }
}
.mrv-join_right {
  &:before {
    content: $mrv-join_right;
  }
}
.mrv-kayaking {
  &:before {
    content: $mrv-kayaking;
  }
}
.mrv-kebab_dining {
  &:before {
    content: $mrv-kebab_dining;
  }
}
.mrv-kettle {
  &:before {
    content: $mrv-kettle;
  }
}
.mrv-key {
  &:before {
    content: $mrv-key;
  }
}
.mrv-key_off {
  &:before {
    content: $mrv-key_off;
  }
}
.mrv-key_visualizer {
  &:before {
    content: $mrv-key_visualizer;
  }
}
.mrv-keyboard {
  &:before {
    content: $mrv-keyboard;
  }
}
.mrv-keyboard_alt {
  &:before {
    content: $mrv-keyboard_alt;
  }
}
.mrv-keyboard_arrow_down {
  &:before {
    content: $mrv-keyboard_arrow_down; 
  }
}
.mrv-keyboard_arrow_left {
  &:before {
    content: $mrv-keyboard_arrow_left;
  }
}
.mrv-keyboard_arrow_right {
  &:before {
    content: $mrv-keyboard_arrow_right;
  }
}
.mrv-keyboard_arrow_up {
  &:before {
    content: $mrv-keyboard_arrow_up;
  }
}
.mrv-keyboard_backspace {
  &:before {
    content: $mrv-keyboard_backspace;
  }
}
.mrv-keyboard_capslock {
  &:before {
    content: $mrv-keyboard_capslock;
  }
}
.mrv-keyboard_command_key {
  &:before {
    content: $mrv-keyboard_command_key;
  }
}
.mrv-keyboard_control_key {
  &:before {
    content: $mrv-keyboard_control_key;
  }
}
.mrv-keyboard_double_arrow_down {
  &:before {
    content: $mrv-keyboard_double_arrow_down;
  }
}
.mrv-keyboard_double_arrow_left {
  &:before {
    content: $mrv-keyboard_double_arrow_left;
  }
}
.mrv-keyboard_double_arrow_right {
  &:before {
    content: $mrv-keyboard_double_arrow_right;
  }
}
.mrv-keyboard_double_arrow_up {
  &:before {
    content: $mrv-keyboard_double_arrow_up;
  }
}
.mrv-keyboard_hide {
  &:before {
    content: $mrv-keyboard_hide;
  }
}
.mrv-keyboard_option_key {
  &:before {
    content: $mrv-keyboard_option_key;
  }
}
.mrv-keyboard_return {
  &:before {
    content: $mrv-keyboard_return;
  }
}
.mrv-keyboard_tab {
  &:before {
    content: $mrv-keyboard_tab;
  }
}
.mrv-keyboard_voice {
  &:before {
    content: $mrv-keyboard_voice;
  }
}
.mrv-king_bed {
  &:before {
    content: $mrv-king_bed;
  }
}
.mrv-kitchen {
  &:before {
    content: $mrv-kitchen;
  }
}
.mrv-kitesurfing {
  &:before {
    content: $mrv-kitesurfing;
  }
}
.mrv-kormo {
  &:before {
    content: $mrv-kormo;
  }
}
.mrv-label {
  &:before {
    content: $mrv-label;
  }
}
.mrv-label_important {
  &:before {
    content: $mrv-label_important;
  }
}
.mrv-label_off {
  &:before {
    content: $mrv-label_off;
  }
}
.mrv-lan {
  &:before {
    content: $mrv-lan;
  }
}
.mrv-landscape {
  &:before {
    content: $mrv-landscape;
  }
}
.mrv-landslide {
  &:before {
    content: $mrv-landslide;
  }
}
.mrv-language {
  &:before {
    content: $mrv-language;
  }
}
.mrv-laptop_chromebook {
  &:before {
    content: $mrv-laptop_chromebook;
  }
}
.mrv-laptop_mac {
  &:before {
    content: $mrv-laptop_mac;
  }
}
.mrv-laptop_windows {
  &:before {
    content: $mrv-laptop_windows;
  }
}
.mrv-last_page {
  &:before {
    content: $mrv-last_page;
  }
}
.mrv-layers {
  &:before {
    content: $mrv-layers;
  }
}
.mrv-layers_clear {
  &:before {
    content: $mrv-layers_clear;
  }
}
.mrv-leaderboard {
  &:before {
    content: $mrv-leaderboard;
  }
}
.mrv-leak_add {
  &:before {
    content: $mrv-leak_add;
  }
}
.mrv-leak_remove {
  &:before {
    content: $mrv-leak_remove;
  }
}
.mrv-legend_toggle {
  &:before {
    content: $mrv-legend_toggle;
  }
}
.mrv-lens {
  &:before {
    content: $mrv-lens;
  }
}
.mrv-lens_blur {
  &:before {
    content: $mrv-lens_blur;
  }
}
.mrv-library_add {
  &:before {
    content: $mrv-library_add;
  }
}
.mrv-library_add_check {
  &:before {
    content: $mrv-library_add_check;
  }
}
.mrv-library_books {
  &:before {
    content: $mrv-library_books;
  }
}
.mrv-library_music {
  &:before {
    content: $mrv-library_music;
  }
}
.mrv-lift_to_talk {
  &:before {
    content: $mrv-lift_to_talk;
  }
}
.mrv-light {
  &:before {
    content: $mrv-light;
  }
}
.mrv-light_mode {
  &:before {
    content: $mrv-light_mode;
  }
}
.mrv-lightbulb {
  &:before {
    content: $mrv-lightbulb;
  }
}
.mrv-lightbulb_circle {
  &:before {
    content: $mrv-lightbulb_circle;
  }
}
.mrv-line_axis {
  &:before {
    content: $mrv-line_axis;
  }
}
.mrv-line_style {
  &:before {
    content: $mrv-line_style;
  }
}
.mrv-line_weight {
  &:before {
    content: $mrv-line_weight;
  }
}
.mrv-linear_scale {
  &:before {
    content: $mrv-linear_scale;
  }
}
.mrv-link {
  &:before {
    content: $mrv-link;
  }
}
.mrv-link_off {
  &:before {
    content: $mrv-link_off;
  }
}
.mrv-linked_camera {
  &:before {
    content: $mrv-linked_camera;
  }
}
.mrv-liquor {
  &:before {
    content: $mrv-liquor;
  }
}
.mrv-list {
  &:before {
    content: $mrv-list;
  }
}
.mrv-list_alt {
  &:before {
    content: $mrv-list_alt;
  }
}
.mrv-live_help {
  &:before {
    content: $mrv-live_help;
  }
}
.mrv-live_tv {
  &:before {
    content: $mrv-live_tv;
  }
}
.mrv-living {
  &:before {
    content: $mrv-living;
  }
}
.mrv-local_activity {
  &:before {
    content: $mrv-local_activity;
  }
}
.mrv-local_atm {
  &:before {
    content: $mrv-local_atm;
  }
}
.mrv-local_bar {
  &:before {
    content: $mrv-local_bar;
  }
}
.mrv-local_cafe {
  &:before {
    content: $mrv-local_cafe;
  }
}
.mrv-local_car_wash {
  &:before {
    content: $mrv-local_car_wash;
  }
}
.mrv-local_convenience_store {
  &:before {
    content: $mrv-local_convenience_store;
  }
}
.mrv-local_dining {
  &:before {
    content: $mrv-local_dining;
  }
}
.mrv-local_drink {
  &:before {
    content: $mrv-local_drink;
  }
}
.mrv-local_fire_department {
  &:before {
    content: $mrv-local_fire_department;
  }
}
.mrv-local_florist {
  &:before {
    content: $mrv-local_florist;
  }
}
.mrv-local_gas_station {
  &:before {
    content: $mrv-local_gas_station;
  }
}
.mrv-local_hospital {
  &:before {
    content: $mrv-local_hospital;
  }
}
.mrv-local_laundry_service {
  &:before {
    content: $mrv-local_laundry_service;
  }
}
.mrv-local_library {
  &:before {
    content: $mrv-local_library;
  }
}
.mrv-local_mall {
  &:before {
    content: $mrv-local_mall;
  }
}
.mrv-local_parking {
  &:before {
    content: $mrv-local_parking;
  }
}
.mrv-local_pharmacy {
  &:before {
    content: $mrv-local_pharmacy;
  }
}
.mrv-local_pizza {
  &:before {
    content: $mrv-local_pizza;
  }
}
.mrv-local_police {
  &:before {
    content: $mrv-local_police;
  }
}
.mrv-local_post_office {
  &:before {
    content: $mrv-local_post_office;
  }
}
.mrv-local_see {
  &:before {
    content: $mrv-local_see;
  }
}
.mrv-local_shipping {
  &:before {
    content: $mrv-local_shipping;
  }
}
.mrv-local_taxi {
  &:before {
    content: $mrv-local_taxi;
  }
}
.mrv-location_automation {
  &:before {
    content: $mrv-location_automation;
  }
}
.mrv-location_away {
  &:before {
    content: $mrv-location_away;
  }
}
.mrv-location_city {
  &:before {
    content: $mrv-location_city;
  }
}
.mrv-location_disabled {
  &:before {
    content: $mrv-location_disabled;
  }
}
.mrv-location_home {
  &:before {
    content: $mrv-location_home;
  }
}
.mrv-location_off {
  &:before {
    content: $mrv-location_off;
  }
}
.mrv-location_searching {
  &:before {
    content: $mrv-location_searching;
  }
}
.mrv-lock {
  &:before {
    content: $mrv-lock;
  }
}
.mrv-lock_clock {
  &:before {
    content: $mrv-lock_clock;
  }
}
.mrv-lock_open {
  &:before {
    content: $mrv-lock_open;
  }
}
.mrv-lock_person {
  &:before {
    content: $mrv-lock_person;
  }
}
.mrv-lock_reset {
  &:before {
    content: $mrv-lock_reset;
  }
}
.mrv-login {
  &:before {
    content: $mrv-login;
  }
}
.mrv-logo_dev {
  &:before {
    content: $mrv-logo_dev;
  }
}
.mrv-logout {
  &:before {
    content: $mrv-logout;
  }
}
.mrv-looks {
  &:before {
    content: $mrv-looks;
  }
}
.mrv-looks_3 {
  &:before {
    content: $mrv-looks_3;
  }
}
.mrv-looks_4 {
  &:before {
    content: $mrv-looks_4;
  }
}
.mrv-looks_5 {
  &:before {
    content: $mrv-looks_5;
  }
}
.mrv-looks_6 {
  &:before {
    content: $mrv-looks_6;
  }
}
.mrv-looks_one {
  &:before {
    content: $mrv-looks_one;
  }
}
.mrv-looks_two {
  &:before {
    content: $mrv-looks_two;
  }
}
.mrv-loupe {
  &:before {
    content: $mrv-loupe;
  }
}
.mrv-low_priority {
  &:before {
    content: $mrv-low_priority;
  }
}
.mrv-loyalty {
  &:before {
    content: $mrv-loyalty;
  }
}
.mrv-lte_mobiledata {
  &:before {
    content: $mrv-lte_mobiledata;
  }
}
.mrv-lte_plus_mobiledata {
  &:before {
    content: $mrv-lte_plus_mobiledata;
  }
}
.mrv-luggage {
  &:before {
    content: $mrv-luggage;
  }
}
.mrv-lunch_dining {
  &:before {
    content: $mrv-lunch_dining;
  }
}
.mrv-lyrics {
  &:before {
    content: $mrv-lyrics;
  }
}
.mrv-machine_image {
  &:before {
    content: $mrv-machine_image;
  }
}
.mrv-magic_button {
  &:before {
    content: $mrv-magic_button;
  }
}
.mrv-mail {
  &:before {
    content: $mrv-mail;
  }
}
.mrv-mail_lock {
  &:before {
    content: $mrv-mail_lock;
  }
}
.mrv-male {
  &:before {
    content: $mrv-male;
  }
}
.mrv-man {
  &:before {
    content: $mrv-man;
  }
}
.mrv-manage_accounts {
  &:before {
    content: $mrv-manage_accounts;
  }
}
.mrv-manage_history {
  &:before {
    content: $mrv-manage_history;
  }
}
.mrv-manage_search {
  &:before {
    content: $mrv-manage_search;
  }
}
.mrv-map {
  &:before {
    content: $mrv-map;
  }
}
.mrv-maps_ugc {
  &:before {
    content: $mrv-maps_ugc;
  }
}
.mrv-margin {
  &:before {
    content: $mrv-margin;
  }
}
.mrv-mark_as_unread {
  &:before {
    content: $mrv-mark_as_unread;
  }
}
.mrv-mark_chat_read {
  &:before {
    content: $mrv-mark_chat_read;
  }
}
.mrv-mark_chat_unread {
  &:before {
    content: $mrv-mark_chat_unread;
  }
}
.mrv-mark_email_read {
  &:before {
    content: $mrv-mark_email_read;
  }
}
.mrv-mark_email_unread {
  &:before {
    content: $mrv-mark_email_unread;
  }
}
.mrv-mark_unread_chat_alt {
  &:before {
    content: $mrv-mark_unread_chat_alt;
  }
}
.mrv-markunread_mailbox {
  &:before {
    content: $mrv-markunread_mailbox;
  }
}
.mrv-masks {
  &:before {
    content: $mrv-masks;
  }
}
.mrv-matter {
  &:before {
    content: $mrv-matter;
  }
}
.mrv-maximize {
  &:before {
    content: $mrv-maximize;
  }
}
.mrv-media_bluetooth_off {
  &:before {
    content: $mrv-media_bluetooth_off;
  }
}
.mrv-media_bluetooth_on {
  &:before {
    content: $mrv-media_bluetooth_on;
  }
}
.mrv-mediation {
  &:before {
    content: $mrv-mediation;
  }
}
.mrv-medical_information {
  &:before {
    content: $mrv-medical_information;
  }
}
.mrv-medical_services {
  &:before {
    content: $mrv-medical_services;
  }
}
.mrv-medication {
  &:before {
    content: $mrv-medication;
  }
}
.mrv-medication_liquid {
  &:before {
    content: $mrv-medication_liquid;
  }
}
.mrv-meeting_room {
  &:before {
    content: $mrv-meeting_room;
  }
}
.mrv-memory {
  &:before {
    content: $mrv-memory;
  }
}
.mrv-menu {
  &:before {
    content: $mrv-menu;
  }
}
.mrv-menu_book {
  &:before {
    content: $mrv-menu_book;
  }
}
.mrv-menu_open {
  &:before {
    content: $mrv-menu_open;
  }
}
.mrv-merge {
  &:before {
    content: $mrv-merge;
  }
}
.mrv-merge_type {
  &:before {
    content: $mrv-merge_type;
  }
}
.mrv-mfg_nest_yale_lock {
  &:before {
    content: $mrv-mfg_nest_yale_lock;
  }
}
.mrv-mic {
  &:before {
    content: $mrv-mic;
  }
}
.mrv-mic_external_off {
  &:before {
    content: $mrv-mic_external_off;
  }
}
.mrv-mic_external_on {
  &:before {
    content: $mrv-mic_external_on;
  }
}
.mrv-mic_off {
  &:before {
    content: $mrv-mic_off;
  }
}
.mrv-microwave {
  &:before {
    content: $mrv-microwave;
  }
}
.mrv-microwave_gen {
  &:before {
    content: $mrv-microwave_gen;
  }
}
.mrv-military_tech {
  &:before {
    content: $mrv-military_tech;
  }
}
.mrv-minimize {
  &:before {
    content: $mrv-minimize;
  }
}
.mrv-minor_crash {
  &:before {
    content: $mrv-minor_crash;
  }
}
.mrv-missed_video_call {
  &:before {
    content: $mrv-missed_video_call;
  }
}
.mrv-mms {
  &:before {
    content: $mrv-mms;
  }
}
.mrv-mobile_friendly {
  &:before {
    content: $mrv-mobile_friendly;
  }
}
.mrv-mobile_off {
  &:before {
    content: $mrv-mobile_off;
  }
}
.mrv-mobile_screen_share {
  &:before {
    content: $mrv-mobile_screen_share;
  }
}
.mrv-mobiledata_off {
  &:before {
    content: $mrv-mobiledata_off;
  }
}
.mrv-mode_comment {
  &:before {
    content: $mrv-mode_comment;
  }
}
.mrv-mode_cool {
  &:before {
    content: $mrv-mode_cool;
  }
}
.mrv-mode_cool_off {
  &:before {
    content: $mrv-mode_cool_off;
  }
}
.mrv-mode_fan {
  &:before {
    content: $mrv-mode_fan;
  }
}
.mrv-mode_fan_off {
  &:before {
    content: $mrv-mode_fan_off;
  }
}
.mrv-mode_heat {
  &:before {
    content: $mrv-mode_heat;
  }
}
.mrv-mode_heat_cool {
  &:before {
    content: $mrv-mode_heat_cool;
  }
}
.mrv-mode_heat_off {
  &:before {
    content: $mrv-mode_heat_off;
  }
}
.mrv-mode_night {
  &:before {
    content: $mrv-mode_night;
  }
}
.mrv-mode_of_travel {
  &:before {
    content: $mrv-mode_of_travel;
  }
}
.mrv-mode_off_on {
  &:before {
    content: $mrv-mode_off_on;
  }
}
.mrv-mode_standby {
  &:before {
    content: $mrv-mode_standby;
  }
}
.mrv-model_training {
  &:before {
    content: $mrv-model_training;
  }
}
.mrv-monetization_on {
  &:before {
    content: $mrv-monetization_on;
  }
}
.mrv-money {
  &:before {
    content: $mrv-money;
  }
}
.mrv-money_off {
  &:before {
    content: $mrv-money_off;
  }
}
.mrv-monitor {
  &:before {
    content: $mrv-monitor;
  }
}
.mrv-monitor_heart {
  &:before {
    content: $mrv-monitor_heart;
  }
}
.mrv-monitor_weight {
  &:before {
    content: $mrv-monitor_weight;
  }
}
.mrv-monitoring {
  &:before {
    content: $mrv-monitoring;
  }
}
.mrv-monochrome_photos {
  &:before {
    content: $mrv-monochrome_photos;
  }
}
.mrv-mood {
  &:before {
    content: $mrv-mood;
  }
}
.mrv-mood_bad {
  &:before {
    content: $mrv-mood_bad;
  }
}
.mrv-mop {
  &:before {
    content: $mrv-mop;
  }
}
.mrv-more {
  &:before {
    content: $mrv-more;
  }
}
.mrv-more_down {
  &:before {
    content: $mrv-more_down;
  }
}
.mrv-more_horiz {
  &:before {
    content: $mrv-more_horiz;
  }
}
.mrv-more_time {
  &:before {
    content: $mrv-more_time;
  }
}
.mrv-more_up {
  &:before {
    content: $mrv-more_up;
  }
}
.mrv-more_vert {
  &:before {
    content: $mrv-more_vert;
  }
}
.mrv-mosque {
  &:before {
    content: $mrv-mosque;
  }
}
.mrv-motion_blur {
  &:before {
    content: $mrv-motion_blur;
  }
}
.mrv-motion_photos_auto {
  &:before {
    content: $mrv-motion_photos_auto;
  }
}
.mrv-motion_photos_off {
  &:before {
    content: $mrv-motion_photos_off;
  }
}
.mrv-motion_photos_paused {
  &:before {
    content: $mrv-motion_photos_paused;
  }
}
.mrv-motion_sense {
  &:before {
    content: $mrv-motion_sense;
  }
}
.mrv-motorcycle {
  &:before {
    content: $mrv-motorcycle;
  }
}
.mrv-mouse {
  &:before {
    content: $mrv-mouse;
  }
}
.mrv-move_down {
  &:before {
    content: $mrv-move_down;
  }
}
.mrv-move_to_inbox {
  &:before {
    content: $mrv-move_to_inbox;
  }
}
.mrv-move_up {
  &:before {
    content: $mrv-move_up;
  }
}
.mrv-movie {
  &:before {
    content: $mrv-movie;
  }
}
.mrv-movie_filter {
  &:before {
    content: $mrv-movie_filter;
  }
}
.mrv-moving {
  &:before {
    content: $mrv-moving;
  }
}
.mrv-mp23 {
  &:before {
    content: $mrv-mp23;
  }
}
.mrv-multicooker {
  &:before {
    content: $mrv-multicooker;
  }
}
.mrv-multiline_chart {
  &:before {
    content: $mrv-multiline_chart;
  }
}
.mrv-multiple_stop {
  &:before {
    content: $mrv-multiple_stop;
  }
}
.mrv-museum {
  &:before {
    content: $mrv-museum;
  }
}
.mrv-music_note {
  &:before {
    content: $mrv-music_note;
  }
}
.mrv-music_off {
  &:before {
    content: $mrv-music_off;
  }
}
.mrv-music_video {
  &:before {
    content: $mrv-music_video;
  }
}
.mrv-my_location {
  &:before {
    content: $mrv-my_location;
  }
}
.mrv-nat {
  &:before {
    content: $mrv-nat;
  }
}
.mrv-nature {
  &:before {
    content: $mrv-nature;
  }
}
.mrv-nature_people {
  &:before {
    content: $mrv-nature_people;
  }
}
.mrv-navigate_before {
  &:before {
    content: $mrv-navigate_before;
  }
}
.mrv-navigate_next {
  &:before {
    content: $mrv-navigate_next;
  }
}
.mrv-navigation {
  &:before {
    content: $mrv-navigation;
  }
}
.mrv-near_me {
  &:before {
    content: $mrv-near_me;
  }
}
.mrv-near_me_disabled {
  &:before {
    content: $mrv-near_me_disabled;
  }
}
.mrv-nearby_error {
  &:before {
    content: $mrv-nearby_error;
  }
}
.mrv-nearby_off {
  &:before {
    content: $mrv-nearby_off;
  }
}
.mrv-nearby_share {
  &:before {
    content: $mrv-nearby_share;
  }
}
.mrv-nest_audio {
  &:before {
    content: $mrv-nest_audio;
  }
}
.mrv-nest_cam_indoor {
  &:before {
    content: $mrv-nest_cam_indoor;
  }
}
.mrv-nest_cam_iq {
  &:before {
    content: $mrv-nest_cam_iq;
  }
}
.mrv-nest_cam_iq_outdoor {
  &:before {
    content: $mrv-nest_cam_iq_outdoor;
  }
}
.mrv-nest_cam_outdoor {
  &:before {
    content: $mrv-nest_cam_outdoor;
  }
}
.mrv-nest_cam_wired_stand {
  &:before {
    content: $mrv-nest_cam_wired_stand;
  }
}
.mrv-nest_connect {
  &:before {
    content: $mrv-nest_connect;
  }
}
.mrv-nest_detect {
  &:before {
    content: $mrv-nest_detect;
  }
}
.mrv-nest_display {
  &:before {
    content: $mrv-nest_display;
  }
}
.mrv-nest_display_max {
  &:before {
    content: $mrv-nest_display_max;
  }
}
.mrv-nest_eco {
  &:before {
    content: $mrv-nest_eco;
  }
}
.mrv-nest_heat_link_e {
  &:before {
    content: $mrv-nest_heat_link_e;
  }
}
.mrv-nest_heat_link_gen_3 {
  &:before {
    content: $mrv-nest_heat_link_gen_3;
  }
}
.mrv-nest_hello_doorbell {
  &:before {
    content: $mrv-nest_hello_doorbell;
  }
}
.mrv-nest_mini {
  &:before {
    content: $mrv-nest_mini;
  }
}
.mrv-nest_remote {
  &:before {
    content: $mrv-nest_remote;
  }
}
.mrv-nest_remote_comfort_sensor {
  &:before {
    content: $mrv-nest_remote_comfort_sensor;
  }
}
.mrv-nest_secure_alarm {
  &:before {
    content: $mrv-nest_secure_alarm;
  }
}
.mrv-nest_tag {
  &:before {
    content: $mrv-nest_tag;
  }
}
.mrv-nest_thermostat_e_eu {
  &:before {
    content: $mrv-nest_thermostat_e_eu;
  }
}
.mrv-nest_thermostat_gen_3 {
  &:before {
    content: $mrv-nest_thermostat_gen_3;
  }
}
.mrv-nest_thermostat_sensor {
  &:before {
    content: $mrv-nest_thermostat_sensor;
  }
}
.mrv-nest_thermostat_sensor_eu {
  &:before {
    content: $mrv-nest_thermostat_sensor_eu;
  }
}
.mrv-nest_thermostat_zirconium_eu {
  &:before {
    content: $mrv-nest_thermostat_zirconium_eu;
  }
}
.mrv-nest_wifi_gale {
  &:before {
    content: $mrv-nest_wifi_gale;
  }
}
.mrv-nest_wifi_mistral {
  &:before {
    content: $mrv-nest_wifi_mistral;
  }
}
.mrv-nest_wifi_point {
  &:before {
    content: $mrv-nest_wifi_point;
  }
}
.mrv-nest_wifi_point_vento {
  &:before {
    content: $mrv-nest_wifi_point_vento;
  }
}
.mrv-nest_wifi_router {
  &:before {
    content: $mrv-nest_wifi_router;
  }
}
.mrv-network_cell {
  &:before {
    content: $mrv-network_cell;
  }
}
.mrv-network_check {
  &:before {
    content: $mrv-network_check;
  }
}
.mrv-network_intelligence {
  &:before {
    content: $mrv-network_intelligence;
  }
}
.mrv-network_locked {
  &:before {
    content: $mrv-network_locked;
  }
}
.mrv-network_ping {
  &:before {
    content: $mrv-network_ping;
  }
}
.mrv-network_wifi {
  &:before {
    content: $mrv-network_wifi;
  }
}
.mrv-network_wifi_1_bar {
  &:before {
    content: $mrv-network_wifi_1_bar;
  }
}
.mrv-network_wifi_2_bar {
  &:before {
    content: $mrv-network_wifi_2_bar;
  }
}
.mrv-network_wifi_3_bar {
  &:before {
    content: $mrv-network_wifi_3_bar;
  }
}
.mrv-new_label {
  &:before {
    content: $mrv-new_label;
  }
}
.mrv-new_releases {
  &:before {
    content: $mrv-new_releases;
  }
}
.mrv-newspaper {
  &:before {
    content: $mrv-newspaper;
  }
}
.mrv-next_plan {
  &:before {
    content: $mrv-next_plan;
  }
}
.mrv-next_week {
  &:before {
    content: $mrv-next_week;
  }
}
.mrv-nfc {
  &:before {
    content: $mrv-nfc;
  }
}
.mrv-night_shelter {
  &:before {
    content: $mrv-night_shelter;
  }
}
.mrv-night_sight_auto {
  &:before {
    content: $mrv-night_sight_auto;
  }
}
.mrv-night_sight_auto_off {
  &:before {
    content: $mrv-night_sight_auto_off;
  }
}
.mrv-nightlife {
  &:before {
    content: $mrv-nightlife;
  }
}
.mrv-nightlight {
  &:before {
    content: $mrv-nightlight;
  }
}
.mrv-nights_stay {
  &:before {
    content: $mrv-nights_stay;
  }
}
.mrv-no_accounts {
  &:before {
    content: $mrv-no_accounts;
  }
}
.mrv-no_adult_content {
  &:before {
    content: $mrv-no_adult_content;
  }
}
.mrv-no_backpack {
  &:before {
    content: $mrv-no_backpack;
  }
}
.mrv-no_crash {
  &:before {
    content: $mrv-no_crash;
  }
}
.mrv-no_drinks {
  &:before {
    content: $mrv-no_drinks;
  }
}
.mrv-no_encryption {
  &:before {
    content: $mrv-no_encryption;
  }
}
.mrv-no_flash {
  &:before {
    content: $mrv-no_flash;
  }
}
.mrv-no_food {
  &:before {
    content: $mrv-no_food;
  }
}
.mrv-no_luggage {
  &:before {
    content: $mrv-no_luggage;
  }
}
.mrv-no_meals {
  &:before {
    content: $mrv-no_meals;
  }
}
.mrv-no_meeting_room {
  &:before {
    content: $mrv-no_meeting_room;
  }
}
.mrv-no_photography {
  &:before {
    content: $mrv-no_photography;
  }
}
.mrv-no_sim {
  &:before {
    content: $mrv-no_sim;
  }
}
.mrv-no_stroller {
  &:before {
    content: $mrv-no_stroller;
  }
}
.mrv-no_transfer {
  &:before {
    content: $mrv-no_transfer;
  }
}
.mrv-noise_aware {
  &:before {
    content: $mrv-noise_aware;
  }
}
.mrv-noise_control_off {
  &:before {
    content: $mrv-noise_control_off;
  }
}
.mrv-nordic_walking {
  &:before {
    content: $mrv-nordic_walking;
  }
}
.mrv-north {
  &:before {
    content: $mrv-north;
  }
}
.mrv-north_east {
  &:before {
    content: $mrv-north_east;
  }
}
.mrv-north_west {
  &:before {
    content: $mrv-north_west;
  }
}
.mrv-not_accessible {
  &:before {
    content: $mrv-not_accessible;
  }
}
.mrv-not_listed_location {
  &:before {
    content: $mrv-not_listed_location;
  }
}
.mrv-not_started {
  &:before {
    content: $mrv-not_started;
  }
}
.mrv-note {
  &:before {
    content: $mrv-note;
  }
}
.mrv-note_add {
  &:before {
    content: $mrv-note_add;
  }
}
.mrv-note_alt {
  &:before {
    content: $mrv-note_alt;
  }
}
.mrv-notes {
  &:before {
    content: $mrv-notes;
  }
}
.mrv-notification_add {
  &:before {
    content: $mrv-notification_add;
  }
}
.mrv-notification_important {
  &:before {
    content: $mrv-notification_important;
  }
}
.mrv-notifications {
  &:before {
    content: $mrv-notifications;
  }
}
.mrv-notifications_active {
  &:before {
    content: $mrv-notifications_active;
  }
}
.mrv-notifications_off {
  &:before {
    content: $mrv-notifications_off;
  }
}
.mrv-notifications_paused {
  &:before {
    content: $mrv-notifications_paused;
  }
}
.mrv-numbers {
  &:before {
    content: $mrv-numbers;
  }
}
.mrv-offline_bolt {
  &:before {
    content: $mrv-offline_bolt;
  }
}
.mrv-offline_pin {
  &:before {
    content: $mrv-offline_pin;
  }
}
.mrv-offline_share {
  &:before {
    content: $mrv-offline_share;
  }
}
.mrv-oil_barrel {
  &:before {
    content: $mrv-oil_barrel;
  }
}
.mrv-on_device_training {
  &:before {
    content: $mrv-on_device_training;
  }
}
.mrv-online_prediction {
  &:before {
    content: $mrv-online_prediction;
  }
}
.mrv-opacity {
  &:before {
    content: $mrv-opacity;
  }
}
.mrv-open_in_browser {
  &:before {
    content: $mrv-open_in_browser;
  }
}
.mrv-open_in_full {
  &:before {
    content: $mrv-open_in_full;
  }
}
.mrv-open_in_new {
  &:before {
    content: $mrv-open_in_new;
  }
}
.mrv-open_in_new_off {
  &:before {
    content: $mrv-open_in_new_off;
  }
}
.mrv-open_with {
  &:before {
    content: $mrv-open_with;
  }
}
.mrv-other_houses {
  &:before {
    content: $mrv-other_houses;
  }
}
.mrv-outbound {
  &:before {
    content: $mrv-outbound;
  }
}
.mrv-outbox {
  &:before {
    content: $mrv-outbox;
  }
}
.mrv-outdoor_garden {
  &:before {
    content: $mrv-outdoor_garden;
  }
}
.mrv-outdoor_grill {
  &:before {
    content: $mrv-outdoor_grill;
  }
}
.mrv-outgoing_mail {
  &:before {
    content: $mrv-outgoing_mail;
  }
}
.mrv-outlet {
  &:before {
    content: $mrv-outlet;
  }
}
.mrv-output {
  &:before {
    content: $mrv-output;
  }
}
.mrv-oven_gen {
  &:before {
    content: $mrv-oven_gen;
  }
}
.mrv-packet_mirroring {
  &:before {
    content: $mrv-packet_mirroring;
  }
}
.mrv-padding {
  &:before {
    content: $mrv-padding;
  }
}
.mrv-pages {
  &:before {
    content: $mrv-pages;
  }
}
.mrv-pageview {
  &:before {
    content: $mrv-pageview;
  }
}
.mrv-paid {
  &:before {
    content: $mrv-paid;
  }
}
.mrv-palette {
  &:before {
    content: $mrv-palette;
  }
}
.mrv-pan_tool {
  &:before {
    content: $mrv-pan_tool;
  }
}
.mrv-pan_tool_alt {
  &:before {
    content: $mrv-pan_tool_alt;
  }
}
.mrv-panorama {
  &:before {
    content: $mrv-panorama;
  }
}
.mrv-panorama_fish_eye {
  &:before {
    content: $mrv-panorama_fish_eye;
  }
}
.mrv-panorama_horizontal {
  &:before {
    content: $mrv-panorama_horizontal;
  }
}
.mrv-panorama_photosphere {
  &:before {
    content: $mrv-panorama_photosphere;
  }
}
.mrv-panorama_vertical {
  &:before {
    content: $mrv-panorama_vertical;
  }
}
.mrv-panorama_wide_angle {
  &:before {
    content: $mrv-panorama_wide_angle;
  }
}
.mrv-paragliding {
  &:before {
    content: $mrv-paragliding;
  }
}
.mrv-park {
  &:before {
    content: $mrv-park;
  }
}
.mrv-partly_cloudy_day {
  &:before {
    content: $mrv-partly_cloudy_day;
  }
}
.mrv-partly_cloudy_night {
  &:before {
    content: $mrv-partly_cloudy_night;
  }
}
.mrv-party_mode {
  &:before {
    content: $mrv-party_mode;
  }
}
.mrv-password {
  &:before {
    content: $mrv-password;
  }
}
.mrv-pattern {
  &:before {
    content: $mrv-pattern;
  }
}
.mrv-pause {
  &:before {
    content: $mrv-pause;
  }
}
.mrv-pause_circle {
  &:before {
    content: $mrv-pause_circle;
  }
}
.mrv-pause_presentation {
  &:before {
    content: $mrv-pause_presentation;
  }
}
.mrv-payments {
  &:before {
    content: $mrv-payments;
  }
}
.mrv-pedal_bike {
  &:before {
    content: $mrv-pedal_bike;
  }
}
.mrv-pending {
  &:before {
    content: $mrv-pending;
  }
}
.mrv-pending_actions {
  &:before {
    content: $mrv-pending_actions;
  }
}
.mrv-pentagon {
  &:before {
    content: $mrv-pentagon;
  }
}
.mrv-percent {
  &:before {
    content: $mrv-percent;
  }
}
.mrv-pergola {
  &:before {
    content: $mrv-pergola;
  }
}
.mrv-perm_camera_mic {
  &:before {
    content: $mrv-perm_camera_mic;
  }
}
.mrv-perm_contact_calendar {
  &:before {
    content: $mrv-perm_contact_calendar;
  }
}
.mrv-perm_data_setting {
  &:before {
    content: $mrv-perm_data_setting;
  }
}
.mrv-perm_device_information {
  &:before {
    content: $mrv-perm_device_information;
  }
}
.mrv-perm_media {
  &:before {
    content: $mrv-perm_media;
  }
}
.mrv-perm_phone_msg {
  &:before {
    content: $mrv-perm_phone_msg;
  }
}
.mrv-perm_scan_wifi {
  &:before {
    content: $mrv-perm_scan_wifi;
  }
}
.mrv-person {
  &:before {
    content: $mrv-person;
  }
}
.mrv-person_add {
  &:before {
    content: $mrv-person_add;
  }
}
.mrv-person_add_disabled {
  &:before {
    content: $mrv-person_add_disabled;
  }
}
.mrv-person_filled {
  &:before {
    content: $mrv-person_filled; 
  }
}
.mrv-person_off {
  &:before {
    content: $mrv-person_off;
  }
}
.mrv-person_pin {
  &:before {
    content: $mrv-person_pin;
  }
}
.mrv-person_pin_circle {
  &:before {
    content: $mrv-person_pin_circle;
  }
}
.mrv-person_remove {
  &:before {
    content: $mrv-person_remove;
  }
}
.mrv-person_search {
  &:before {
    content: $mrv-person_search;
  }
}
.mrv-personal_injury {
  &:before {
    content: $mrv-personal_injury;
  }
}
.mrv-personalized_recommendations {
  &:before {
    content: $mrv-personalized_recommendations;
  }
}
.mrv-pest_control {
  &:before {
    content: $mrv-pest_control;
  }
}
.mrv-pest_control_rodent {
  &:before {
    content: $mrv-pest_control_rodent;
  }
}
.mrv-pets {
  &:before {
    content: $mrv-pets;
  }
}
.mrv-phishing {
  &:before {
    content: $mrv-phishing;
  }
}
.mrv-phone_android {
  &:before {
    content: $mrv-phone_android;
  }
}
.mrv-phone_bluetooth_speaker {
  &:before {
    content: $mrv-phone_bluetooth_speaker;
  }
}
.mrv-phone_callback {
  &:before {
    content: $mrv-phone_callback;
  }
}
.mrv-phone_disabled {
  &:before {
    content: $mrv-phone_disabled;
  }
}
.mrv-phone_enabled {
  &:before {
    content: $mrv-phone_enabled;
  }
}
.mrv-phone_forwarded {
  &:before {
    content: $mrv-phone_forwarded;
  }
}
.mrv-phone_in_talk {
  &:before {
    content: $mrv-phone_in_talk;
  }
}
.mrv-phone_iphone {
  &:before {
    content: $mrv-phone_iphone;
  }
}
.mrv-phone_locked {
  &:before {
    content: $mrv-phone_locked;
  }
}
.mrv-phone_missed {
  &:before {
    content: $mrv-phone_missed;
  }
}
.mrv-phone_paused {
  &:before {
    content: $mrv-phone_paused;
  }
}
.mrv-phonelink_erase {
  &:before {
    content: $mrv-phonelink_erase;
  }
}
.mrv-phonelink_lock {
  &:before {
    content: $mrv-phonelink_lock;
  }
}
.mrv-phonelink_off {
  &:before {
    content: $mrv-phonelink_off;
  }
}
.mrv-phonelink_ring {
  &:before {
    content: $mrv-phonelink_ring;
  }
}
.mrv-phonelink_setup {
  &:before {
    content: $mrv-phonelink_setup;
  }
}
.mrv-photo {
  &:before {
    content: $mrv-photo;
  }
}
.mrv-photo_album {
  &:before {
    content: $mrv-photo_album;
  }
}
.mrv-photo_camera {
  &:before {
    content: $mrv-photo_camera;
  }
}
.mrv-photo_camera_back {
  &:before {
    content: $mrv-photo_camera_back;
  }
}
.mrv-photo_camera_front {
  &:before {
    content: $mrv-photo_camera_front;
  }
}
.mrv-photo_filter {
  &:before {
    content: $mrv-photo_filter;
  }
}
.mrv-photo_frame {
  &:before {
    content: $mrv-photo_frame;
  }
}
.mrv-photo_library {
  &:before {
    content: $mrv-photo_library;
  }
}
.mrv-photo_size_select_large {
  &:before {
    content: $mrv-photo_size_select_large;
  }
}
.mrv-photo_size_select_small {
  &:before {
    content: $mrv-photo_size_select_small;
  }
}
.mrv-php {
  &:before {
    content: $mrv-php;
  }
}
.mrv-piano {
  &:before {
    content: $mrv-piano;
  }
}
.mrv-piano_off {
  &:before {
    content: $mrv-piano_off;
  }
}
.mrv-picture_as_pdf {
  &:before {
    content: $mrv-picture_as_pdf;
  }
}
.mrv-picture_in_picture {
  &:before {
    content: $mrv-picture_in_picture;
  }
}
.mrv-picture_in_picture_alt {
  &:before {
    content: $mrv-picture_in_picture_alt;
  }
}
.mrv-pie_chart {
  &:before {
    content: $mrv-pie_chart;
  }
}
.mrv-pin {
  &:before {
    content: $mrv-pin;
  }
}
.mrv-pin_drop {
  &:before {
    content: $mrv-pin_drop;
  }
}
.mrv-pin_end {
  &:before {
    content: $mrv-pin_end;
  }
}
.mrv-pin_invoke {
  &:before {
    content: $mrv-pin_invoke;
  }
}
.mrv-pinch {
  &:before {
    content: $mrv-pinch;
  }
}
.mrv-pinch_zoom_in {
  &:before {
    content: $mrv-pinch_zoom_in;
  }
}
.mrv-pinch_zoom_out {
  &:before {
    content: $mrv-pinch_zoom_out;
  }
}
.mrv-pivot_table_chart {
  &:before {
    content: $mrv-pivot_table_chart;
  }
}
.mrv-place_item {
  &:before {
    content: $mrv-place_item;
  }
}
.mrv-plagiarism {
  &:before {
    content: $mrv-plagiarism;
  }
}
.mrv-play_arrow {
  &:before {
    content: $mrv-play_arrow;
  }
}
.mrv-play_circle {
  &:before {
    content: $mrv-play_circle;
  }
}
.mrv-play_disabled {
  &:before {
    content: $mrv-play_disabled;
  }
}
.mrv-play_for_work {
  &:before {
    content: $mrv-play_for_work;
  }
}
.mrv-play_lesson {
  &:before {
    content: $mrv-play_lesson;
  }
}
.mrv-play_pause {
  &:before {
    content: $mrv-play_pause;
  }
}
.mrv-play_services {
  &:before {
    content: $mrv-play_services;
  }
}
.mrv-playlist_add {
  &:before {
    content: $mrv-playlist_add;
  }
}
.mrv-playlist_add_check {
  &:before {
    content: $mrv-playlist_add_check;
  }
}
.mrv-playlist_add_check_circle {
  &:before {
    content: $mrv-playlist_add_check_circle;
  }
}
.mrv-playlist_add_circle {
  &:before {
    content: $mrv-playlist_add_circle;
  }
}
.mrv-playlist_play {
  &:before {
    content: $mrv-playlist_play;
  }
}
.mrv-playlist_remove {
  &:before {
    content: $mrv-playlist_remove;
  }
}
.mrv-plumbing {
  &:before {
    content: $mrv-plumbing;
  }
}
.mrv-podcasts {
  &:before {
    content: $mrv-podcasts;
  }
}
.mrv-podcasts_app {
  &:before {
    content: $mrv-podcasts_app;
  }
}
.mrv-point_of_sale {
  &:before {
    content: $mrv-point_of_sale;
  }
}
.mrv-policy {
  &:before {
    content: $mrv-policy;
  }
}
.mrv-polyline {
  &:before {
    content: $mrv-polyline;
  }
}
.mrv-polymer {
  &:before {
    content: $mrv-polymer;
  }
}
.mrv-pool {
  &:before {
    content: $mrv-pool;
  }
}
.mrv-portable_wifi_off {
  &:before {
    content: $mrv-portable_wifi_off;
  }
}
.mrv-post_add {
  &:before {
    content: $mrv-post_add;
  }
}
.mrv-power {
  &:before {
    content: $mrv-power;
  }
}
.mrv-power_input {
  &:before {
    content: $mrv-power_input;
  }
}
.mrv-power_off {
  &:before {
    content: $mrv-power_off;
  }
}
.mrv-power_settings_new {
  &:before {
    content: $mrv-power_settings_new;
  }
}
.mrv-precision_manufacturing {
  &:before {
    content: $mrv-precision_manufacturing;
  }
}
.mrv-pregnant_woman {
  &:before {
    content: $mrv-pregnant_woman;
  }
}
.mrv-present_to_all {
  &:before {
    content: $mrv-present_to_all;
  }
}
.mrv-preview {
  &:before {
    content: $mrv-preview;
  }
}
.mrv-price_change {
  &:before {
    content: $mrv-price_change;
  }
}
.mrv-price_check {
  &:before {
    content: $mrv-price_check;
  }
}
.mrv-print {
  &:before {
    content: $mrv-print;
  }
}
.mrv-print_disabled {
  &:before {
    content: $mrv-print_disabled;
  }
}
.mrv-priority {
  &:before {
    content: $mrv-priority;
  }
}
.mrv-priority_high {
  &:before {
    content: $mrv-priority_high;
  }
}
.mrv-privacy {
  &:before {
    content: $mrv-privacy;
  }
}
.mrv-privacy_tip {
  &:before {
    content: $mrv-privacy_tip;
  }
}
.mrv-private_connectivity {
  &:before {
    content: $mrv-private_connectivity;
  }
}
.mrv-production_quantity_limits {
  &:before {
    content: $mrv-production_quantity_limits;
  }
}
.mrv-propane {
  &:before {
    content: $mrv-propane;
  }
}
.mrv-propane_tank {
  &:before {
    content: $mrv-propane_tank;
  }
}
.mrv-psychology {
  &:before {
    content: $mrv-psychology;
  }
}
.mrv-public {
  &:before {
    content: $mrv-public;
  }
}
.mrv-public_off {
  &:before {
    content: $mrv-public_off;
  }
}
.mrv-publish {
  &:before {
    content: $mrv-publish;
  }
}
.mrv-published_with_changes {
  &:before {
    content: $mrv-published_with_changes;
  }
}
.mrv-punch_clock {
  &:before {
    content: $mrv-punch_clock;
  }
}
.mrv-qr_code {
  &:before {
    content: $mrv-qr_code;
  }
}
.mrv-qr_code_2 {
  &:before {
    content: $mrv-qr_code_2;
  }
}
.mrv-qr_code_scanner {
  &:before {
    content: $mrv-qr_code_scanner;
  }
}
.mrv-query_stats {
  &:before {
    content: $mrv-query_stats;
  }
}
.mrv-question_mark {
  &:before {
    content: $mrv-question_mark;
  }
}
.mrv-queue_music {
  &:before {
    content: $mrv-queue_music;
  }
}
.mrv-queue_play_next {
  &:before {
    content: $mrv-queue_play_next;
  }
}
.mrv-quick_phrases {
  &:before {
    content: $mrv-quick_phrases;
  }
}
.mrv-quickreply {
  &:before {
    content: $mrv-quickreply;
  }
}
.mrv-quiz {
  &:before {
    content: $mrv-quiz;
  }
}
.mrv-r_mobiledata {
  &:before {
    content: $mrv-r_mobiledata;
  }
}
.mrv-radar {
  &:before {
    content: $mrv-radar;
  }
}
.mrv-radio {
  &:before {
    content: $mrv-radio;
  }
}
.mrv-radio_button_checked {
  &:before {
    content: $mrv-radio_button_checked;
  }
}
.mrv-radio_button_unchecked {
  &:before {
    content: $mrv-radio_button_unchecked;
  }
}
.mrv-railway_alert {
  &:before {
    content: $mrv-railway_alert;
  }
}
.mrv-rainy {
  &:before {
    content: $mrv-rainy;
  }
}
.mrv-ramen_dining {
  &:before {
    content: $mrv-ramen_dining;
  }
}
.mrv-ramp_left {
  &:before {
    content: $mrv-ramp_left;
  }
}
.mrv-ramp_right {
  &:before {
    content: $mrv-ramp_right;
  }
}
.mrv-range_hood {
  &:before {
    content: $mrv-range_hood;
  }
}
.mrv-rate_review {
  &:before {
    content: $mrv-rate_review;
  }
}
.mrv-raw_off {
  &:before {
    content: $mrv-raw_off;
  }
}
.mrv-raw_on {
  &:before {
    content: $mrv-raw_on;
  }
}
.mrv-read_more {
  &:before {
    content: $mrv-read_more;
  }
}
.mrv-real_estate_agent {
  &:before {
    content: $mrv-real_estate_agent;
  }
}
.mrv-receipt {
  &:before {
    content: $mrv-receipt;
  }
}
.mrv-receipt_long {
  &:before {
    content: $mrv-receipt_long;
  }
}
.mrv-recent_actors {
  &:before {
    content: $mrv-recent_actors;
  }
}
.mrv-recommend {
  &:before {
    content: $mrv-recommend;
  }
}
.mrv-record_voice_over {
  &:before {
    content: $mrv-record_voice_over;
  }
}
.mrv-rectangle {
  &:before {
    content: $mrv-rectangle;
  }
}
.mrv-recycling {
  &:before {
    content: $mrv-recycling;
  }
}
.mrv-redeem {
  &:before {
    content: $mrv-redeem;
  }
}
.mrv-redo {
  &:before {
    content: $mrv-redo;
  }
}
.mrv-reduce_capacity {
  &:before {
    content: $mrv-reduce_capacity;
  }
}
.mrv-refresh {
  &:before {
    content: $mrv-refresh;
  }
}
.mrv-remember_me {
  &:before {
    content: $mrv-remember_me;
  }
}
.mrv-remote_gen {
  &:before {
    content: $mrv-remote_gen;
  }
}
.mrv-remove {
  &:before {
    content: $mrv-remove;
  }
}
.mrv-remove_done {
  &:before {
    content: $mrv-remove_done;
  }
}
.mrv-remove_from_queue {
  &:before {
    content: $mrv-remove_from_queue;
  }
}
.mrv-remove_moderator {
  &:before {
    content: $mrv-remove_moderator;
  }
}
.mrv-remove_road {
  &:before {
    content: $mrv-remove_road;
  }
}
.mrv-remove_shopping_cart {
  &:before {
    content: $mrv-remove_shopping_cart;
  }
}
.mrv-reorder {
  &:before {
    content: $mrv-reorder;
  }
}
.mrv-repeat {
  &:before {
    content: $mrv-repeat;
  }
}
.mrv-repeat_on {
  &:before {
    content: $mrv-repeat_on;
  }
}
.mrv-repeat_one {
  &:before {
    content: $mrv-repeat_one;
  }
}
.mrv-repeat_one_on {
  &:before {
    content: $mrv-repeat_one_on;
  }
}
.mrv-replay {
  &:before {
    content: $mrv-replay;
  }
}
.mrv-replay_5 {
  &:before {
    content: $mrv-replay_5;
  }
}
.mrv-replay_10 {
  &:before {
    content: $mrv-replay_10;
  }
}
.mrv-replay_30 {
  &:before {
    content: $mrv-replay_30;
  }
}
.mrv-replay_circle_filled {
  &:before {
    content: $mrv-replay_circle_filled;
  }
}
.mrv-reply {
  &:before {
    content: $mrv-reply;
  }
}
.mrv-reply_all {
  &:before {
    content: $mrv-reply_all;
  }
}
.mrv-report {
  &:before {
    content: $mrv-report;
  }
}
.mrv-report_off {
  &:before {
    content: $mrv-report_off;
  }
}
.mrv-request_page {
  &:before {
    content: $mrv-request_page;
  }
}
.mrv-request_quote {
  &:before {
    content: $mrv-request_quote;
  }
}
.mrv-reset_tv {
  &:before {
    content: $mrv-reset_tv;
  }
}
.mrv-restart_alt {
  &:before {
    content: $mrv-restart_alt;
  }
}
.mrv-restaurant {
  &:before {
    content: $mrv-restaurant;
  }
}
.mrv-restaurant_menu {
  &:before {
    content: $mrv-restaurant_menu;
  }
}
.mrv-restore_from_trash {
  &:before {
    content: $mrv-restore_from_trash;
  }
}
.mrv-restore_page {
  &:before {
    content: $mrv-restore_page;
  }
}
.mrv-retention {
  &:before {
    content: $mrv-retention;
  }
}
.mrv-reviews {
  &:before {
    content: $mrv-reviews;
  }
}
.mrv-rice_bowl {
  &:before {
    content: $mrv-rice_bowl;
  }
}
.mrv-ring_volume {
  &:before {
    content: $mrv-ring_volume;
  }
}
.mrv-rocket {
  &:before {
    content: $mrv-rocket;
  }
}
.mrv-rocket_launch {
  &:before {
    content: $mrv-rocket_launch;
  }
}
.mrv-roller_shades {
  &:before {
    content: $mrv-roller_shades;
  }
}
.mrv-roller_shades_closed {
  &:before {
    content: $mrv-roller_shades_closed;
  }
}
.mrv-roller_skating {
  &:before {
    content: $mrv-roller_skating;
  }
}
.mrv-roofing {
  &:before {
    content: $mrv-roofing;
  }
}
.mrv-room_preferences {
  &:before {
    content: $mrv-room_preferences;
  }
}
.mrv-room_service {
  &:before {
    content: $mrv-room_service;
  }
}
.mrv-rotate_90_degrees_ccw {
  &:before {
    content: $mrv-rotate_90_degrees_ccw;
  }
}
.mrv-rotate_90_degrees_cw {
  &:before {
    content: $mrv-rotate_90_degrees_cw;
  }
}
.mrv-rotate_left {
  &:before {
    content: $mrv-rotate_left;
  }
}
.mrv-rotate_right {
  &:before {
    content: $mrv-rotate_right;
  }
}
.mrv-roundabout_left {
  &:before {
    content: $mrv-roundabout_left;
  }
}
.mrv-roundabout_right {
  &:before {
    content: $mrv-roundabout_right;
  }
}
.mrv-rounded_corner {
  &:before {
    content: $mrv-rounded_corner;
  }
}
.mrv-route {
  &:before {
    content: $mrv-route;
  }
}
.mrv-router {
  &:before {
    content: $mrv-router;
  }
}
.mrv-routine {
  &:before {
    content: $mrv-routine;
  }
}
.mrv-rowing {
  &:before {
    content: $mrv-rowing;
  }
}
.mrv-rss_feed {
  &:before {
    content: $mrv-rss_feed;
  }
}
.mrv-rsvp {
  &:before {
    content: $mrv-rsvp;
  }
}
.mrv-rtt {
  &:before {
    content: $mrv-rtt;
  }
}
.mrv-rule {
  &:before {
    content: $mrv-rule;
  }
}
.mrv-rule_folder {
  &:before {
    content: $mrv-rule_folder;
  }
}
.mrv-run_circle {
  &:before {
    content: $mrv-run_circle;
  }
}
.mrv-running_with_errors {
  &:before {
    content: $mrv-running_with_errors;
  }
}
.mrv-rv_hookup {
  &:before {
    content: $mrv-rv_hookup;
  }
}
.mrv-safety_check {
  &:before {
    content: $mrv-safety_check;
  }
}
.mrv-safety_divider {
  &:before {
    content: $mrv-safety_divider;
  }
}
.mrv-sailing {
  &:before {
    content: $mrv-sailing;
  }
}
.mrv-sanitizer {
  &:before {
    content: $mrv-sanitizer;
  }
}
.mrv-satellite {
  &:before {
    content: $mrv-satellite;
  }
}
.mrv-satellite_alt {
  &:before {
    content: $mrv-satellite_alt;
  }
}
.mrv-save {
  &:before {
    content: $mrv-save;
  }
}
.mrv-save_as {
  &:before {
    content: $mrv-save_as;
  }
}
.mrv-saved_search {
  &:before {
    content: $mrv-saved_search;
  }
}
.mrv-savings {
  &:before {
    content: $mrv-savings;
  }
}
.mrv-scale {
  &:before {
    content: $mrv-scale;
  }
}
.mrv-scanner {
  &:before {
    content: $mrv-scanner;
  }
}
.mrv-scatter_plot {
  &:before {
    content: $mrv-scatter_plot;
  }
}
.mrv-scene {
  &:before {
    content: $mrv-scene;
  }
}
.mrv-schedule {
  &:before {
    content: $mrv-schedule;
  }
}
.mrv-schedule_send {
  &:before {
    content: $mrv-schedule_send;
  }
}
.mrv-schema {
  &:before {
    content: $mrv-schema;
  }
}
.mrv-school {
  &:before {
    content: $mrv-school;
  }
}
.mrv-science {
  &:before {
    content: $mrv-science;
  }
}
.mrv-score {
  &:before {
    content: $mrv-score;
  }
}
.mrv-scoreboard {
  &:before {
    content: $mrv-scoreboard;
  }
}
.mrv-screen_attention {
  &:before {
    content: $mrv-screen_attention;
  }
}
.mrv-screen_attention_off {
  &:before {
    content: $mrv-screen_attention_off;
  }
}
.mrv-screen_lock_landscape {
  &:before {
    content: $mrv-screen_lock_landscape;
  }
}
.mrv-screen_lock_portrait {
  &:before {
    content: $mrv-screen_lock_portrait;
  }
}
.mrv-screen_lock_rotation {
  &:before {
    content: $mrv-screen_lock_rotation;
  }
}
.mrv-screen_rotation {
  &:before {
    content: $mrv-screen_rotation;
  }
}
.mrv-screen_rotation_alt {
  &:before {
    content: $mrv-screen_rotation_alt;
  }
}
.mrv-screen_search_desktop {
  &:before {
    content: $mrv-screen_search_desktop;
  }
}
.mrv-screen_share {
  &:before {
    content: $mrv-screen_share;
  }
}
.mrv-screenshot {
  &:before {
    content: $mrv-screenshot;
  }
}
.mrv-screenshot_monitor {
  &:before {
    content: $mrv-screenshot_monitor;
  }
}
.mrv-scuba_diving {
  &:before {
    content: $mrv-scuba_diving;
  }
}
.mrv-sd {
  &:before {
    content: $mrv-sd;
  }
}
.mrv-sd_card {
  &:before {
    content: $mrv-sd_card;
  }
}
.mrv-sd_card_alert {
  &:before {
    content: $mrv-sd_card_alert;
  }
}
.mrv-search {
  &:before {
    content: $mrv-search; 
  }
}
.mrv-search_off {
  &:before {
    content: $mrv-search_off;
  }
}
.mrv-security {
  &:before {
    content: $mrv-security;
  }
}
.mrv-security_pro_monitoring {
  &:before {
    content: $mrv-security_pro_monitoring;
  }
}
.mrv-security_update_good {
  &:before {
    content: $mrv-security_update_good;
  }
}
.mrv-security_update_warning {
  &:before {
    content: $mrv-security_update_warning;
  }
}
.mrv-segment {
  &:before {
    content: $mrv-segment;
  }
}
.mrv-select_all {
  &:before {
    content: $mrv-select_all;
  }
}
.mrv-select_check_box {
  &:before {
    content: $mrv-select_check_box;
  }
}
.mrv-self_improvement {
  &:before {
    content: $mrv-self_improvement;
  }
}
.mrv-sell {
  &:before {
    content: $mrv-sell;
  }
}
.mrv-send {
  &:before {
    content: $mrv-send;
  }
}
.mrv-send_and_archive {
  &:before {
    content: $mrv-send_and_archive;
  }
}
.mrv-send_time_extension {
  &:before {
    content: $mrv-send_time_extension;
  }
}
.mrv-send_to_mobile {
  &:before {
    content: $mrv-send_to_mobile;
  }
}
.mrv-sensor_door {
  &:before {
    content: $mrv-sensor_door;
  }
}
.mrv-sensor_occupied {
  &:before {
    content: $mrv-sensor_occupied;
  }
}
.mrv-sensor_window {
  &:before {
    content: $mrv-sensor_window;
  }
}
.mrv-sensors {
  &:before {
    content: $mrv-sensors;
  }
}
.mrv-sensors_off {
  &:before {
    content: $mrv-sensors_off;
  }
}
.mrv-sentiment_dissatisfied {
  &:before {
    content: $mrv-sentiment_dissatisfied;
  }
}
.mrv-sentiment_extremely_dissatisfied {
  &:before {
    content: $mrv-sentiment_extremely_dissatisfied;
  }
}
.mrv-sentiment_neutral {
  &:before {
    content: $mrv-sentiment_neutral;
  }
}
.mrv-sentiment_satisfied {
  &:before {
    content: $mrv-sentiment_satisfied;
  }
}
.mrv-sentiment_very_dissatisfied {
  &:before {
    content: $mrv-sentiment_very_dissatisfied;
  }
}
.mrv-sentiment_very_satisfied {
  &:before {
    content: $mrv-sentiment_very_satisfied; 
  }
}
.mrv-service_directory {
  &:before {
    content: $mrv-service_directory;
  }
}
.mrv-set_meal {
  &:before {
    content: $mrv-set_meal;
  }
}
.mrv-settings {
  &:before {
    content: $mrv-settings;
  }
}
.mrv-settings_accessibility {
  &:before {
    content: $mrv-settings_accessibility;
  }
}
.mrv-settings_applications {
  &:before {
    content: $mrv-settings_applications;
  }
}
.mrv-settings_backup_restore {
  &:before {
    content: $mrv-settings_backup_restore;
  }
}
.mrv-settings_bluetooth {
  &:before {
    content: $mrv-settings_bluetooth;
  }
}
.mrv-settings_brightness {
  &:before {
    content: $mrv-settings_brightness;
  }
}
.mrv-settings_cell {
  &:before {
    content: $mrv-settings_cell;
  }
}
.mrv-settings_ethernet {
  &:before {
    content: $mrv-settings_ethernet;
  }
}
.mrv-settings_input_antenna {
  &:before {
    content: $mrv-settings_input_antenna;
  }
}
.mrv-settings_input_component {
  &:before {
    content: $mrv-settings_input_component;
  }
}
.mrv-settings_input_hdmi {
  &:before {
    content: $mrv-settings_input_hdmi;
  }
}
.mrv-settings_input_svideo {
  &:before {
    content: $mrv-settings_input_svideo;
  }
}
.mrv-settings_overscan {
  &:before {
    content: $mrv-settings_overscan;
  }
}
.mrv-settings_phone {
  &:before {
    content: $mrv-settings_phone;
  }
}
.mrv-settings_power {
  &:before {
    content: $mrv-settings_power;
  }
}
.mrv-settings_remote {
  &:before {
    content: $mrv-settings_remote;
  }
}
.mrv-settings_suggest {
  &:before {
    content: $mrv-settings_suggest;
  }
}
.mrv-settings_system_daydream {
  &:before {
    content: $mrv-settings_system_daydream;
  }
}
.mrv-settings_voice {
  &:before {
    content: $mrv-settings_voice;
  }
}
.mrv-settop_component {
  &:before {
    content: $mrv-settop_component;
  }
}
.mrv-severe_cold {
  &:before {
    content: $mrv-severe_cold;
  }
}
.mrv-share {
  &:before {
    content: $mrv-share;
  }
}
.mrv-share_location {
  &:before {
    content: $mrv-share_location;
  }
}
.mrv-sheets {
  &:before {
    content: $mrv-sheets;
  }
}
.mrv-sheets_add_on {
  &:before {
    content: $mrv-sheets_add_on;
  }
}
.mrv-sheets_apps_script {
  &:before {
    content: $mrv-sheets_apps_script;
  }
}
.mrv-shield {
  &:before {
    content: $mrv-shield;
  }
}
.mrv-shield_moon {
  &:before {
    content: $mrv-shield_moon;
  }
}
.mrv-shop {
  &:before {
    content: $mrv-shop;
  }
}
.mrv-shop_two {
  &:before {
    content: $mrv-shop_two;
  }
}
.mrv-shopping_bag {
  &:before {
    content: $mrv-shopping_bag;
  }
}
.mrv-shopping_basket {
  &:before {
    content: $mrv-shopping_basket;
  }
}
.mrv-shopping_cart {
  &:before {
    content: $mrv-shopping_cart;
  }
}
.mrv-shopping_cart_checkout {
  &:before {
    content: $mrv-shopping_cart_checkout;
  }
}
.mrv-short_text {
  &:before {
    content: $mrv-short_text;
  }
}
.mrv-show_chart {
  &:before {
    content: $mrv-show_chart;
  }
}
.mrv-shower {
  &:before {
    content: $mrv-shower;
  }
}
.mrv-shuffle {
  &:before {
    content: $mrv-shuffle;
  }
}
.mrv-shuffle_on {
  &:before {
    content: $mrv-shuffle_on;
  }
}
.mrv-shutter_speed {
  &:before {
    content: $mrv-shutter_speed;
  }
}
.mrv-sick {
  &:before {
    content: $mrv-sick;
  }
}
.mrv-sign_language {
  &:before {
    content: $mrv-sign_language;
  }
}
.mrv-signal_cellular_0_bar {
  &:before {
    content: $mrv-signal_cellular_0_bar;
  }
}
.mrv-signal_cellular_1_bar {
  &:before {
    content: $mrv-signal_cellular_1_bar;
  }
}
.mrv-signal_cellular_2_bar {
  &:before {
    content: $mrv-signal_cellular_2_bar;
  }
}
.mrv-signal_cellular_3_bar {
  &:before {
    content: $mrv-signal_cellular_3_bar;
  }
}
.mrv-signal_cellular_4_bar {
  &:before {
    content: $mrv-signal_cellular_4_bar;
  }
}
.mrv-signal_cellular_alt {
  &:before {
    content: $mrv-signal_cellular_alt;
  }
}
.mrv-signal_cellular_alt_1_bar {
  &:before {
    content: $mrv-signal_cellular_alt_1_bar;
  }
}
.mrv-signal_cellular_alt_2_bar {
  &:before {
    content: $mrv-signal_cellular_alt_2_bar;
  }
}
.mrv-signal_cellular_connected_no_internet_0_bar {
  &:before {
    content: $mrv-signal_cellular_connected_no_internet_0_bar;
  }
}
.mrv-signal_cellular_connected_no_internet_4_bar {
  &:before {
    content: $mrv-signal_cellular_connected_no_internet_4_bar;
  }
}
.mrv-signal_cellular_nodata {
  &:before {
    content: $mrv-signal_cellular_nodata;
  }
}
.mrv-signal_cellular_null {
  &:before {
    content: $mrv-signal_cellular_null;
  }
}
.mrv-signal_cellular_off {
  &:before {
    content: $mrv-signal_cellular_off;
  }
}
.mrv-signal_disconnected {
  &:before {
    content: $mrv-signal_disconnected;
  }
}
.mrv-signal_wifi_0_bar {
  &:before {
    content: $mrv-signal_wifi_0_bar;
  }
}
.mrv-signal_wifi_4_bar {
  &:before {
    content: $mrv-signal_wifi_4_bar;
  }
}
.mrv-signal_wifi_bad {
  &:before {
    content: $mrv-signal_wifi_bad;
  }
}
.mrv-signal_wifi_off {
  &:before {
    content: $mrv-signal_wifi_off;
  }
}
.mrv-signal_wifi_statusbar_not_connected {
  &:before {
    content: $mrv-signal_wifi_statusbar_not_connected;
  }
}
.mrv-signal_wifi_statusbar_null {
  &:before {
    content: $mrv-signal_wifi_statusbar_null;
  }
}
.mrv-signpost {
  &:before {
    content: $mrv-signpost;
  }
}
.mrv-sim_card {
  &:before {
    content: $mrv-sim_card;
  }
}
.mrv-sim_card_download {
  &:before {
    content: $mrv-sim_card_download;
  }
}
.mrv-single_bed {
  &:before {
    content: $mrv-single_bed;
  }
}
.mrv-sip {
  &:before {
    content: $mrv-sip;
  }
}
.mrv-skateboarding {
  &:before {
    content: $mrv-skateboarding;
  }
}
.mrv-skip_next {
  &:before {
    content: $mrv-skip_next;
  }
}
.mrv-skip_previous {
  &:before {
    content: $mrv-skip_previous;
  }
}
.mrv-sledding {
  &:before {
    content: $mrv-sledding;
  }
}
.mrv-slides {
  &:before {
    content: $mrv-slides;
  }
}
.mrv-slides_add_on {
  &:before {
    content: $mrv-slides_add_on;
  }
}
.mrv-slides_apps_script {
  &:before {
    content: $mrv-slides_apps_script;
  }
}
.mrv-slideshow {
  &:before {
    content: $mrv-slideshow;
  }
}
.mrv-slow_motion_video {
  &:before {
    content: $mrv-slow_motion_video;
  }
}
.mrv-smart_button {
  &:before {
    content: $mrv-smart_button;
  }
}
.mrv-smart_display {
  &:before {
    content: $mrv-smart_display;
  }
}
.mrv-smart_outlet {
  &:before {
    content: $mrv-smart_outlet;
  }
}
.mrv-smart_screen {
  &:before {
    content: $mrv-smart_screen;
  }
}
.mrv-smart_toy {
  &:before {
    content: $mrv-smart_toy;
  }
}
.mrv-smartphone {
  &:before {
    content: $mrv-smartphone;
  }
}
.mrv-smoke_free {
  &:before {
    content: $mrv-smoke_free;
  }
}
.mrv-smoking_rooms {
  &:before {
    content: $mrv-smoking_rooms;
  }
}
.mrv-sms {
  &:before {
    content: $mrv-sms;
  }
}
.mrv-sms_failed {
  &:before {
    content: $mrv-sms_failed;
  }
}
.mrv-snippet_folder {
  &:before {
    content: $mrv-snippet_folder;
  }
}
.mrv-snooze {
  &:before {
    content: $mrv-snooze;
  }
}
.mrv-snowboarding {
  &:before {
    content: $mrv-snowboarding;
  }
}
.mrv-snowing {
  &:before {
    content: $mrv-snowing;
  }
}
.mrv-snowmobile {
  &:before {
    content: $mrv-snowmobile;
  }
}
.mrv-snowshoeing {
  &:before {
    content: $mrv-snowshoeing;
  }
}
.mrv-soap {
  &:before {
    content: $mrv-soap;
  }
}
.mrv-social_distance {
  &:before {
    content: $mrv-social_distance;
  }
}
.mrv-solar_power {
  &:before {
    content: $mrv-solar_power;
  }
}
.mrv-sort {
  &:before {
    content: $mrv-sort;
  }
}
.mrv-sort_by_alpha {
  &:before {
    content: $mrv-sort_by_alpha;
  }
}
.mrv-sos {
  &:before {
    content: $mrv-sos;
  }
}
.mrv-sound_detection_dog_barking {
  &:before {
    content: $mrv-sound_detection_dog_barking;
  }
}
.mrv-sound_detection_glass_break {
  &:before {
    content: $mrv-sound_detection_glass_break;
  }
}
.mrv-sound_detection_loud_sound {
  &:before {
    content: $mrv-sound_detection_loud_sound;
  }
}
.mrv-sound_sensing {
  &:before {
    content: $mrv-sound_sensing;
  }
}
.mrv-soup_kitchen {
  &:before {
    content: $mrv-soup_kitchen;
  }
}
.mrv-south {
  &:before {
    content: $mrv-south;
  }
}
.mrv-south_america {
  &:before {
    content: $mrv-south_america;
  }
}
.mrv-south_east {
  &:before {
    content: $mrv-south_east;
  }
}
.mrv-south_west {
  &:before {
    content: $mrv-south_west;
  }
}
.mrv-spa {
  &:before {
    content: $mrv-spa;
  }
}
.mrv-space_bar {
  &:before {
    content: $mrv-space_bar;
  }
}
.mrv-space_dashboard {
  &:before {
    content: $mrv-space_dashboard;
  }
}
.mrv-spatial_audio {
  &:before {
    content: $mrv-spatial_audio;
  }
}
.mrv-spatial_audio_off {
  &:before {
    content: $mrv-spatial_audio_off;
  }
}
.mrv-spatial_tracking {
  &:before {
    content: $mrv-spatial_tracking;
  }
}
.mrv-speaker {
  &:before {
    content: $mrv-speaker;
  }
}
.mrv-speaker_group {
  &:before {
    content: $mrv-speaker_group;
  }
}
.mrv-speaker_notes {
  &:before {
    content: $mrv-speaker_notes;
  }
}
.mrv-speaker_notes_off {
  &:before {
    content: $mrv-speaker_notes_off;
  }
}
.mrv-speaker_phone {
  &:before {
    content: $mrv-speaker_phone;
  }
}
.mrv-speed {
  &:before {
    content: $mrv-speed;
  }
}
.mrv-spellcheck {
  &:before {
    content: $mrv-spellcheck;
  }
}
.mrv-splitscreen {
  &:before {
    content: $mrv-splitscreen;
  }
}
.mrv-spoke {
  &:before {
    content: $mrv-spoke;
  }
}
.mrv-sports {
  &:before {
    content: $mrv-sports;
  }
}
.mrv-sports_bar {
  &:before {
    content: $mrv-sports_bar;
  }
}
.mrv-sports_baseball {
  &:before {
    content: $mrv-sports_baseball;
  }
}
.mrv-sports_basketball {
  &:before {
    content: $mrv-sports_basketball;
  }
}
.mrv-sports_cricket {
  &:before {
    content: $mrv-sports_cricket;
  }
}
.mrv-sports_esports {
  &:before {
    content: $mrv-sports_esports;
  }
}
.mrv-sports_football {
  &:before {
    content: $mrv-sports_football;
  }
}
.mrv-sports_golf {
  &:before {
    content: $mrv-sports_golf;
  }
}
.mrv-sports_gymnastics {
  &:before {
    content: $mrv-sports_gymnastics;
  }
}
.mrv-sports_handball {
  &:before {
    content: $mrv-sports_handball;
  }
}
.mrv-sports_hockey {
  &:before {
    content: $mrv-sports_hockey;
  }
}
.mrv-sports_kabaddi {
  &:before {
    content: $mrv-sports_kabaddi;
  }
}
.mrv-sports_martial_arts {
  &:before {
    content: $mrv-sports_martial_arts;
  }
}
.mrv-sports_mma {
  &:before {
    content: $mrv-sports_mma;
  }
}
.mrv-sports_motorsports {
  &:before {
    content: $mrv-sports_motorsports;
  }
}
.mrv-sports_rugby {
  &:before {
    content: $mrv-sports_rugby;
  }
}
.mrv-sports_score {
  &:before {
    content: $mrv-sports_score;
  }
}
.mrv-sports_soccer {
  &:before {
    content: $mrv-sports_soccer;
  }
}
.mrv-sports_tennis {
  &:before {
    content: $mrv-sports_tennis;
  }
}
.mrv-sports_volleyball {
  &:before {
    content: $mrv-sports_volleyball;
  }
}
.mrv-sprinkler {
  &:before {
    content: $mrv-sprinkler;
  }
}
.mrv-square {
  &:before {
    content: $mrv-square;
  }
}
.mrv-square_foot {
  &:before {
    content: $mrv-square_foot;
  }
}
.mrv-ssid_chart {
  &:before {
    content: $mrv-ssid_chart;
  }
}
.mrv-stabilization {
  &:before {
    content: $mrv-stabilization;
  }
}
.mrv-stabilization_action {
  &:before {
    content: $mrv-stabilization_action;
  }
}
.mrv-stabilization_lock {
  &:before {
    content: $mrv-stabilization_lock;
  }
}
.mrv-stabilization_pan {
  &:before {
    content: $mrv-stabilization_pan;
  }
}
.mrv-stacked_bar_chart {
  &:before {
    content: $mrv-stacked_bar_chart;
  }
}
.mrv-stacked_line_chart {
  &:before {
    content: $mrv-stacked_line_chart;
  }
}
.mrv-stadia_controller {
  &:before {
    content: $mrv-stadia_controller;
  }
}
.mrv-stadium {
  &:before {
    content: $mrv-stadium;
  }
}
.mrv-stairs {
  &:before {
    content: $mrv-stairs;
  }
}
.mrv-star {
  &:before {
    content: $mrv-star;
  }
}
.mrv-star_half {
  &:before {
    content: $mrv-star_half;
  }
}
.mrv-star_outline {
  &:before {
    content: $mrv-star_outline;
  }
}
.mrv-stars {
  &:before {
    content: $mrv-stars;
  }
}
.mrv-start {
  &:before {
    content: $mrv-start;
  }
}
.mrv-stateful {
  &:before {
    content: $mrv-stateful;
  }
}
.mrv-stay_current_landscape {
  &:before {
    content: $mrv-stay_current_landscape;
  }
}
.mrv-stay_current_portrait {
  &:before {
    content: $mrv-stay_current_portrait;
  }
}
.mrv-stay_primary_landscape {
  &:before {
    content: $mrv-stay_primary_landscape;
  }
}
.mrv-stay_primary_portrait {
  &:before {
    content: $mrv-stay_primary_portrait;
  }
}
.mrv-sticky_note_2 {
  &:before {
    content: $mrv-sticky_note_2;
  }
}
.mrv-stop {
  &:before {
    content: $mrv-stop;
  }
}
.mrv-stop_circle {
  &:before {
    content: $mrv-stop_circle;
  }
}
.mrv-stop_screen_share {
  &:before {
    content: $mrv-stop_screen_share;
  }
}
.mrv-storage {
  &:before {
    content: $mrv-storage;
  }
}
.mrv-store {
  &:before {
    content: $mrv-store;
  }
}
.mrv-storefront {
  &:before {
    content: $mrv-storefront;
  }
}
.mrv-storm {
  &:before {
    content: $mrv-storm;
  }
}
.mrv-straight {
  &:before {
    content: $mrv-straight;
  }
}
.mrv-straighten {
  &:before {
    content: $mrv-straighten;
  }
}
.mrv-stream {
  &:before {
    content: $mrv-stream;
  }
}
.mrv-streetview {
  &:before {
    content: $mrv-streetview;
  }
}
.mrv-strikethrough_s {
  &:before {
    content: $mrv-strikethrough_s;
  }
}
.mrv-stroller {
  &:before {
    content: $mrv-stroller;
  }
}
.mrv-style {
  &:before {
    content: $mrv-style;
  }
}
.mrv-styler {
  &:before {
    content: $mrv-styler;
  }
}
.mrv-subdirectory_arrow_left {
  &:before {
    content: $mrv-subdirectory_arrow_left;
  }
}
.mrv-subdirectory_arrow_right {
  &:before {
    content: $mrv-subdirectory_arrow_right;
  }
}
.mrv-subject {
  &:before {
    content: $mrv-subject;
  }
}
.mrv-subscript {
  &:before {
    content: $mrv-subscript;
  }
}
.mrv-subscriptions {
  &:before {
    content: $mrv-subscriptions;
  }
}
.mrv-subtitles {
  &:before {
    content: $mrv-subtitles;
  }
}
.mrv-subtitles_off {
  &:before {
    content: $mrv-subtitles_off;
  }
}
.mrv-subway {
  &:before {
    content: $mrv-subway;
  }
}
.mrv-summarize {
  &:before {
    content: $mrv-summarize;
  }
}
.mrv-sunny {
  &:before {
    content: $mrv-sunny;
  }
}
.mrv-sunny_snowing {
  &:before {
    content: $mrv-sunny_snowing;
  }
}
.mrv-superscript {
  &:before {
    content: $mrv-superscript;
  }
}
.mrv-supervised_user_circle {
  &:before {
    content: $mrv-supervised_user_circle;
  }
}
.mrv-supervisor_account {
  &:before {
    content: $mrv-supervisor_account;
  }
}
.mrv-support {
  &:before {
    content: $mrv-support;
  }
}
.mrv-support_agent {
  &:before {
    content: $mrv-support_agent;
  }
}
.mrv-surfing {
  &:before {
    content: $mrv-surfing;
  }
}
.mrv-surround_sound {
  &:before {
    content: $mrv-surround_sound;
  }
}
.mrv-swap_calls {
  &:before {
    content: $mrv-swap_calls;
  }
}
.mrv-swap_horiz {
  &:before {
    content: $mrv-swap_horiz;
  }
}
.mrv-swap_horizontal_circle {
  &:before {
    content: $mrv-swap_horizontal_circle;
  }
}
.mrv-swap_vert {
  &:before {
    content: $mrv-swap_vert;
  }
}
.mrv-swap_vertical_circle {
  &:before {
    content: $mrv-swap_vertical_circle;
  }
}
.mrv-swipe {
  &:before {
    content: $mrv-swipe;
  }
}
.mrv-swipe_down {
  &:before {
    content: $mrv-swipe_down;
  }
}
.mrv-swipe_down_alt {
  &:before {
    content: $mrv-swipe_down_alt;
  }
}
.mrv-swipe_left {
  &:before {
    content: $mrv-swipe_left;
  }
}
.mrv-swipe_left_alt {
  &:before {
    content: $mrv-swipe_left_alt;
  }
}
.mrv-swipe_right {
  &:before {
    content: $mrv-swipe_right;
  }
}
.mrv-swipe_right_alt {
  &:before {
    content: $mrv-swipe_right_alt;
  }
}
.mrv-swipe_up {
  &:before {
    content: $mrv-swipe_up;
  }
}
.mrv-swipe_up_alt {
  &:before {
    content: $mrv-swipe_up_alt;
  }
}
.mrv-swipe_vertical {
  &:before {
    content: $mrv-swipe_vertical;
  }
}
.mrv-switch {
  &:before {
    content: $mrv-switch;
  }
}
.mrv-switch_access_shortcut {
  &:before {
    content: $mrv-switch_access_shortcut;
  }
}
.mrv-switch_access_shortcut_add {
  &:before {
    content: $mrv-switch_access_shortcut_add;
  }
}
.mrv-switch_account {
  &:before {
    content: $mrv-switch_account;
  }
}
.mrv-switch_camera {
  &:before {
    content: $mrv-switch_camera;
  }
}
.mrv-switch_left {
  &:before {
    content: $mrv-switch_left;
  }
}
.mrv-switch_right {
  &:before {
    content: $mrv-switch_right;
  }
}
.mrv-switch_video {
  &:before {
    content: $mrv-switch_video;
  }
}
.mrv-synagogue {
  &:before {
    content: $mrv-synagogue;
  }
}
.mrv-sync {
  &:before {
    content: $mrv-sync;
  }
}
.mrv-sync_alt {
  &:before {
    content: $mrv-sync_alt;
  }
}
.mrv-sync_disabled {
  &:before {
    content: $mrv-sync_disabled;
  }
}
.mrv-sync_lock {
  &:before {
    content: $mrv-sync_lock;
  }
}
.mrv-sync_problem {
  &:before {
    content: $mrv-sync_problem;
  }
}
.mrv-system_update {
  &:before {
    content: $mrv-system_update;
  }
}
.mrv-system_update_alt {
  &:before {
    content: $mrv-system_update_alt;
  }
}
.mrv-tab {
  &:before {
    content: $mrv-tab;
  }
}
.mrv-tab_unselected {
  &:before {
    content: $mrv-tab_unselected;
  }
}
.mrv-table {
  &:before {
    content: $mrv-table;
  }
}
.mrv-table_bar {
  &:before {
    content: $mrv-table_bar;
  }
}
.mrv-table_chart {
  &:before {
    content: $mrv-table_chart;
  }
}
.mrv-table_restaurant {
  &:before {
    content: $mrv-table_restaurant;
  }
}
.mrv-table_rows {
  &:before {
    content: $mrv-table_rows;
  }
}
.mrv-table_view {
  &:before {
    content: $mrv-table_view;
  }
}
.mrv-tablet {
  &:before {
    content: $mrv-tablet;
  }
}
.mrv-tablet_android {
  &:before {
    content: $mrv-tablet_android;
  }
}
.mrv-tablet_mac {
  &:before {
    content: $mrv-tablet_mac;
  }
}
.mrv-tag {
  &:before {
    content: $mrv-tag;
  }
}
.mrv-takeout_dining {
  &:before {
    content: $mrv-takeout_dining;
  }
}
.mrv-tamper_detection_off {
  &:before {
    content: $mrv-tamper_detection_off;
  }
}
.mrv-tap_and_play {
  &:before {
    content: $mrv-tap_and_play;
  }
}
.mrv-tapas {
  &:before {
    content: $mrv-tapas;
  }
}
.mrv-task {
  &:before {
    content: $mrv-task;
  }
}
.mrv-task_alt {
  &:before {
    content: $mrv-task_alt;
  }
}
.mrv-taxi_alert {
  &:before {
    content: $mrv-taxi_alert;
  }
}
.mrv-team_dashboard {
  &:before {
    content: $mrv-team_dashboard;
  }
}
.mrv-temple_buddhist {
  &:before {
    content: $mrv-temple_buddhist;
  }
}
.mrv-temple_hindu {
  &:before {
    content: $mrv-temple_hindu;
  }
}
.mrv-tenancy {
  &:before {
    content: $mrv-tenancy;
  }
}
.mrv-terminal {
  &:before {
    content: $mrv-terminal;
  }
}
.mrv-text_decrease {
  &:before {
    content: $mrv-text_decrease;
  }
}
.mrv-text_fields {
  &:before {
    content: $mrv-text_fields;
  }
}
.mrv-text_format {
  &:before {
    content: $mrv-text_format;
  }
}
.mrv-text_increase {
  &:before {
    content: $mrv-text_increase;
  }
}
.mrv-text_rotate_up {
  &:before {
    content: $mrv-text_rotate_up;
  }
}
.mrv-text_rotate_vertical {
  &:before {
    content: $mrv-text_rotate_vertical;
  }
}
.mrv-text_rotation_angledown {
  &:before {
    content: $mrv-text_rotation_angledown;
  }
}
.mrv-text_rotation_angleup {
  &:before {
    content: $mrv-text_rotation_angleup;
  }
}
.mrv-text_rotation_down {
  &:before {
    content: $mrv-text_rotation_down;
  }
}
.mrv-text_rotation_none {
  &:before {
    content: $mrv-text_rotation_none;
  }
}
.mrv-text_snippet {
  &:before {
    content: $mrv-text_snippet;
  }
}
.mrv-text_to_speech {
  &:before {
    content: $mrv-text_to_speech;
  }
}
.mrv-texture {
  &:before {
    content: $mrv-texture;
  }
}
.mrv-theater_comedy {
  &:before {
    content: $mrv-theater_comedy;
  }
}
.mrv-theaters {
  &:before {
    content: $mrv-theaters;
  }
}
.mrv-thermometer {
  &:before {
    content: $mrv-thermometer;
  }
}
.mrv-thermostat {
  &:before {
    content: $mrv-thermostat;
  }
}
.mrv-thermostat_auto {
  &:before {
    content: $mrv-thermostat_auto;
  }
}
.mrv-thermostat_carbon {
  &:before {
    content: $mrv-thermostat_carbon;
  }
}
.mrv-threat_intelligence {
  &:before {
    content: $mrv-threat_intelligence;
  }
}
.mrv-thumb_down {
  &:before {
    content: $mrv-thumb_down;
  }
}
.mrv-thumb_down_off {
  &:before {
    content: $mrv-thumb_down_off;
  }
}
.mrv-thumb_up {
  &:before {
    content: $mrv-thumb_up;
  }
}
.mrv-thumb_up_off {
  &:before {
    content: $mrv-thumb_up_off;
  }
}
.mrv-thumbs_up_down {
  &:before {
    content: $mrv-thumbs_up_down;
  }
}
.mrv-thumbs_up_down_off {
  &:before {
    content: $mrv-thumbs_up_down_off;
  }
}
.mrv-thunderstorm {
  &:before {
    content: $mrv-thunderstorm;
  }
}
.mrv-time_auto {
  &:before {
    content: $mrv-time_auto;
  }
}
.mrv-timelapse {
  &:before {
    content: $mrv-timelapse;
  }
}
.mrv-timeline {
  &:before {
    content: $mrv-timeline;
  }
}
.mrv-timer {
  &:before {
    content: $mrv-timer;
  }
}
.mrv-timer_3 {
  &:before {
    content: $mrv-timer_3;
  }
}
.mrv-timer_3_alt_1 {
  &:before {
    content: $mrv-timer_3_alt_1;
  }
}
.mrv-timer_3_select {
  &:before {
    content: $mrv-timer_3_select;
  }
}
.mrv-timer_10 {
  &:before {
    content: $mrv-timer_10;
  }
}
.mrv-timer_10_alt_1 {
  &:before {
    content: $mrv-timer_10_alt_1;
  }
}
.mrv-timer_10_select {
  &:before {
    content: $mrv-timer_10_select;
  }
}
.mrv-timer_off {
  &:before {
    content: $mrv-timer_off;
  }
}
.mrv-tips_and_updates {
  &:before {
    content: $mrv-tips_and_updates;
  }
}
.mrv-tire_repair {
  &:before {
    content: $mrv-tire_repair;
  }
}
.mrv-title {
  &:before {
    content: $mrv-title;
  }
}
.mrv-toc {
  &:before {
    content: $mrv-toc;
  }
}
.mrv-today {
  &:before {
    content: $mrv-today;
  }
}
.mrv-toggle_off {
  &:before {
    content: $mrv-toggle_off;
  }
}
.mrv-toggle_on {
  &:before {
    content: $mrv-toggle_on;
  }
}
.mrv-token {
  &:before {
    content: $mrv-token;
  }
}
.mrv-toll {
  &:before {
    content: $mrv-toll;
  }
}
.mrv-tonality {
  &:before {
    content: $mrv-tonality;
  }
}
.mrv-topic {
  &:before {
    content: $mrv-topic;
  }
}
.mrv-tornado {
  &:before {
    content: $mrv-tornado;
  }
}
.mrv-touch_app {
  &:before {
    content: $mrv-touch_app;
  }
}
.mrv-tour {
  &:before {
    content: $mrv-tour;
  }
}
.mrv-toys {
  &:before {
    content: $mrv-toys;
  }
}
.mrv-track_changes {
  &:before {
    content: $mrv-track_changes;
  }
}
.mrv-traffic {
  &:before {
    content: $mrv-traffic;
  }
}
.mrv-trail_length {
  &:before {
    content: $mrv-trail_length;
  }
}
.mrv-trail_length_medium {
  &:before {
    content: $mrv-trail_length_medium;
  }
}
.mrv-trail_length_short {
  &:before {
    content: $mrv-trail_length_short;
  }
}
.mrv-train {
  &:before {
    content: $mrv-train;
  }
}
.mrv-tram {
  &:before {
    content: $mrv-tram;
  }
}
.mrv-transfer_within_a_station {
  &:before {
    content: $mrv-transfer_within_a_station;
  }
}
.mrv-transform {
  &:before {
    content: $mrv-transform;
  }
}
.mrv-transgender {
  &:before {
    content: $mrv-transgender;
  }
}
.mrv-transit_enterexit {
  &:before {
    content: $mrv-transit_enterexit;
  }
}
.mrv-translate {
  &:before {
    content: $mrv-translate;
  }
}
.mrv-transportation {
  &:before {
    content: $mrv-transportation;
  }
}
.mrv-travel_explore {
  &:before {
    content: $mrv-travel_explore;
  }
}
.mrv-trending_down {
  &:before {
    content: $mrv-trending_down;
  }
}
.mrv-trending_flat {
  &:before {
    content: $mrv-trending_flat;
  }
}
.mrv-trending_up {
  &:before {
    content: $mrv-trending_up;
  }
}
.mrv-trip_origin {
  &:before {
    content: $mrv-trip_origin;
  }
}
.mrv-tsunami {
  &:before {
    content: $mrv-tsunami;
  }
}
.mrv-tty {
  &:before {
    content: $mrv-tty;
  }
}
.mrv-tune {
  &:before {
    content: $mrv-tune;
  }
}
.mrv-turn_left {
  &:before {
    content: $mrv-turn_left;
  }
}
.mrv-turn_right {
  &:before {
    content: $mrv-turn_right;
  }
}
.mrv-turn_sharp_left {
  &:before {
    content: $mrv-turn_sharp_left;
  }
}
.mrv-turn_sharp_right {
  &:before {
    content: $mrv-turn_sharp_right;
  }
}
.mrv-turn_slight_left {
  &:before {
    content: $mrv-turn_slight_left;
  }
}
.mrv-turn_slight_right {
  &:before {
    content: $mrv-turn_slight_right;
  }
}
.mrv-tv {
  &:before {
    content: $mrv-tv;
  }
}
.mrv-tv_gen {
  &:before {
    content: $mrv-tv_gen;
  }
}
.mrv-tv_off {
  &:before {
    content: $mrv-tv_off;
  }
}
.mrv-tv_with_assistant {
  &:before {
    content: $mrv-tv_with_assistant;
  }
}
.mrv-two_wheeler {
  &:before {
    content: $mrv-two_wheeler;
  }
}
.mrv-type_specimen {
  &:before {
    content: $mrv-type_specimen;
  }
}
.mrv-u_turn_left {
  &:before {
    content: $mrv-u_turn_left;
  }
}
.mrv-u_turn_right {
  &:before {
    content: $mrv-u_turn_right;
  }
}
.mrv-umbrella {
  &:before {
    content: $mrv-umbrella;
  }
}
.mrv-unarchive {
  &:before {
    content: $mrv-unarchive;
  }
}
.mrv-unblock_flipped {
  &:before {
    content: $mrv-unblock_flipped;
  }
}
.mrv-undo {
  &:before {
    content: $mrv-undo;
  }
}
.mrv-unfold_less {
  &:before {
    content: $mrv-unfold_less;
  }
}
.mrv-unfold_more {
  &:before {
    content: $mrv-unfold_more;
  }
}
.mrv-unpin {
  &:before {
    content: $mrv-unpin;
  }
}
.mrv-unpublished {
  &:before {
    content: $mrv-unpublished;
  }
}
.mrv-unsubscribe {
  &:before {
    content: $mrv-unsubscribe;
  }
}
.mrv-upcoming {
  &:before {
    content: $mrv-upcoming;
  }
}
.mrv-update {
  &:before {
    content: $mrv-update;
  }
}
.mrv-update_disabled {
  &:before {
    content: $mrv-update_disabled;
  }
}
.mrv-upgrade {
  &:before {
    content: $mrv-upgrade;
  }
}
.mrv-upload {
  &:before {
    content: $mrv-upload;
  }
}
.mrv-upload_file {
  &:before {
    content: $mrv-upload_file;
  }
}
.mrv-usb {
  &:before {
    content: $mrv-usb;
  }
}
.mrv-usb_off {
  &:before {
    content: $mrv-usb_off;
  }
}
.mrv-vaccines {
  &:before {
    content: $mrv-vaccines;
  }
}
.mrv-valve {
  &:before {
    content: $mrv-valve;
  }
}
.mrv-vape_free {
  &:before {
    content: $mrv-vape_free;
  }
}
.mrv-vaping_rooms {
  &:before {
    content: $mrv-vaping_rooms;
  }
}
.mrv-verified {
  &:before {
    content: $mrv-verified;
  }
}
.mrv-verified_user {
  &:before {
    content: $mrv-verified_user;
  }
}
.mrv-vertical_align_bottom {
  &:before {
    content: $mrv-vertical_align_bottom;
  }
}
.mrv-vertical_align_center {
  &:before {
    content: $mrv-vertical_align_center;
  }
}
.mrv-vertical_align_top {
  &:before {
    content: $mrv-vertical_align_top;
  }
}
.mrv-vertical_distribute {
  &:before {
    content: $mrv-vertical_distribute;
  }
}
.mrv-vertical_shades {
  &:before {
    content: $mrv-vertical_shades;
  }
}
.mrv-vertical_shades_closed {
  &:before {
    content: $mrv-vertical_shades_closed;
  }
}
.mrv-vertical_split {
  &:before {
    content: $mrv-vertical_split;
  }
}
.mrv-vibration {
  &:before {
    content: $mrv-vibration;
  }
}
.mrv-video_audio_call {
  &:before {
    content: $mrv-video_audio_call;
  }
}
.mrv-video_call {
  &:before {
    content: $mrv-video_call;
  }
}
.mrv-video_camera_back {
  &:before {
    content: $mrv-video_camera_back;
  }
}
.mrv-video_camera_front {
  &:before {
    content: $mrv-video_camera_front;
  }
}
.mrv-video_file {
  &:before {
    content: $mrv-video_file;
  }
}
.mrv-video_label {
  &:before {
    content: $mrv-video_label;
  }
}
.mrv-video_library {
  &:before {
    content: $mrv-video_library;
  }
}
.mrv-video_settings {
  &:before {
    content: $mrv-video_settings;
  }
}
.mrv-video_stable {
  &:before {
    content: $mrv-video_stable;
  }
}
.mrv-videocam {
  &:before {
    content: $mrv-videocam;
  }
}
.mrv-videocam_off {
  &:before {
    content: $mrv-videocam_off;
  }
}
.mrv-videogame_asset {
  &:before {
    content: $mrv-videogame_asset;
  }
}
.mrv-videogame_asset_off {
  &:before {
    content: $mrv-videogame_asset_off;
  }
}
.mrv-view_agenda {
  &:before {
    content: $mrv-view_agenda;
  }
}
.mrv-view_array {
  &:before {
    content: $mrv-view_array;
  }
}
.mrv-view_carousel {
  &:before {
    content: $mrv-view_carousel;
  }
}
.mrv-view_column {
  &:before {
    content: $mrv-view_column;
  }
}
.mrv-view_comfy {
  &:before {
    content: $mrv-view_comfy;
  }
}
.mrv-view_comfy_alt {
  &:before {
    content: $mrv-view_comfy_alt;
  }
}
.mrv-view_compact {
  &:before {
    content: $mrv-view_compact;
  }
}
.mrv-view_compact_alt {
  &:before {
    content: $mrv-view_compact_alt;
  }
}
.mrv-view_cozy {
  &:before {
    content: $mrv-view_cozy;
  }
}
.mrv-view_day {
  &:before {
    content: $mrv-view_day;
  }
}
.mrv-view_headline {
  &:before {
    content: $mrv-view_headline;
  }
}
.mrv-view_in_ar {
  &:before {
    content: $mrv-view_in_ar;
  }
}
.mrv-view_in_ar_new {
  &:before {
    content: $mrv-view_in_ar_new;
  }
}
.mrv-view_kanban {
  &:before {
    content: $mrv-view_kanban;
  }
}
.mrv-view_list {
  &:before {
    content: $mrv-view_list;
  }
}
.mrv-view_module {
  &:before {
    content: $mrv-view_module;
  }
}
.mrv-view_quilt {
  &:before {
    content: $mrv-view_quilt;
  }
}
.mrv-view_sidebar {
  &:before {
    content: $mrv-view_sidebar;
  }
}
.mrv-view_stream {
  &:before {
    content: $mrv-view_stream;
  }
}
.mrv-view_timeline {
  &:before {
    content: $mrv-view_timeline;
  }
}
.mrv-view_week {
  &:before {
    content: $mrv-view_week;
  }
}
.mrv-vignette {
  &:before {
    content: $mrv-vignette;
  }
}
.mrv-villa {
  &:before {
    content: $mrv-villa;
  }
}
.mrv-visibility {
  &:before {
    content: $mrv-visibility;
  }
}
.mrv-visibility_off {
  &:before {
    content: $mrv-visibility_off;
  }
}
.mrv-voice_chat {
  &:before {
    content: $mrv-voice_chat;
  }
}
.mrv-voice_over_off {
  &:before {
    content: $mrv-voice_over_off;
  }
}
.mrv-voicemail {
  &:before {
    content: $mrv-voicemail;
  }
}
.mrv-volcano {
  &:before {
    content: $mrv-volcano;
  }
}
.mrv-volume_down {
  &:before {
    content: $mrv-volume_down;
  }
}
.mrv-volume_down_alt {
  &:before {
    content: $mrv-volume_down_alt;
  }
}
.mrv-volume_mute {
  &:before {
    content: $mrv-volume_mute;
  }
}
.mrv-volume_off {
  &:before {
    content: $mrv-volume_off;
  }
}
.mrv-volume_up {
  &:before {
    content: $mrv-volume_up;
  }
}
.mrv-volunteer_activism {
  &:before {
    content: $mrv-volunteer_activism;
  }
}
.mrv-vpn_googleone {
  &:before {
    content: $mrv-vpn_googleone;
  }
}
.mrv-vpn_key {
  &:before {
    content: $mrv-vpn_key;
  }
}
.mrv-vpn_key_off {
  &:before {
    content: $mrv-vpn_key_off;
  }
}
.mrv-vpn_lock {
  &:before {
    content: $mrv-vpn_lock;
  }
}
.mrv-vrpano {
  &:before {
    content: $mrv-vrpano;
  }
}
.mrv-wallet {
  &:before {
    content: $mrv-wallet;
  }
}
.mrv-wallpaper {
  &:before {
    content: $mrv-wallpaper;
  }
}
.mrv-warehouse {
  &:before {
    content: $mrv-warehouse;
  }
}
.mrv-warning {
  &:before {
    content: $mrv-warning;
  }
}
.mrv-wash {
  &:before {
    content: $mrv-wash;
  }
}
.mrv-watch {
  &:before {
    content: $mrv-watch;
  }
}
.mrv-watch_off {
  &:before {
    content: $mrv-watch_off;
  }
}
.mrv-water {
  &:before {
    content: $mrv-water;
  }
}
.mrv-water_damage {
  &:before {
    content: $mrv-water_damage;
  }
}
.mrv-water_drop {
  &:before {
    content: $mrv-water_drop;
  }
}
.mrv-water_heater {
  &:before {
    content: $mrv-water_heater;
  }
}
.mrv-waterfall_chart {
  &:before {
    content: $mrv-waterfall_chart;
  }
}
.mrv-waves {
  &:before {
    content: $mrv-waves;
  }
}
.mrv-waving_hand {
  &:before {
    content: $mrv-waving_hand;
  }
}
.mrv-wb_auto {
  &:before {
    content: $mrv-wb_auto;
  }
}
.mrv-wb_incandescent {
  &:before {
    content: $mrv-wb_incandescent;
  }
}
.mrv-wb_iridescent {
  &:before {
    content: $mrv-wb_iridescent;
  }
}
.mrv-wb_shade {
  &:before {
    content: $mrv-wb_shade;
  }
}
.mrv-wb_sunny {
  &:before {
    content: $mrv-wb_sunny;
  }
}
.mrv-wb_twilight {
  &:before {
    content: $mrv-wb_twilight;
  }
}
.mrv-wc {
  &:before {
    content: $mrv-wc;
  }
}
.mrv-web {
  &:before {
    content: $mrv-web;
  }
}
.mrv-web_asset {
  &:before {
    content: $mrv-web_asset;
  }
}
.mrv-web_asset_off {
  &:before {
    content: $mrv-web_asset_off;
  }
}
.mrv-web_stories {
  &:before {
    content: $mrv-web_stories;
  }
}
.mrv-webhook {
  &:before {
    content: $mrv-webhook;
  }
}
.mrv-weekend {
  &:before {
    content: $mrv-weekend;
  }
}
.mrv-west {
  &:before {
    content: $mrv-west;
  }
}
.mrv-whatshot {
  &:before {
    content: $mrv-whatshot;
  }
}
.mrv-wheelchair_pickup {
  &:before {
    content: $mrv-wheelchair_pickup;
  }
}
.mrv-where_to_vote {
  &:before {
    content: $mrv-where_to_vote;
  }
}
.mrv-whiteboard {
  &:before {
    content: $mrv-whiteboard;
  }
}
.mrv-widgets {
  &:before {
    content: $mrv-widgets;
  }
}
.mrv-width_full {
  &:before {
    content: $mrv-width_full;
  }
}
.mrv-width_normal {
  &:before {
    content: $mrv-width_normal;
  }
}
.mrv-width_wide {
  &:before {
    content: $mrv-width_wide;
  }
}
.mrv-wifi {
  &:before {
    content: $mrv-wifi;
  }
}
.mrv-wifi_1_bar {
  &:before {
    content: $mrv-wifi_1_bar;
  }
}
.mrv-wifi_2_bar {
  &:before {
    content: $mrv-wifi_2_bar;
  }
}
.mrv-wifi_calling {
  &:before {
    content: $mrv-wifi_calling;
  }
}
.mrv-wifi_calling_1 {
  &:before {
    content: $mrv-wifi_calling_1;
  }
}
.mrv-wifi_calling_2 {
  &:before {
    content: $mrv-wifi_calling_2;
  }
}
.mrv-wifi_calling_3 {
  &:before {
    content: $mrv-wifi_calling_3;
  }
}
.mrv-wifi_channel {
  &:before {
    content: $mrv-wifi_channel;
  }
}
.mrv-wifi_find {
  &:before {
    content: $mrv-wifi_find;
  }
}
.mrv-wifi_lock {
  &:before {
    content: $mrv-wifi_lock;
  }
}
.mrv-wifi_off {
  &:before {
    content: $mrv-wifi_off;
  }
}
.mrv-wifi_password {
  &:before {
    content: $mrv-wifi_password;
  }
}
.mrv-wifi_protected_setup {
  &:before {
    content: $mrv-wifi_protected_setup;
  }
}
.mrv-wifi_tethering {
  &:before {
    content: $mrv-wifi_tethering;
  }
}
.mrv-wifi_tethering_error {
  &:before {
    content: $mrv-wifi_tethering_error;
  }
}
.mrv-wifi_tethering_off {
  &:before {
    content: $mrv-wifi_tethering_off;
  }
}
.mrv-wind_power {
  &:before {
    content: $mrv-wind_power;
  }
}
.mrv-window {
  &:before {
    content: $mrv-window;
  }
}
.mrv-wine_bar {
  &:before {
    content: $mrv-wine_bar;
  }
}
.mrv-woman {
  &:before {
    content: $mrv-woman;
  }
}
.mrv-work {
  &:before {
    content: $mrv-work;
  }
}
.mrv-work_history {
  &:before {
    content: $mrv-work_history;
  }
}
.mrv-workbench {
  &:before {
    content: $mrv-workbench;
  }
}
.mrv-workspace_premium {
  &:before {
    content: $mrv-workspace_premium;
  }
}
.mrv-workspaces {
  &:before {
    content: $mrv-workspaces;
  }
}
.mrv-wrap_text {
  &:before {
    content: $mrv-wrap_text;
  }
}
.mrv-wrong_location {
  &:before {
    content: $mrv-wrong_location;
  }
}
.mrv-wysiwyg {
  &:before {
    content: $mrv-wysiwyg;
  }
}
.mrv-yard {
  &:before {
    content: $mrv-yard;
  }
}
.mrv-youtube_music {
  &:before {
    content: $mrv-youtube_music;
  }
}
.mrv-youtube_searched_for {
  &:before {
    content: $mrv-youtube_searched_for;
  }
}
.mrv-youtube_tv {
  &:before {
    content: $mrv-youtube_tv;
  }
}
.mrv-zoom_in {
  &:before {
    content: $mrv-zoom_in;
  }
}
.mrv-zoom_in_map {
  &:before {
    content: $mrv-zoom_in_map;
  }
}
.mrv-zoom_out {
  &:before {
    content: $mrv-zoom_out;
  }
}
.mrv-zoom_out_map {
  &:before {
    content: $mrv-zoom_out_map;
  }
}
