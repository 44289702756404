/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  @apply bg-blue-400 text-blue-400 w-2 h-2;
  position: relative;

  border-radius: 5px;

  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;

  border-radius: 5px;
  @apply bg-blue-400 text-blue-400 w-2 h-2;

  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;

  border-radius: 5px;
  @apply bg-blue-400 text-blue-400 w-2 h-2;

  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    @apply bg-blue-400 text-blue-400;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 4px;
  width: 4px;
  @apply rounded-full;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    @apply bg-gray-200 text-gray-200;
    transform: scale(1);
  }
  50% {
    @apply bg-white;

    transform: scale(1.6);
  }
  100% {
    @apply bg-gray-200 text-gray-200;
    transform: scale(1);
  }
}
.loading-line {
  @apply fixed top-0 left-0 w-full h-1;
  // z-index: 999999;
  .bar {
    @apply absolute h-1 w-full;
    background: linear-gradient(
      270deg,
      #3f82ee 14.86%,
      rgba(78, 188, 252, 0.4) 60.01%
    );
    animation-name: loader-animation;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
  }
  @keyframes loader-animation {
    0% {
      background: linear-gradient(
        270deg,
        #3f82ee 20.86%,
        rgba(78, 188, 252, 0.4) 80.01%
      );
    }
    50% {
      background: linear-gradient(
        270deg,
        #3f82ee 30.86%,
        rgba(78, 188, 252, 0.4) 90.01%
      );
    }
    100% {
      background: linear-gradient(
        270deg,
        #3f82ee 40.86%,
        rgba(78, 188, 252, 0.4) 100%
      );
    }
  }
}
