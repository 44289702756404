.ant-select.ant-select-single.ant-select-lg {
  .ant-select-selector {
    @apply h-[44px] pt-0.5;
  }
  .ant-select-selection-search {
    .ant-select-selection-search-input {
      @apply h-full;
    }
  }
}
