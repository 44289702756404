.input-search-project {
  @apply w-[445px];
  .ant-select-selector {
    padding: 4px 4px 4px 12px !important;

    input {
      padding-right: 7rem !important;
    }
  }
  .ant-select-arrow {
    cursor: pointer !important;
    pointer-events: unset !important;
    margin-right: -6px;
  }
}
.ant-select.ant-select-single.ant-select-lg .ant-select-selector {
  @apply h-full;
  .ant-select-selection-search {
    @apply pr-4 #{!important};
  }
}
.input-search-clear {
  // .ant-select-selector {
  //   width: 95% !important;
  // }
  // input {
  //   width: 337px !important;
  //   @apply bg-grey-100;
  // }
}
.show-scroll-bar {
  .rc-virtual-list-scrollbar {
    display: block !important;
    &-show {
      display: block !important;
    }
  }
}
