.video-embed {
  @apply overflow-hidden;
  video {
    @apply h-full w-full #{!important};
  }
  .video-js {
    @apply static pt-0 w-full h-full;
    .vjs-big-play-button {
      @apply w-[3rem] h-[3rem] rounded-full z-30;
      span {
        @apply text-white;
      }
    }
    .vjs-control-bar {
      @apply z-30;
    }
  }
}
